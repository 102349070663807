import React, { useState, useContext, useRef, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { OpportunityService } from "../../services/OpportunityService";
import { useQuery } from "../../hooks/useQuery";
import { useMutations } from "../../hooks/useMutations";
import { AuthContext } from "../../context/AuthContext";
import useAnalytics from '../../hooks/useAnalytics';
import SEOMetadata from "../SEOMetadata/SEOMetadata";
import OpportunityGrid from "../OpportunityGrid/OpportunityGrid";
import { useQueryClient } from "react-query";

const RecommendedJobs = ({ setSelectedSection }) => {
  const mainContentRef = useRef(null);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID || 'G-XXXXXXXXXX';
  const { trackEvent } = useAnalytics(GA_MEASUREMENT_ID);
  const [currentPage, setCurrentPage] = useState(1);
  const opportunitiesPerPage = 30;
  const { userDetails: user } = useContext(AuthContext);
  // Calculate offset based on current page
  const offset = (currentPage - 1) * opportunitiesPerPage;

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, [currentPage]);


  // Fetch user preferences
  const { data: userPreferences, isLoading: isLoadingPrefs, error: prefsError } = useQuery(
    ["userPreferences", user?.id],
    () => OpportunityService.getUserPreference(user?.id),
    { enabled: !!user }
  );

  // Fetch recommended opportunities
  const { 
    data: recommendedResponse = { data: [], pagination: { total: 0 } }, 
    isLoading: isLoadingOpportunities 
  } = useQuery(
    ["recommendedOpportunities", user?.id, currentPage],
    () => OpportunityService.getOpportunitiesByUserPreference(
      user?.id,
      opportunitiesPerPage,
      offset
    ),
    { 
      enabled: !!userPreferences,
      keepPreviousData: true 
    }
  );

  const recommendedOpportunities = recommendedResponse.data || [];
  const totalOpportunities = recommendedResponse.pagination?.total || 0;
  const totalPages = Math.ceil(totalOpportunities / opportunitiesPerPage);

  // Fetch saved opportunities
  const { data: savedOpportunities = [] } = useQuery(
    ["getSavedOpportunities", user?.id],
    () => OpportunityService.getSavedOpportunitiesDetails({ userId: user?.id }),
    { enabled: !!user }
  );

  // Mutation handlers
  const { mutateAsync: createSaveOpportunity } = useMutations(OpportunityService.createSavedOpportunity);
  const { mutateAsync: deleteSaveOpportunity } = useMutations(OpportunityService.deleteSavedOpportunities);

  const handleToggleSave = async (opportunityId) => {
    if (!user) return;

    try {
      const isSaved = savedOpportunities.some(opp => opp.id === opportunityId);
      if (isSaved) {
        await deleteSaveOpportunity({ user_id: user.id, opportunity_id: opportunityId });
      } else {
        await createSaveOpportunity({ user_id: user.id, opportunity_id: opportunityId });
      }
      await queryClient.invalidateQueries(["getSavedOpportunities", user.id]);
    } catch (error) {
      console.error("Error toggling save:", error);
    }
  };


  const handlePageChange = useCallback((newPage) => {
    setCurrentPage(newPage);
    trackEvent({ 
      category: 'Pagination', 
      action: 'Change Page', 
      label: `Page ${newPage}` 
    });
  }, [trackEvent]);

  if (!user) {
    return (
      <div className="text-center py-20">
        <p className="text-xl text-gray-600 mb-4">🔒 Please log in to view recommendations</p>
        <button
          onClick={() => navigate("/login")}
          className="mt-4 px-6 py-2 bg-[#DB4F40] text-white rounded-lg shadow-sm hover:bg-[#C64537] transition"
        >
          Login
        </button>
      </div>
    );
  }

  if (isLoadingPrefs) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-4 border-[#DB4F40] border-t-transparent" />
      </div>
    );
  }

  if (!userPreferences) {
    return (
      <div className="text-center py-20">
        <p className="text-xl text-gray-600 mb-4">⚙️ Set preferences to view recommendations</p>
        <button
          onClick={() => setSelectedSection("user-preferences")}
          className="mt-4 px-6 py-2 bg-[#DB4F40] text-white rounded-lg shadow-sm hover:bg-[#C64537] transition"
        >
          Set Preferences
        </button>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100">
      <SEOMetadata
        title="PhDScanner - Recommended Opportunities"
        description="Personalized PhD recommendations based on your preferences"
        keywords="phd recommendations, academic opportunities"
      />

      <div className="max-w-7xl mx-auto p-4 sm:p-6">
        {isLoadingOpportunities ? (
          <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-12 w-12 border-4 border-[#DB4F40] border-t-transparent" />
          </div>
        ) : (
          <>
            {recommendedOpportunities.length > 0 ? (
              <div className="space-y-8">
                <OpportunityGrid
                  opportunities={recommendedOpportunities}
                  user={user}
                  savedOpportunities={new Set(savedOpportunities.map(opp => opp.id))}
                  handleToggleSave={handleToggleSave}
                />

                {totalPages > 1 && (
                  <div className="flex flex-col sm:flex-row justify-center items-center gap-4 py-8">
                    <button
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1}
                      className="px-6 py-2 rounded-lg bg-white border border-[#FFBEB9] 
                               hover:border-[#DB4F40] text-[#DB4F40] hover:bg-[#FFEEED] 
                               disabled:opacity-50 transition-all"
                    >
                      Previous
                    </button>
                    <span className="px-6 py-2 bg-[#DB4F40] text-white rounded-lg shadow-sm">
                      Page {currentPage} of {totalPages}
                    </span>
                    <button
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={currentPage === totalPages}
                      className="px-6 py-2 rounded-lg bg-white border border-[#FFBEB9] 
                               hover:border-[#DB4F40] text-[#DB4F40] hover:bg-[#FFEEED] 
                               disabled:opacity-50 transition-all"
                    >
                      Next
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <div className="text-center py-20">
                <p className="text-xl text-gray-600">🎓 No recommendations found</p>
                <p className="text-gray-500 mt-2">Try adjusting your preferences</p>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default RecommendedJobs;