import React, { useState } from "react";
import { Calendar, Mail, Send } from "lucide-react";
import { useMutations } from "../../hooks/useMutations";
import { AuthService } from "../../services/AuthService";
import { useSnackBar } from "../../hooks/useSnackBar";
import useAnalytics from '../../hooks/useAnalytics'; 
import { FaEnvelope, FaCalendar } from "react-icons/fa";

const SubscribeSection = () => {
  const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID || 'G-XXXXXXXXXX';
  const { trackEvent } = useAnalytics(GA_MEASUREMENT_ID);
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const enqMessage = useSnackBar();

  const { mutateAsync: subscribe, isLoading: saving } = useMutations(
    AuthService.subscribeMail,
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      trackEvent({
        category: 'Button',
        action: 'Click',
        label: 'Subscribe email'
      });
      const res = await subscribe({ email });
      
      if (res?.error) {
        enqMessage(res?.error);
      }
      if (!res?.error && res?.message) {
        enqMessage("Subscribed successfully!", "success");
      }
    } catch (error) {
      enqMessage(error);
    }
  };

  return (
    <section className="relative py-20 overflow-hidden border-t border-[#DB4F40]/20 bg-gradient-to-br from-[#FFEEED] to-[#FFD1CE]">
      <div className="container mx-auto px-6 relative z-10">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-extrabold text-[#1B222A] leading-tight mb-4">
            Stay Informed on PhD Opportunities
          </h2>
          <p className="text-xl text-gray-700 max-w-2xl mx-auto mb-8 leading-relaxed">
            Subscribe to receive daily updates on the latest PhD positions and academic resources worldwide.
          </p>
        </div>

        <div className="max-w-2xl mx-auto bg-white/90 backdrop-blur-sm rounded-2xl p-8 shadow-2xl border border-[#DB4F40]/20">
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="relative">
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="w-full px-4 py-4 border border-gray-300 rounded-xl 
                focus:outline-none focus:ring-2 focus:ring-[#DB4F40]
                transition duration-300 text-gray-800 placeholder-gray-500"
                placeholder="Enter your academic email"
              />
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <Mail className="h-5 w-5 text-gray-400" />
              </div>
            </div>

            <button
              type="submit"
              disabled={saving}
              className="w-full px-8 py-4 bg-gradient-to-br from-[#DB4F40] to-[#C64537] hover:from-[#C64537] hover:to-[#DB4F40] 
              text-white rounded-full font-semibold transform transition-all duration-300 
              hover:scale-105 hover:shadow-xl focus:outline-none focus:ring-4 focus:ring-[#DB4F40]/50
              flex items-center justify-center"
            >
              {saving ? 'Subscribing...' : 'Subscribe to Updates'}
              <Send className="ml-3 h-5 w-5" />
            </button>
          </form>

          {message && (
            <p className="mt-4 text-center text-sm text-[#DB4F40] animate-pulse">
              {message}
            </p>
          )}
        </div>

        <div className="mt-16 grid md:grid-cols-2 gap-8 max-w-4xl mx-auto">
          {[
            {
              icon: FaCalendar,
              title: "Daily Updates",
              description: "Receive fresh information on PhD openings and academic resources.",
              iconColor: "text-[#DB4F40]"
            },
            {
              icon: FaEnvelope,
              title: "No Spam Guarantee",
              description: "We value your privacy and only send relevant academic information.",
              iconColor: "text-[#DB4F40]"
            }
          ].map((item, index) => (
            <div 
              key={index}
              className="bg-white/90 backdrop-blur-sm rounded-xl p-6 shadow-lg border border-[#DB4F40]/20 
              transform transition-all duration-300 hover:scale-105 hover:shadow-xl"
            >
              <div className="flex items-center mb-4">
                <item.icon className={`h-8 w-8 mr-4 ${item.iconColor}`} />
                <h3 className="text-xl font-bold text-[#1B222A]">{item.title}</h3>
              </div>
              <p className="text-gray-700 leading-relaxed">
                {item.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default SubscribeSection;