import React from "react";
import phdscannerName from "../../assets/phdscanner_about.webp";
import SEOMetadata from "../SEOMetadata/SEOMetadata";
import { FaGraduationCap, FaUniversity, FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";

export default function About() {
  return (
    <div className="bg-gradient-to-b from-[#FFF5F5] to-[#FEF9FB]">
      <SEOMetadata
        title="PhDScanner About Us Page"
        description="Welcome to PhDScanner! At PhDScanner, we are dedicated to connecting aspiring researchers with PhD opportunities from universities around the globe. Our platform serves as a comprehensive resource for students and professionals seeking to advance their academic careers through doctoral programs."
        keywords="phdscanner, phd finder, find phd, phd, find phd postions, phd opportunities, phd openings, academic researchers, higher education, phd vacancies, phd jobs"
        imageUrl="https://www.phdscanner.com/phdscanner_logo.webp"
        url="https://www.phdscanner.com/about"
      />
      
      <div className="relative w-full px-2 min-h-screen flex items-center justify-center overflow-hidden">
        <div className="container mx-auto px-4 py-28 relative z-10">
          <div className="flex flex-col lg:flex-row items-center gap-12">
            {/* Image Section */}
            <div className="lg:w-1/2 w-full">
              <div className="relative group">
                <div className="absolute -inset-2 bg-gradient-to-r from-[#FF7E79] to-[#EEA0ED] rounded-3xl blur opacity-30 group-hover:opacity-50 transition duration-1000"></div>
                <img
                  src={phdscannerName}
                  alt="PhDScanner"
                  className="w-full h-auto object-contain rounded-2xl transform transition-all duration-300 group-hover:scale-[1.02]"
                />
              </div>
            </div>

            {/* Content Section */}
            <div className="lg:w-1/2 w-full">
              <div className="space-y-8">
                {/* Section Title */}
                <div className="mb-6">
                  <h1 className="text-5xl font-bold text-gray-900 leading-tight mb-4">
                    Empowering Researchers Worldwide
                  </h1>
                </div>

                {/* Introduction */}
                <p className="text-lg text-gray-700 leading-relaxed mb-6">
                  At <strong className="font-semibold text-[#DB4F40]">PhDScanner</strong>, we're revolutionizing 
                  how researchers connect with global opportunities. Our platform bridges 
                  the gap between academic ambition and world-class doctoral programs.
                </p>

                {/* Key Offerings */}
                <div className="grid gap-6 md:grid-cols-2">
                  <div className="p-6 bg-white rounded-xl shadow-sm hover:shadow-md transition-shadow">
                    <FaGraduationCap className="text-[#DB4F40] text-4xl mb-4" />
                    <h3 className="text-xl font-bold text-gray-900 mb-2">
                      Comprehensive Listings
                    </h3>
                    <p className="text-gray-600">
                      Curated PhD opportunities across 60+ institutions
                    </p>
                  </div>

                  <div className="p-6 bg-white rounded-xl shadow-sm hover:shadow-md transition-shadow">
                    <FaUniversity className="text-[#DB4F40] text-4xl mb-4" />
                    <h3 className="text-xl font-bold text-gray-900 mb-2">
                      Global Reach
                    </h3>
                    <p className="text-gray-600">
                      Partnerships with academic institutions across 10+ countries
                    </p>
                  </div>
                </div>

                {/* Stats */}
                <div className="bg-white p-6 rounded-xl border border-gray-100">
                  <div className="grid grid-cols-2 gap-4 text-center">
                    <div>
                      <div className="text-3xl font-bold text-[#DB4F40]">50K+</div>
                      <div className="text-gray-600 text-sm">Monthly Visitors</div>
                    </div>
                    <div>
                      <div className="text-3xl font-bold text-[#DB4F40]">2K+</div>
                      <div className="text-gray-600 text-sm">Active Listings</div>
                    </div>
                  </div>
                </div>

                {/* Contact Section */}
                <div className="bg-gradient-to-br from-[#FFEEED] to-[#FEF2F8] p-8 rounded-2xl">
                  <div className="flex items-start gap-4">
                    <div className="bg-[#DB4F40] p-3 rounded-lg">
                      <FaEnvelope className="text-white text-2xl" />
                    </div>
                    <div>
                      <h3 className="text-xl font-bold text-gray-900 mb-2">
                        Connect With Us
                      </h3>
                      <p className="text-gray-600 mb-4">
                        Have questions or partnership inquiries? Our team is here to help
                        you navigate your research journey.
                      </p>
                      <a
                        href="mailto:admin@phdscanner.com"
                        className="inline-flex items-center gap-2 text-[#DB4F40] font-semibold hover:text-[#C23A30] transition-colors"
                      >
                        <FaMapMarkerAlt />
                        admin@phdscanner.com
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}