// EditBlog.jsx
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Blog } from "../../services/Blog";
import ErrorPopup from "../ErrorPopup/ErrorPopup";

const EditBlog = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [showErrorPopup, setShowErrorPopup] = useState(false);

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        if (!id) {
          setError('Invalid blog ID');
          setShowErrorPopup(true);
          return;
        }

        const response = await Blog.getBlogById(id);
        const blog = response;
        
        setTitle(blog.title);
        setContent(blog.content);
        
        if (blog.image_data) {
          setImagePreview(`data:image/jpeg;base64,${blog.image_data}`);
        }
      } catch (error) {
        console.error('Error fetching blog:', error);
        setShowErrorPopup(true);
        setError('Failed to load blog post. It may have been deleted or does not exist.');
      } finally {
        setLoading(false);
      }
    };

    fetchBlog();
  }, [id]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const supportedTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'];
      const maxSize = 1 * 1024 * 1024; // 1MB

      if (!supportedTypes.includes(file.type)) {
        setError('Unsupported file type. Please upload a PNG, JPG, or GIF image.');
        setShowErrorPopup(true);
        return;
      }

      if (file.size > maxSize) {
        setError('File size too large. Please upload an image less than 1MB.');
        setShowErrorPopup(true);
        return;
      }

      setImage(file);
      const reader = new FileReader();
      reader.onload = () => setImagePreview(reader.result);
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!title || !content) {
      setError('Title and content are required');
      setShowErrorPopup(true);
      return;
    }

    if (!id) {
      setError('Invalid blog ID');
      setShowErrorPopup(true);
      return;
    }

    setSubmitting(true);
    setError('');

    try {
      const formData = new FormData();
      formData.append('title', title);
      formData.append('content', content);
      if (image) formData.append('image', image);

      await Blog.updateBlog({ id, data: formData }); // Fixed parameter format
      navigate(`/blog/${id}`);
      
    } catch (error) {
      console.error('Error updating blog:', error);
      setError(error.response?.data?.error || 'Failed to update blog post. Please try again.');
      setShowErrorPopup(true);
    } finally {
      setSubmitting(false);
    }
  };

  if (loading) {
    return (
      <div className="container mx-auto px-4 py-12 flex justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-[#DB4F40]"></div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-12 max-w-4xl">
      <div className="bg-gradient-to-br from-[#FFEEED] to-[#FFD1CE] rounded-2xl shadow-xl p-8 border border-[#DB4F40]/20">
        <h1 className="text-4xl font-extrabold text-[#1B222A] mb-8 border-b border-[#DB4F40]/30 pb-4">
          Edit Post
        </h1>

        <ErrorPopup 
          message={error}
          visible={showErrorPopup}
          onClose={() => setShowErrorPopup(false)}
        />

        {imagePreview && !image && (
          <div className="mb-8">
            <p className="text-sm text-[#1B222A]/80 mb-3 font-medium">Current Featured Image</p>
            <div className="relative w-full max-w-xs rounded-xl overflow-hidden border-2 border-[#DB4F40]/30">
              <img src={imagePreview} alt="Current" className="w-full h-48 object-cover" />
            </div>
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-8">
          <div>
            <label className="block text-[#1B222A] font-semibold mb-4">
              Title
              <input
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="w-full px-5 py-3 border-2 border-[#DB4F40]/30 rounded-xl bg-white/80 focus:ring-2 focus:ring-[#DB4F40]/50 focus:border-transparent mt-2"
                placeholder="Enter blog title"
              />
            </label>
          </div>

          <div>
            <label className="block text-[#1B222A] font-semibold mb-4">
              Content
              <div className="border-2 border-[#DB4F40]/30 rounded-xl bg-white overflow-hidden">
                <ReactQuill
                  value={content}
                  onChange={setContent}
                  modules={{
                    toolbar: [
                      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                      ['bold', 'italic', 'underline', 'strike'],
                      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                      ['link', 'image'],
                      ['clean']
                    ]
                  }}
                  className="h-96 bg-white rounded-b-xl"
                  placeholder="Describe the blog content here..."
                />
              </div>
            </label>
          </div>

          <div>
            <label className="block text-[#1B222A] font-semibold mb-4">
              Featured Image
              <div className="flex items-center gap-6 mt-2">
                <label className="flex flex-col items-center justify-center w-full h-40 border-2 border-dashed border-[#DB4F40]/30 rounded-xl cursor-pointer bg-white/50 hover:bg-white transition-colors">
                  <div className="text-center p-6">
                    <svg className="w-8 h-8 mx-auto text-[#DB4F40] mb-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"/>
                    </svg>
                    <p className="text-sm text-[#1B222A]/80">
                      <span className="font-semibold text-[#DB4F40]">Click to upload</span> or drag and drop
                    </p>
                    <p className="text-xs text-[#1B222A]/60">PNG, JPG or GIF (MAX. 1MB)</p>
                  </div>
                  <input
                    type="file"
                    className="hidden"
                    accept="image/*"
                    onChange={handleImageChange}
                  />
                </label>

                {imagePreview && image && (
                  <div className="relative h-40 w-40 rounded-xl overflow-hidden border-2 border-[#DB4F40]/30 bg-white">
                    <img src={imagePreview} alt="Preview" className="w-full h-full object-cover" />
                    <button
                      type="button"
                      onClick={() => {
                        setImage(null);
                        setImagePreview(null);
                      }}
                      className="absolute top-2 right-2 bg-white/90 p-1 rounded-full shadow-sm hover:bg-white transition-colors"
                    >
                      <svg className="w-5 h-5 text-[#DB4F40]" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"/>
                      </svg>
                    </button>
                  </div>
                )}
              </div>
            </label>
          </div>

          <div className="flex justify-end gap-4 pt-8 border-t border-[#DB4F40]/20">
            <button
              type="button"
              onClick={() => navigate(`/blog/${id}`)}
              className="px-8 py-3 border-2 border-[#DB4F40]/30 text-[#DB4F40] rounded-xl hover:bg-[#FFEEED] transition-colors font-semibold"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={submitting}
              className="px-8 py-3 bg-gradient-to-br from-[#DB4F40] to-[#C64537] text-white rounded-xl hover:from-[#C64537] hover:to-[#DB4F40] transition-all flex items-center gap-2 font-semibold"
            >
              {submitting && (
                <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"/>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"/>
                </svg>
              )}
              {submitting ? 'Saving Changes...' : 'Save Changes'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditBlog;