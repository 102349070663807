

const countryImages = {
    "United Kingdom": "/country/United Kingdom.webp",
    "Denmark": "/country/Denmark.webp",
    "Netherlands": "/country/Netherlands.webp",
    "Sweden": "/country/Sweden.webp",
    "Germany": "/country/Germany.webp",
    "France": "/country/France.webp",
    "Belgium": "/country/Belgium.webp",
    "Finland": "/country/Finland.webp",
    "Norway": "/country/Norway.webp",
    "Switzerland": "/country/Switzerland.webp",
    "Luxembourg": "/country/Luxembourg.webp",
    "Norway": "/country/Norway.webp",
};


module.exports = {
    countryImages
}

