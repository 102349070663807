import React, { useState } from "react";
import { MapPin, Phone, Mail, Clock, Send, X } from "lucide-react";
import { ContactMessage } from "../../services/ContactMessage";
import useAnalytics from '../../hooks/useAnalytics';
import SEOMetadata from "../SEOMetadata/SEOMetadata";
import { FaLocationDot, FaPhone } from "react-icons/fa6";
import { FaGraduationCap, FaUniversity, FaEnvelope, FaBuilding, FaClock, FaMapPin } from "react-icons/fa";

const ContactUs = () => {
    const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID || 'G-XXXXXXXXXX';
    const { trackEvent } = useAnalytics(GA_MEASUREMENT_ID);
    const [formData, setFormData] = useState({ name: "", email: "", message: "" });
    const [errors, setErrors] = useState({});
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false); // Loading state

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, [name]: "" });
    };

    const validateForm = () => {
        let formErrors = {};
        if (!formData.name.trim()) formErrors.name = "Name is required";
        if (!formData.email.trim()) formErrors.email = "Email is required";
        else if (!/\S+@\S+\.\S+/.test(formData.email)) formErrors.email = "Email is invalid";
        if (!formData.message.trim()) formErrors.message = "Message is required";
        else if (formData.message.length > 1500) formErrors.message = "Message is too long";

        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            setIsLoading(true); // Start loading
            try {
                trackEvent({
                    category: 'Button',
                    action: 'Click',
                    label: 'Contact Us'
                });
                await ContactMessage.sendMessage(formData); // Call the API
                setFormData({ name: "", email: "", message: "" }); // Reset form
                setIsPopupVisible(true); // Show success popup
            } catch (error) {
                console.error("Error sending message:", error);
                // Optionally handle error state here
            } finally {
                setIsLoading(false); // Stop loading
            }

        }
    };

    const handleClosePopup = () => {
        setIsPopupVisible(false);
    };

    const ContactInfo = ({ icon: Icon, title, content }) => (
        <div className="flex items-start mb-8">
            <div className="mr-4 mt-1 flex-shrink-0">
                <Icon className="h-6 w-6 text-[#DB4F40]" strokeWidth={1.5} />
            </div>
            <div>
                <h3 className="text-lg font-semibold text-[#1B222A] mb-1">{title}</h3>
                <p className="text-base text-[#1B222A] opacity-90 leading-relaxed">{content}</p>
            </div>
        </div>
    );

    return (
        <div className="bg-[#FFFAF9]">
            <SEOMetadata
                title="PhDScanner Contact Us Page"
                description="Get in Touch. We'd love to hear from you. Send us a message and we'll respond as soon as possible"
                keywords="phdscanner, phd finder, find phd, phds, phd postions, phd opportunities, phd openings, academic researchers, higher education, phd vacancies, phd jobs"
                imageUrl="https://www.phdscanner.com/phdscanner_logo.webp"
                url="https://www.phdscanner.com/contact"
            />

            <section className="relative w-full px-2 min-h-screen flex items-center justify-center overflow-hidden">
                <div className="container mx-auto px-4 py-16 relative z-10">
                    <div className="bg-white rounded-3xl shadow-lg overflow-hidden">
                        <div className="mx-auto max-w-7xl px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
                            <div className="mb-12 text-center">
                                <h1 className="text-5xl font-bold text-[#1B222A] leading-tight mb-4">
                                    Connect with <span className="text-[#DB4F40]">Our Team</span>
                                </h1>
                                <p className="text-lg text-[#1B222A] max-w-2xl mx-auto mb-8 leading-relaxed">
                                    Have questions or suggestions? We're here to help and would love to hear from you.
                                </p>
                            </div>

                            <div className="mt-12 grid gap-12 md:grid-cols-2">
                                <div className="bg-white p-10 rounded-2xl border-l-4 border-[#DB4F40] shadow-sm">
                                    <h3 className="text-3xl font-bold mb-8 text-[#1B222A]">Our Details</h3>
                                    <ContactInfo
                                        icon={FaMapPin}
                                        title="Office Address"
                                        content="PhDScanner, Richmond Rd, Bradford BD7 1DP, United Kingdom"
                                    />
                                    <ContactInfo
                                        icon={FaPhone}
                                        title="Contact Number"
                                        content="+44 (789) 989-8897"
                                    />
                                    <ContactInfo
                                        icon={FaEnvelope}
                                        title="Email Address"
                                        content="admin@phdscanner.com"
                                    />
                                    <ContactInfo
                                        icon={FaClock}
                                        title="Office Hours"
                                        content="Monday - Friday: 8:00 AM - 5:00 PM UTC"
                                    />
                                </div>

                                <div className="bg-[#FFF5F4] p-10 rounded-2xl shadow-sm">
                                    <h3 className="text-3xl font-bold mb-8 text-[#1B222A]">Send Your Message</h3>
                                    <form onSubmit={handleSubmit} className="space-y-6">
                                        <div>
                                            <label htmlFor="name" className="block text-base font-medium text-[#1B222A] mb-2">
                                                Full Name
                                            </label>
                                            <input
                                                type="text"
                                                id="name"
                                                name="name"
                                                value={formData.name}
                                                onChange={handleChange}
                                                className="w-full px-4 py-3 border border-[#E5E7EB] rounded-lg focus:outline-none focus:ring-2 focus:ring-[#DB4F40] focus:border-transparent"
                                                placeholder="Enter your full name"
                                            />
                                            {errors.name && <p className="text-red-500 text-sm mt-1">{errors.name}</p>}
                                        </div>
                                        <div>
                                            <label htmlFor="email" className="block text-base font-medium text-[#1B222A] mb-2">
                                                Email Address
                                            </label>
                                            <input
                                                type="email"
                                                id="email"
                                                name="email"
                                                value={formData.email}
                                                onChange={handleChange}
                                                className="w-full px-4 py-3 border border-[#E5E7EB] rounded-lg focus:outline-none focus:ring-2 focus:ring-[#DB4F40] focus:border-transparent"
                                                placeholder="Enter your email address"
                                            />
                                            {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email}</p>}
                                        </div>
                                        <div>
                                            <label htmlFor="message" className="block text-base font-medium text-[#1B222A] mb-2">
                                                Your Message
                                            </label>
                                            <textarea
                                                id="message"
                                                name="message"
                                                value={formData.message}
                                                onChange={handleChange}
                                                className="w-full px-4 py-3 border border-[#E5E7EB] rounded-lg focus:outline-none focus:ring-2 focus:ring-[#DB4F40] focus:border-transparent"
                                                rows={5}
                                                placeholder="Type your message here..."
                                            />
                                            {errors.message && <p className="text-red-500 text-sm mt-1">{errors.message}</p>}
                                        </div>
                                        <button
                                            type="submit"
                                            className="w-full bg-[#DB4F40] hover:bg-[#C23327] text-white font-semibold py-3 px-6 rounded-lg transition-all duration-300 ease-in-out flex items-center justify-center"
                                        >
                                            <Send className="mr-3 h-5 w-5" strokeWidth={2} />
                                            {isLoading ? 'Sending...' : 'Send Message'}
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Popup Modal - Updated Styling */}
                {isPopupVisible && (
                    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black/30 px-4 backdrop-blur-sm">
                        <div className="bg-white p-8 rounded-2xl shadow-xl w-full max-w-md border border-[#F3F4F6]">
                            <div className="flex justify-between items-start mb-6">
                                <h3 className="text-2xl font-bold text-[#1B222A]">Message Received!</h3>
                                <button
                                    onClick={handleClosePopup}
                                    className="text-gray-500 hover:text-[#DB4F40] transition-colors"
                                >
                                    <X className="h-7 w-7" strokeWidth={1.5} />
                                </button>
                            </div>
                            <p className="text-[#1B222A] mb-6 text-lg leading-relaxed">
                                Thank you for contacting us! We've received your message and will respond within 24-48 hours.
                            </p>
                            <button
                                onClick={handleClosePopup}
                                className="w-full bg-[#DB4F40] hover:bg-[#C23327] text-white font-semibold py-3 px-6 rounded-lg transition-all duration-300"
                            >
                                Close Notification
                            </button>
                        </div>
                    </div>
                )}
            </section>
        </div>
    );
};

export default ContactUs;