import React from 'react';
import { Link } from 'react-router-dom';
import { FaArrowLeft, FaCookie, FaShieldAlt } from 'react-icons/fa';

const PrivacyPolicy = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-[#FFEEED] to-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        {/* Back Button */}
        <Link 
          to="/" 
          className="mb-8 inline-flex items-center text-[#DB4F40] hover:text-[#C64537] font-medium transition-colors"
        >
          <FaArrowLeft className="mr-2" />
          Back to Home
        </Link>

        {/* Hero Section */}
        <div className="text-center mb-16">
          <div className="bg-[#DB4F40]/10 rounded-full p-4 inline-block mb-6">
            <FaShieldAlt className="text-4xl text-[#DB4F40]" />
          </div>
          <h1 className="text-4xl font-extrabold text-[#1B222A] mb-4">
            Privacy & Cookie Policy
          </h1>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Your trust is important to us. This policy outlines how we handle your data with the utmost care and transparency.
          </p>
        </div>

        {/* Content Container */}
        <div className="bg-white rounded-2xl shadow-lg p-8 sm:p-12 border border-[#DB4F40]/20">
          {/* Cookies Section */}
          <section className="mb-12">
            <div className="flex items-center mb-6">
              <FaCookie className="text-2xl text-[#DB4F40] mr-3" />
              <h2 className="text-3xl font-bold text-[#1B222A]">
                Cookie Usage
              </h2>
            </div>
            
            <div className="overflow-x-auto">
              <table className="w-full border-collapse">
                <thead>
                  <tr className="bg-[#DB4F40]/10">
                    <th className="px-6 py-4 text-left text-[#C64537] font-semibold border-b-2 border-[#DB4F40]">
                      Cookie Name
                    </th>
                    <th className="px-6 py-4 text-left text-[#C64537] font-semibold border-b-2 border-[#DB4F40]">
                      Purpose
                    </th>
                    <th className="px-6 py-4 text-left text-[#C64537] font-semibold border-b-2 border-[#DB4F40]">
                      Duration
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="hover:bg-[#FFEEED]/50 transition-colors">
                    <td className="px-6 py-4 border-b border-[#DB4F40]/20">
                      <code className="text-[#DB4F40] bg-[#FFEEED] px-2 py-1 rounded">
                        session_id
                      </code>
                    </td>
                    <td className="px-6 py-4 border-b border-[#DB4F40]/20">
                      Maintains your secure login session
                    </td>
                    <td className="px-6 py-4 border-b border-[#DB4F40]/20">
                      Browser session
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <p className="mt-6 text-gray-600 text-sm italic">
              * No third-party cookies or tracking mechanisms are employed on our platform.
            </p>
          </section>

          {/* Data Protection Section */}
          <section className="mb-12">
            <h3 className="text-2xl font-bold text-[#1B222A] mb-4">
              <FaShieldAlt className="inline mr-2 text-[#DB4F40]" />
              Data Protection
            </h3>
            <div className="space-y-4 text-gray-700">
              <p>
                We implement industry-standard security measures including encryption, 
                regular security audits, and strict access controls to protect your data.
              </p>
              <p>
                All personal data is processed in accordance with the GDPR and other 
                applicable data protection regulations.
              </p>
            </div>
          </section>

          {/* Contact Section */}
          <section>
            <h3 className="text-2xl font-bold text-[#1B222A] mb-4">
              Need More Information?
            </h3>
            <p className="text-gray-700 mb-4">
              Contact our admin at:
            </p>
            <a 
              href="mailto:admin@phdscanner.com" 
              className="text-[#DB4F40] hover:text-[#C64537] font-medium transition-colors"
            >
              admin@phdscanner.com
            </a>
          </section>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;