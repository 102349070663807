// src/AppRoutes.tsx
import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import AdminDashboard from "./components/AdminDashboard/AdminDashboard";
import Home from "./components/Home/Home";
import About from "./components/About/About";
import ContactUs from "./components/ContactUs/ContactUs";
import Login from "./components/Login/Login";
import User from "./components/User/User";
import { Opportunities } from "./components/Opportunity/Opportunity";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import { OpportunityForm } from "./components/OpportunityForm/OpportunityForm";
import Layout from "./Layout";
import OpportunityDetail from "./components/OpportunityDetail/OpportunityDetail";
import SmartPicks from "./components/SmartPicks/SmartPicks";
import BlogPage from "./components/BlogPage/BlogPage";
import BlogDetail from "./components/BlogDetail/BlogDetail";
import CreateBlog from "./components/CreateBlog/CreateBlog";
import EditBlog from "./components/EditBlog/EditBlog";


interface AppRoutesProps {
  trackPageView: (path: string) => void; // Define the prop type
}

export const AppRoutes: React.FC<AppRoutesProps> = ({ trackPageView }) => {
  const location = useLocation();

  useEffect(() => {
    // Track page view
    trackPageView(location.pathname + location.search);
  }, [location, trackPageView]);

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="/" element={<Home />} />
        <Route path="home" element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="contact" element={<ContactUs />} />
        <Route path="login" element={<Login />} />
        <Route path="admin" element={<AdminDashboard />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/smart-picks" element={<SmartPicks />} />
        <Route path="user" element={<User />}>
          <Route path=":userid" element={<User />} />
        </Route>
        <Route path="phd-vacancies" element={<Opportunities />} />
        <Route path="phd-vacancies/:category/:country?/:university?" element={<Opportunities />} />
        <Route path="phd-vacancies/:category/discipline/:discipline?" element={<Opportunities />} />
        <Route path="phd-vacancies/:category/discipline/:discipline?/country/:country?" element={<Opportunities />} />
        <Route path="phd-vacancies/:category/discipline/:discipline?/country/:country?/university/:university?" element={<Opportunities />} />
        <Route path="post-jobs" element={<OpportunityForm />} />
        <Route path="opportunities/:title" element={<OpportunityDetail />} />
        {/* Blog Routes */}
        <Route path="blogs" element={<BlogPage />} />
        <Route path="blog/:id" element={<BlogDetail />} />
        <Route path="create-blog" element={<CreateBlog />} />
        <Route path="edit-blog/:id" element={<EditBlog />} />
      </Route>
    </Routes>
  );
};