import { create } from "domain";
import { phdScannerApiAxios } from "../lib/axios-interceptor";
import { handleResponse } from "../utils/handleResponse";

export const Blog = {

  async createBlog(reqData: any) {
    return handleResponse(() =>
      phdScannerApiAxios.post("/api/blogs", reqData),
    );
  },

  async updateBlog({ id, data }: { id: string; data: any }) {
    return handleResponse(() =>
      phdScannerApiAxios.put(`/api/blogs/${id}`, data) // Use backticks here
    );
  },

 

  getBlogs: async (params: { page: string; search: string; }) => {
    try {
      // Make sure params are properly converted to URL query parameters
      // This is crucial - if your backend expects 'search' but it's not being sent correctly
      const queryParams = new URLSearchParams();
      
      if (params.page) {
        queryParams.append('page', params.page);
      }
      
      if (params.search) {
        queryParams.append('search', params.search);
      }
      
      // Log the final URL for debugging
      console.log(`Sending request to: /blogs?${queryParams.toString()}`);
      
      // Make the API call with properly formatted query parameters
      const response = await phdScannerApiAxios.get(`/api/blogs?${queryParams.toString()}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching blogs:', error);
      throw error;
    }
  },

  async getBlogById(id: string) {
    return handleResponse(() =>
      phdScannerApiAxios.get(`/api/blogs/${id}`),
    );
  },

  async deleteBlogById(id: string) {
    return handleResponse(() =>
      phdScannerApiAxios.delete(`/api/blogs/${id}`),
    );
  },


};
