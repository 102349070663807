import React from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { AuthContext } from "../../context/AuthContext";
import { useContext } from "react";
import { Blog } from "../../services/Blog";
import { FaArrowRight, FaEdit, FaEye } from 'react-icons/fa';
import SEOMetadata from "../SEOMetadata/SEOMetadata";
import useAnalytics from '../../hooks/useAnalytics';


// Helper function to format date like "March 3, 2025"
const formatDate = (date) => {
  return date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
};

const BlogDetail = () => {
  const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID || 'G-XXXXXXXXXX';
  const { trackEvent } = useAnalytics(GA_MEASUREMENT_ID);
  const { userDetails: user } = useContext(AuthContext);
  const { id } = useParams();
  const navigate = useNavigate();

  const { data: blog, isLoading, error } = useQuery(
    ["getAllBlogs", id],
    () => Blog.getBlogById(id),
    { retry: false }
  );

  const handleDelete = async () => {
    if (window.confirm('Are you sure you want to delete this blog post? This action cannot be undone.')) {
      try {
        trackEvent({
          category: 'Delete blog',
          action: 'Delete',
          label: 'Delete blog'
      });
        await Blog.deleteBlogById(id);
        navigate('/blogs');
      } catch (error) {
        console.error('Error deleting blog:', error);
        alert('Failed to delete blog post. Please try again.');
      }
    }
  };

  if (isLoading) {
    return (
      <div className="container mx-auto px-4 py-12 flex justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error || !blog) {
    return (
      <div className="container mx-auto px-4 py-12 max-w-3xl">
        <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-6" role="alert">
          <p>Failed to load blog post. It may have been deleted or does not exist.</p>
        </div>
        <Link to="/blogs" className="text-blue-600 hover:text-blue-800 font-medium">
          &larr; Back to Blogs
        </Link>
      </div>
    );
  }

  const currentDate = formatDate(new Date());
  const seoTitle = `${blog.title} - PhD Tips`;
  const seoDescription = `${currentDate} -- Read our in-depth article on ${blog.title}. Practical guidance for PhD students and researchers written by experienced academics.`;
  const keywords = `phd tips, phd research, phd advice, doctoral research, research methods, academic writing, phd life`;


  return (
    <>
      <SEOMetadata
        title={seoTitle}
        description={seoDescription}
        keywords={keywords}
        imageUrl={blog.image_data ? `data:image/jpeg;base64,${blog.image_data}` : "https://www.phdscanner.com/phdscanner_logo.webp"}
        url={`https://www.phdscanner.com/blog/${blog.id}`}
      />
    
      <div className="container mx-auto px-4 py-12 max-w-4xl">
        <Link 
          to="/blogs" 
          className="inline-flex items-center text-[#DB4F40] hover:text-[#C64537] font-medium mb-8"
        >
          <FaArrowRight className="mr-2 rotate-180" />
          Back to Blogs
        </Link>
        
        <article className="bg-gradient-to-br from-[#FFEEED] to-[#FFD1CE] rounded-2xl shadow-xl overflow-hidden">
          {blog.image_data && (
            <div className="aspect-video bg-gray-200 relative">
              <img
                src={`data:image/jpeg;base64,${blog.image_data}`}
                alt={blog.title}
                className="w-full h-full object-cover"
              />
              
              {/* View count badge */}
              <div className="absolute bottom-4 right-4 bg-black/60 text-white px-3 py-2 rounded-full text-sm flex items-center">
                <FaEye className="mr-2 w-4 h-4" />
                <span>{blog.views || 0} views</span>
              </div>
            </div>
          )}
          
          <div className="p-8">
            <div className="flex justify-between items-center mb-6">
              <h1 className="text-4xl font-extrabold text-[#1B222A]">
                {blog.title}
              </h1>
              
              {/* If no image is present, show views here instead */}
              {!blog.image_data && (
                <div className="bg-[#DB4F40]/10 text-[#DB4F40] px-3 py-2 rounded-full text-sm flex items-center">
                  <FaEye className="mr-2 w-4 h-4" />
                  <span>{blog.views || 0} views</span>
                </div>
              )}
            </div>
            
            <div className="prose prose-lg max-w-none text-[#1B222A]">
              {blog.description && (
                <p className="text-xl text-[#1B222A]/90 mb-8">{blog.description}</p>
              )}
              <div dangerouslySetInnerHTML={{ __html: blog.content }} />
            </div>
          </div>

          {user && user.role === "admin" && (
            <div className="px-8 py-6 bg-[#FFEEED] border-t border-[#DB4F40]/20 flex justify-end gap-4">
              <Link
                to={`/edit-blog/${blog.id}`}
                className="px-6 py-2 bg-gradient-to-br from-[#DB4F40] to-[#C64537] hover:from-[#C64537] hover:to-[#DB4F40] text-white rounded-xl transition-all duration-300 hover:scale-105"
              >
                Edit
              </Link>
              <button
                onClick={handleDelete}
                className="px-6 py-2 bg-red-600 hover:bg-red-700 text-white rounded-xl transition-all duration-300 hover:scale-105"
              >
                Delete
              </button>
            </div>
          )}
        </article>
      </div>
    </>
  );
};

export default BlogDetail;