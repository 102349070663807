import { phdScannerApiAxios } from "../lib/axios-interceptor";
import { handleResponse } from "../utils/handleResponse";
import { createUrl, transformParams } from "../utils/helpers";
import { extractOpportunityId, isReadableUrl, createReadableOpportunityUrl } from "../utils/opportunityUrlUtils";

type PARAMTYPE = Record<string, string | number | boolean>;
export const OpportunityService = {
  async getAllOpportunities(params: PARAMTYPE) {
    const url = createUrl("/api/opportunities", params);
    return handleResponse(() => phdScannerApiAxios.get(url));
  },

  async savedUserPreference(reqData: any) {
    return handleResponse(() =>
      phdScannerApiAxios.post("api/user/preferences", reqData),
    );
  },

  async getUserPreference(userId: string) {
    return handleResponse(() =>
      phdScannerApiAxios.get(`api/user/preferences/${userId}`),
    );
  },

  async getOpportunitiesByUserPreference(
    userId: string,
    limit: number = 30,
    offset: number = 0
  ) {
    return handleResponse(() =>
      phdScannerApiAxios.get(`/api/opportunities/user/${userId}`, {
        params: { limit, offset }
      })
    );
  },

 

  async getOpportunityCountries(params: PARAMTYPE) {
    const url = createUrl("/api/countries", params);
    return handleResponse(() => phdScannerApiAxios.get(url));
  },

  async getOpportunityUniversities(params: PARAMTYPE) {
    const url = createUrl("/api/universities", params);
    return handleResponse(() => phdScannerApiAxios.get(url));
  },

  async getOpportunityDiscipline(params: PARAMTYPE) {
    const url = createUrl("/api/disciplines", params);
    return handleResponse(() => phdScannerApiAxios.get(url));
  },
  

  async getOpportunityCountryUni(params: PARAMTYPE) {
    const url = createUrl("/api/opportunities/countries/universities", params);
    return handleResponse(() => phdScannerApiAxios.get(url));
  },
  async getOpportunityCountriesCount() {
    return handleResponse(() =>
      phdScannerApiAxios.get("/api/opportunities/countries/counts"),
    );
  },
  async getOpportunityDisciplineCount() {
    return handleResponse(() =>
      phdScannerApiAxios.get("/api/opportunities/discipline/counts"),
    );
  },

  async getOpportunityUniversitiesCounts() {
    return handleResponse(() =>
      phdScannerApiAxios.get("/api/opportunities/universities/counts"),
    );
  },

  async getOpportunityUniversitiesCountries() {
    return handleResponse(() =>
      phdScannerApiAxios.get("/api/admin/universities/countries"),
    );
  },

  async getCountryOpportunityById(id: string) {
    return handleResponse(() =>
      phdScannerApiAxios.get(`/api/opportunities/countries/${id}`),
    );
  },


  async deleteMessageById(id: string) {
    return handleResponse(() => phdScannerApiAxios.delete(`api/contact?id=${id}`));
  },
  
  async getMessageById() {
    return handleResponse(() => phdScannerApiAxios.get(`/api/admin/contact`));
  },

  async getOpportunityById(identifierInput: string) {
    // Determine if input is a readable URL or direct ID
    const id = isReadableUrl(identifierInput) 
      ? extractOpportunityId(identifierInput)
      : identifierInput;

    return handleResponse(() => phdScannerApiAxios.get(`/api/opportunities/${id}`));
  },

  async createOpportunity(reqData: any) {
    console.log(`reqData data : ${JSON.stringify(reqData)}`);
    return handleResponse(() =>
      phdScannerApiAxios.post("/api/admin/opportunities", reqData),
    );
  },

  

  async updateOpportunityById({ id, data }: { id: string; data: any }) {
    return handleResponse(() =>
      phdScannerApiAxios.put(`/api/admin/opportunities/${id}`, data) // Use backticks here
    );
  },

  async deleteOpportunityById(id: string) {
    return handleResponse(() =>
      phdScannerApiAxios.delete(`/api/admin/opportunities/${id}`),
    );
  },

  async getSavedOpportunities() {
    return handleResponse(() =>
      phdScannerApiAxios.get("/api/user/opportunity/save"),
    );
  },

  async getSavedOpportunitiesDetails() {
    return handleResponse(() =>
      phdScannerApiAxios.get("/api/user/opportunity/save/details"),
    );
  },

  async createSavedOpportunity(reqData: any) {
    return handleResponse(() =>
        phdScannerApiAxios.post("/api/user/opportunity/save", reqData),
    );
  },
  async deleteSavedOpportunities(reqData: any) {
    return handleResponse(() =>
      phdScannerApiAxios.delete("/api/user/opportunity/save", {
        data: reqData, // Pass the request body here
      })
    );
  },

  
  // Method to generate a link for navigation
  generateOpportunityLink(opportunity: {
    id: string;
    country: string;
    university: string;
    title: string;
  }): string {
    return `phd-vacancies-${createReadableOpportunityUrl(
      opportunity.id, 
      opportunity.country,
      opportunity.university, 
      opportunity.title
    )}`;
  }
};
