import { phdScannerApiAxios } from "../lib/axios-interceptor";
import { handleResponse } from "../utils/handleResponse";

export const ContactMessage = {

  async sendMessage(reqData: any) {
    return handleResponse(() =>
      phdScannerApiAxios.post("/api/contact", reqData),
    );
  },

  async getMessage() {
    return handleResponse(() =>
      phdScannerApiAxios.get("/api/admin/contact"),
    );
  },

  async deleteMessageById(id: string) {
    return handleResponse(() =>
      phdScannerApiAxios.delete(`api/admin/contact?id=${id}`),
    );
  },


};
