import React from 'react';
import { Helmet } from 'react-helmet-async';

const SEOMetadata = ({
  title,
  description,
  keywords,
  imageUrl,
  url,
  type = 'website',
  opportunity // Add opportunity object for more dynamic content
}) => {
  // Fallback image and default values
  const defaultImage = 'https://phdscanner.com/phdscanner_logo.png';
  const defaultDescription = 'Discover and apply to exciting PhD opportunities worldwide. Your gateway to academic research and career advancement.';
  
  // Construct dynamic metadata
  const seoTitle = title || 'PhDScanner - Global PhD Opportunities';
  const seoDescription = description || defaultDescription;
  const seoImage = imageUrl || defaultImage;
  const seoUrl = url || 'https://www.phdscanner.com';

  const linkedInCompany = {
    companyName: "PhDScanner",
    companyUrl: "https://www.linkedin.com/company/phdscanner/posts/?feedView=all",
    companyDescription: "Connecting talented researchers with transformative PhD opportunities across global institutions."
  };

  // Generate additional structured data
  // const structuredData = {
  //   "@context": "https://schema.org",
  //   "@type": "EducationalOrganization",
  //   "name": "PhDScanner",
  //   "description": seoDescription,
  //   "url": seoUrl,
  //   "logo": defaultImage,
  //   "sameAs": [
  //     "https://www.linkedin.com/company/phdscanner",
  //     "https://twitter.com/phdscanner",
  //     "https://www.facebook.com/phdscanner"
  //   ]
  // };

  return (
    <>
      <Helmet>
       {/* Open Graph / Facebook metadata */}
        <meta property="og:locale" content="en_US"></meta>
        <meta property="og:type" content={type} />
        <meta property="og:title" content={seoTitle} />
        <meta property="og:description" content={seoDescription} />
        <meta property="og:url" content={seoUrl} />
        <meta property="og:site_name" content="PhDScanner" />
        <meta property="og:image" content={seoImage} />
        <meta property="og:image:secure_url" content={seoImage} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:image:type" content="image/png" />
        <meta name="linkedin:card" content="summary_large_image" />
        <meta name="linkedin:title" content={seoTitle} />
        <meta name="linkedin:description" content={seoDescription} />
       

        {/* Standard SEO metadata */}
        <title>{seoTitle}</title>
        <meta name="description" content={seoDescription} />
        <meta name="keywords" content={keywords || 'phd, phd finder, find phd, phd vacancies, phd positions, research, academic opportunities, scholarships'} />

        {/* Twitter Card metadata */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@phdscanner" />
        <meta name="twitter:creator" content="@phdscanner" />
        <meta name="twitter:title" content={seoTitle} />
        <meta name="twitter:description" content={seoDescription} />
        <meta name="twitter:image" content={seoImage} />

        {/* LinkedIn Specific Metadata */}
        <meta property="og:see_also" content={linkedInCompany.companyUrl} />
        <meta property="og:description" content={linkedInCompany.companyDescription} />

        {/* Image Metadata */}
        

        {/* Structured Data */}
        {/* <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script> */}
      </Helmet>
    </>
  );
};

export default SEOMetadata;