import React, { useState, useEffect, useRef, useContext } from "react";
import { ChevronDown } from "lucide-react";
import phdscanner from "../../assets/phdscanner_logo.webp";
import { useNavigate } from "react-router-dom";
import UserProfilePopup from "../UserProfilePopUp/UserProfilePopup";
import { AuthContext } from "../../context/AuthContext";
import useAnalytics from '../../hooks/useAnalytics';

export default function Header() {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isServicesMenuOpen, setServicesMenuOpen] = useState(false);
  const navigate = useNavigate();
  const servicesMenuRef = useRef(null);
  const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID || 'G-XXXXXXXXXX';
  const { trackEvent } = useAnalytics(GA_MEASUREMENT_ID);

  const {
    userDetails: user,
    isUserDetailsLoading: loading,
    handleLogout,
  } = useContext(AuthContext);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (servicesMenuRef.current && !servicesMenuRef.current.contains(event.target)) {
        setServicesMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
    trackEvent({
      category: 'Menu',
      action: 'Toggle Mobile Menu',
      label: isMobileMenuOpen ? 'Closed' : 'Opened'
    });
  };

  const toggleServicesMenu = () => {
    setServicesMenuOpen(!isServicesMenuOpen);
    trackEvent({
      category: 'Menu',
      action: 'Toggle Services Menu',
      label: isServicesMenuOpen ? 'Closed' : 'Opened'
    });
  };

  const handleSignIn = () => {
    trackEvent({
      category: 'User',
      action: 'Sign In',
      label: 'User clicked Sign In button'
    });
    navigate("/login");
  };

  // Updated isActive function to handle dynamic routes
  const isActive = (path) => {
    const currentPath = window.location.pathname;

    // Handle home route
    if (path === '/home') {
      return currentPath === '/' || currentPath === '/home';
    }

    // Special handling for PhD vacancies route
    if (path === '/phd-vacancies/standard') {
      return currentPath.startsWith('/phd-vacancies/standard');
    }
    // Special handling for blog routes
    // Highlight `/blogs` if the path contains `/blog/`
    if (path === '/blogs' && currentPath.includes('/blog/')) {
      return true;
    }

    // For other routes, keep exact matching
    return currentPath === path;
  };

  return (
    <>
      <header className="fixed top-0 left-0 w-full z-50 bg-white/95 backdrop-blur-md border-b border-gray-100 transform-gpu">
        <nav className="px-4 lg:px-8 py-3">
          <div className="flex flex-wrap justify-between items-center mx-auto max-w-7xl">
            <a
              href="/home"
              className="flex items-center space-x-3 group transform transition duration-300 hover:scale-95"
            >
              <img
                src={phdscanner}
                className="h-12 w-auto object-contain lg:h-14"
                alt="PhDScanner Logo"
              />
              <span className="text-2xl lg:text-3xl font-extrabold text-[#2C2C2C]" style={{ fontFamily: 'Grocetask, sans-serif' }}>
                PhDScanner
              </span>
            </a>

            <div className="flex items-center lg:order-2 gap-4">
              {!loading && (
                <div className="flex items-center">
                  {user ? (
                    <UserProfilePopup user={user} handleLogout={handleLogout} />
                  ) : (
                    <button
                      className="relative inline-flex items-center justify-center px-6 py-2.5 font-semibold text-white transition-all duration-300 ease-out rounded-lg group bg-gradient-to-br from-[#DB4F40] to-[#C64537] hover:shadow-lg hover:shadow-red-100 hover:scale-105"
                      onClick={handleSignIn}
                    >
                      <span className="relative">Sign In</span>
                      <div className="absolute inset-0 rounded-lg opacity-0 group-hover:opacity-10 transition-opacity duration-300 bg-white/30"></div>
                    </button>
                  )}
                </div>
              )}
              <button
                type="button"
                className="inline-flex items-center p-2 text-gray-600 rounded-lg lg:hidden hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-[#DB4F40] transition-all"
                onClick={toggleMobileMenu}
              >
                <span className="sr-only">Open main menu</span>
                <svg
                  className={`w-6 h-6 ${isMobileMenuOpen ? "hidden" : "block"}`}
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                </svg>
                <svg
                  className={`w-6 h-6 ${isMobileMenuOpen ? "block" : "hidden"}`}
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>

            <div
              className={`lg:flex lg:w-auto lg:order-1 w-full ${isMobileMenuOpen ? "block" : "hidden"
                }`}
              id="mobile-menu-2"
            >
              <ul className="flex flex-col lg:flex-row lg:space-x-6 mt-4 lg:mt-0 bg-white lg:bg-transparent rounded-lg shadow-lg lg:shadow-none p-4 lg:p-0">
                {[
                  { path: "/home", label: "Home" },
                  { path: "/phd-vacancies/standard", label: "PhD Openings" },
                  { path: "/smart-picks", label: "Smart Picks" },
                  { path: "/blogs", label: "PhD Tips" },
                  { path: "/about", label: "About" },
                  { path: "/contact", label: "Contact" },
                ].map((item) => (
                  <li key={item.path} className="mb-2 lg:mb-0">
                    <a
                      href={item.path}
                      className={`relative block py-2 px-4 lg:px-3 text-lg font-medium rounded-md transition-colors duration-300
                      ${isActive(item.path)
                          ? "text-[#DB4F40] bg-[#FFE5E3] lg:bg-transparent before:absolute before:-bottom-1 before:left-0 before:w-full before:h-0.5 before:bg-[#DB4F40]"
                          : "text-gray-600 hover:text-[#DB4F40] hover:bg-[#FFE5E3] lg:hover:bg-transparent"
                        }
                    `}
                      style={{ fontFamily: 'Grocetask, sans-serif' }}
                    >
                      {item.label}
                    </a>
                  </li>
                ))}
                {user && user.role === "admin" && (
                  <li className="mb-2 lg:mb-0">
                    <a
                      href="/admin"
                      className={`block py-2 px-4 lg:px-3 text-lg font-medium rounded-md transition-colors duration-300
                      ${isActive("/admin")
                          ? "text-[#DB4F40] bg-[#FFE5E3] lg:bg-transparent"
                          : "text-gray-600 hover:text-[#DB4F40] hover:bg-[#FFE5E3] lg:hover:bg-transparent"
                        }
                    `}
                    >
                      Admin
                    </a>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </nav>
      </header>

      <div className="h-20"></div>
    </>
  );
}