import { Link } from 'react-router-dom';
import { ChevronRightIcon, HomeIcon } from '@heroicons/react/24/outline';
import { FaLocationDot, FaPhone } from "react-icons/fa6";
import { FaGraduationCap, FaUniversity, FaEnvelope, FaBuilding, FaClock, FaMapPin,FaHome } from "react-icons/fa";
import { useState, useEffect } from 'react';

const Breadcrumb = ({ items }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Function to truncate text based on screen size
  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) return text;
    return `${text.substring(0, maxLength)}...`;
  };

  // Get truncated items based on screen size
  const getDisplayItems = () => {
    if (isMobile && items.length > 2) {
      // On mobile, show only first and last items
      return [
        items[0],
        { ...items[items.length - 1], label: truncateText(items[items.length - 1].label, 25) }
      ];
    }
    return items.map((item, index) => ({
      ...item,
      label: index === items.length - 1 ? truncateText(item.label, isMobile ? 25 : 50) : item.label
    }));
  };

  const displayItems = getDisplayItems();

  return (
    <nav className="w-full overflow-hidden" aria-label="Breadcrumb">
      <ol className="flex flex-wrap items-center space-x-2 md:space-x-3 text-sm md:text-base font-medium">
        {displayItems.map((item, index) => (
          <li 
            key={index} 
            className={`inline-flex items-center ${
              index === displayItems.length - 1 ? 'flex-1 min-w-0' : ''
            }`}
          >
            {index === 0 ? (
              <Link
                to={item.href}
                className="inline-flex items-center text-[#1B222A] hover:text-[#DB4F40] transition-colors duration-200"
              >
                <FaHome className="w-4 h-4 md:w-5 md:h-5 mr-1.5 text-[#DB4F40]" />
                <span className="xs:inline">{item.label}</span>
              </Link>
            ) : (
              <>
                <ChevronRightIcon 
                  className="flex-shrink-0 w-4 h-4 md:w-5 md:h-5 text-gray-500 mx-1 md:mx-2" 
                />
                <Link
                  to={item.href}
                  className={`text-[#1B222A] hover:text-[#DB4F40] transition-colors duration-200 ${
                    index === displayItems.length - 1 ? 'font-semibold' : ''
                  }`}
                >
                  {item.label}
                </Link>
              </>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumb;