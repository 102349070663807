import React, { useState, useEffect } from "react";
import {
  AlertCircle,
  CheckCircle,
  Send,
  Calendar,
  Globe,
  Users,
  Link,
  User,
} from "lucide-react";
import { useMutations } from "../../hooks/useMutations";
import { OpportunityService } from "../../services/OpportunityService";
import { useSnackBar } from "../../hooks/useSnackBar";
import { Select } from "antd"; // Import Select from Ant Design for the dropdown
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


const { Option } = Select;

const Popup = ({ message, isSuccess, onClose }) => {
  return (
    <div
      className={`fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 ${!message ? "hidden" : ""}`}
    >
      <div
        className={`bg-white p-6 rounded-lg shadow-lg ${isSuccess ? "border-teal-500" : "border-red-500"} border-2 max-w-md w-full mx-4`}
      >
        <div className="flex items-center mb-4">
          {isSuccess ? (
            <CheckCircle className="h-6 w-6 text-red-500 mr-2" />
          ) : (
            <AlertCircle className="h-6 w-6 text-red-500 mr-2" />
          )}
          <h3
            className={`text-lg font-semibold ${isSuccess ? "text-red-700" : "text-red-700"}`}
          >
            {isSuccess ? "Success" : "Error"}
          </h3>
        </div>
        <p className="text-gray-600 mb-4">{message}</p>
        <button
          className="w-full px-4 py-2 bg-teal-600 text-white rounded-lg hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-opacity-50 transition duration-300"
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export const OpportunityForm = () => {
  const initialFormData = {
    category: "standard",
    title: "",
    department: "",
    university: "",
    university_url: "",
    city: "",
    country: "",
    closing_date: "",
    open_to: "",
    supervisor: "",
    supervisor_url: "",
    type: "",
    fully_funded: "false",
    opportunity_url: "",
    description: "",
  };
  const [formData, setFormData] = useState(initialFormData);
  const [universities, setUniversities] = useState([]);
  const [isCustomUniversity, setIsCustomUniversity] = useState(false);
  const [searchText, setSearchText] = useState("");

  const enqMsg = useSnackBar();

  // Fetch universities data on component mount
  useEffect(() => {
    const fetchUniversities = async () => {
      try {
        const response = await OpportunityService.getOpportunityUniversitiesCountries();
         // Remove duplicates based on university name and create unique IDs
         const uniqueUniversities = response.reduce((acc, current) => {
          const isDuplicate = acc.find(item => item.university === current.university);
          if (!isDuplicate) {
            acc.push({
              ...current,
              id: `${current.university}-${current.city}-${current.country}`.toLowerCase().replace(/\s+/g, '-')
            });
          }
          return acc;
        }, []);
        setUniversities(uniqueUniversities);; // Ensure we always have an array
      } catch (error) {
        enqMsg("Failed to fetch universities data", "error");
        setUniversities([]); // Set empty array on error
      }
    };
    fetchUniversities();
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleUniversityChange = (value) => {
    if (value === "custom") {
      setIsCustomUniversity(true);
      setFormData({
        ...formData,
        university: "",
        city: "",
        country: "",
        university_url: "",
      });
    } else {
      setIsCustomUniversity(false);
      const selectedUniversity = universities.find((uni) => uni.university === value);
      if (selectedUniversity) {
        setFormData({
          ...formData,
          university: selectedUniversity.university,
          city: selectedUniversity.city || "",
          country: selectedUniversity.country || "",
          university_url: selectedUniversity.university_url || "",
        });
      }
    }
  };

  const handleTitleChange = (e) => {
    setFormData({
      ...formData,
      title: e.target.value
    });
  };

  const handleDescriptionChange = (content) => {
    setFormData({
      ...formData,
      description: content,
    });
  };

  const { mutateAsync: saveOpportunity, isLoading: saving } = useMutations(
    OpportunityService.createOpportunity
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const closingDateEpoch = formData?.closing_date
        ? Math.floor(Date.UTC(...formData.closing_date.split('-').map((v, i) => i === 1 ? Number(v) - 1 : Number(v))) / 1000)
        : null;

      const closingDateString = closingDateEpoch ? closingDateEpoch.toString() : null;

      const res = await saveOpportunity({
        ...formData,
        closing_date: closingDateString,
        fully_funded: formData?.fully_funded === "true",
      });

      if (res?.error) {
        throw new Error(res?.error);
      } else {
        enqMsg(res?.message, "success");
      }
    } catch (error) {
      enqMsg(error);
    }
  };

  const inputStyle =
    "w-full rounded-md border border-[#DB4F40] py-2 px-3 focus:border-[#DB4F40] focus:ring focus:ring-[#DB4F40]/50 transition duration-300";
  const labelStyle = "block text-sm font-medium  mb-1";

  const formFields = [
    {
      name: "university_url",
      label: "University Website",
      type: "url",
      required: false,
      placeholder: "https://www.university.edu",
      icon: <Link className="h-5 w-5 text-red-500" />,
    },
    {
      name: "department",
      label: "Department",
      type: "text",
      required: true,
      placeholder: "Enter the department name",
      icon: <Users className="h-5 w-5 text-red-500" />,
    },
    {
      name: "city",
      label: "City",
      type: "text",
      required: true,
      icon: <Globe className="h-5 w-5 text-red-500" />,
    },
    {
      name: "country",
      label: "Country",
      type: "text",
      required: true,
      icon: <Globe className="h-5 w-5 text-red-500" />,
    },
    {
      name: "closing_date",
      label: "Closing Date",
      type: "date",
      required: true,
      icon: <Calendar className="h-5 w-5 text-red-500" />,
    },
    {
      name: "open_to",
      label: "Open To",
      type: "text",
      required: false,
      placeholder: "e.g., All nationalities, EU citizens only",
      icon: <Users className="h-5 w-5 text-red-500" />,
    },
    {
      name: "opportunity_url",
      label: "Opportunity URL",
      type: "url",
      required: false,
      placeholder: "https://www.university.edu/position/12345 (optional)",
      icon: <Link className="h-5 w-5 text-red-500" />,
    },
    {
      name: "supervisor",
      label: "Supervisor",
      type: "text",
      required: false,
      placeholder: "Enter supervisor name (optional)",
      icon: <User className="h-5 w-5 text-red-500" />,
    },
    {
      name: "supervisor_url",
      label: "Supervisor URL",
      type: "url",
      required: false,
      placeholder: "https://www.university.edu/supervisor or mailto:test@university.uk (optional)",
      icon: <Link className="h-5 w-5 text-red-500" />,
    },
  ];

  return (
    <div className="bg-[#EEA0ED]/20 min-h-screen py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto bg-white rounded-lg shadow-xl overflow-hidden">
        <div className="bg-[#DB4F40] py-6 px-4 sm:px-6 lg:px-8">
          <h2 className="text-2xl font-bold text-white text-center">
            Post PhD Position
          </h2>
        </div>
        <div className="p-6 sm:p-8">
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="mb-4">
              <label className="block text-sm font-medium text-[#1B222A] mb-2">
                Title
                <span className="text-red-500 ml-1">*</span>
              </label>
              <input
                type="text"
                name="title"
                value={formData.title}
                onChange={handleTitleChange}
                className="w-full rounded-md border border-[#DB4F40] py-2 px-3 focus:border-[#DB4F40] focus:ring focus:ring-[#DB4F40]/50 transition duration-300"
                required
                placeholder="Enter the opportunity title"
              />
            </div>

            <div className="mb-4">
              <label className="block text-sm font-medium text-[#1B222A] mb-2">
                University Name
                <span className="text-red-500 ml-1">*</span>
              </label>
              <Select
                showSearch
                style={{ width: '100%' }}
                value={formData.university || undefined}
                onChange={handleUniversityChange}
                placeholder="Select or enter the university name"
                optionFilterProp="children"
                virtual={true}
                onSearch={setSearchText}
                filterOption={(input, option) =>
                  option?.children?.toLowerCase().includes(input.toLowerCase())
                }
                dropdownRender={(menu) => (
                  <div>
                    {menu}
                    <div 
                      style={{ 
                        padding: '8px',
                        borderTop: '1px solid #e8e8e8'
                      }}
                    >
                      <input
                        type="text"
                        placeholder="Enter custom university"
                        className="w-full rounded-md border border-[#DB4F40] py-2 px-3 focus:border-[#DB4F40] focus:ring focus:ring-[#DB4F40]/50 transition duration-300"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleUniversityChange("custom");
                        }}
                        onChange={(e) => {
                          e.stopPropagation();
                          setFormData({
                            ...formData,
                            university: e.target.value,
                          });
                        }}
                        value={isCustomUniversity ? formData.university : ''}
                      />
                    </div>
                  </div>
                )}
              >
                {universities.map((uni) => (
                  <Option 
                    key={uni.university} 
                    value={uni.university}
                    title={uni.university}
                  >
                    {uni.university}
                  </Option>
                ))}
              </Select>
            </div>

            {formFields.slice(0).map((field, index) => (
              <div
                key={field.name}
                className={
                  index === 1 ? "grid grid-cols-1 sm:grid-cols-2 gap-6" : ""
                }
              >
                <div className={index === 1 ? "" : "mb-4"}>
                  <label htmlFor={field.name} className={labelStyle}>
                    {field.label}
                    {field.required && (
                      <span className="text-red-500 ml-1">*</span>
                    )}
                  </label>
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      {field.icon}
                    </div>
                    <input
                      type={field.type}
                      id={field.name}
                      name={field.name}
                      value={formData[field.name]}
                      onChange={handleChange}
                      className={`${inputStyle} pl-10`}
                      required={field.required}
                      placeholder={field.placeholder || field.label}
                    />
                  </div>
                </div>
                {index === 1 && (
                  <div>
                    <label htmlFor="fully_funded" className={labelStyle}>
                      Fully Funded
                    </label>
                    <select
                      id="fully_funded"
                      name="fully_funded"
                      value={formData.fully_funded}
                      onChange={handleChange}
                      className={inputStyle}
                    >
                      <option value="false">No</option>
                      <option value="true">Yes</option>
                    </select>
                  </div>
                )}
              </div>
            ))}


            <div className="mb-4">
              <label className="block text-sm font-medium text-[#1B222A] mb-2">
                Description
              </label>
              <div className="border border-[#DB4F40] h-96">
                <ReactQuill
                  value={formData.description}
                  onChange={handleDescriptionChange}
                  modules={{
                    toolbar: [
                      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                      ['bold', 'italic', 'underline', 'strike'],
                      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                      ['link', 'image'],
                      ['clean']
                    ]
                  }}
                  className="h-[calc(100%-40px)]"
                  placeholder="Describe the opportunity details (optional)"
                />
              </div>
            </div>

            <div className="mb-4">
              <button
                type="submit"
                className="w-full bg-[#DB4F40] text-white px-6 py-3 rounded-md font-semibold hover:bg-[#DB4F40]/90 focus:outline-none focus:ring-2 focus:ring-[#DB4F40] focus:ring-opacity-50 transition duration-300 flex items-center justify-center"
              >
                <Send className="h-5 w-5 mr-2" />
                Submit Opportunity
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};