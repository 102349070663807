import React from 'react';
import phdscanner from "../../assets/phdscanner_logo.webp";
import { ArrowUpRight } from 'lucide-react';
import { FaGraduationCap, FaUniversity, FaEnvelope } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import useAnalytics from '../../hooks/useAnalytics';

const SocialLink = ({ href, children, label, trackEvent }) => (
    <a
        href={href}
        className="group flex items-center gap-3 text-[#DB4F40] hover:text-[#c13426] transition-all duration-300 transform hover:scale-105"
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => trackEvent({
            category: 'Social Link',
            action: 'Click',
            label: label
        })}
    >
        {children}
        <span className="text-base font-semibold tracking-wide">{label}</span>
        <ArrowUpRight className="w-4 h-4 opacity-0 -translate-y-1 group-hover:opacity-100 group-hover:translate-y-0 transition-all duration-300" />
    </a>
);

const FooterSection = ({ title, children }) => (
    <div className="bg-white rounded-xl p-6 shadow-lg hover:shadow-xl transition-shadow duration-300 space-y-4">
        <h3 className="text-2xl font-bold text-[#1B222A] tracking-wide">
            {title}
        </h3>
        {children}
    </div>
);

export default function Footer() {
    const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID || 'G-XXXXXXXXXX';
    const { trackEvent } = useAnalytics(GA_MEASUREMENT_ID);

    return (
        <footer className="relative w-full py-16 font-poppins bg-gradient-to-b from-[#FFEEED] to-[#FFD6D4] border-t border-[#DB4F40]">
            <div className="relative z-10 max-w-7xl mx-auto px-6 md:px-8">
                <div className="grid grid-cols-1 md:grid-cols-12 gap-8 mb-12">
                    {/* Brand Section */}
                    <div className="md:col-span-4 bg-white rounded-xl p-6 shadow-lg hover:shadow-xl transition-shadow duration-300">
                        <a href="/home" className="flex items-center mb-6 transform hover:scale-105 transition-transform duration-300">
                            <img
                                src={phdscanner}
                                className="h-14 w-auto object-contain"
                                alt="PhDScanner Logo"
                            />
                            <span className="ml-3 text-3xl font-extrabold text-[#2C2C2C] bg-clip-text">
                                PhDScanner
                            </span>
                        </a>
                        <p className="text-[#1B222A] leading-relaxed text-lg opacity-80">
                            Empowering researchers and academics with cutting-edge tools for success in their academic journey.
                        </p>
                    </div>

                    {/* Quick Links Section */}
                    <div className="md:col-span-3">
                        <FooterSection title="Quick Links">
                            <nav className="space-y-4">
                                {[
                                    ['Belgium', 'Belgium'],
                                    ['Denmark', 'Denmark'],
                                    ['Netherlands', 'Netherlands'],
                                    ['Sweden', 'Sweden'],
                                    ['Germany', 'Germany']
                                ].map(([country, path]) => (
                                    <a
                                        key={country}
                                        href={`/phd-vacancies/standard/${path}`}
                                        className="group flex items-center gap-2 text-[#DB4F40] hover:text-[#c13426] transition-all duration-300 transform hover:scale-105"
                                    >
                                        <FaGraduationCap className="text-xl" />
                                        <span className="text-base font-semibold">PhD Openings in {country}</span>
                                        <ArrowUpRight className="w-4 h-4 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                                    </a>
                                ))}
                            </nav>
                        </FooterSection>
                    </div>

                    {/* Contact Section */}
                    <div className="md:col-span-3">
                        <FooterSection title="Contact Us">
                            <div className="space-y-6">
                                <a
                                    href="mailto:admin@phdscanner.com"
                                    className="flex items-center gap-3 text-[#DB4F40] hover:text-[#c13426] transition-all duration-300 transform hover:scale-105"
                                >
                                    <FaEnvelope className="text-2xl" />
                                    <span className="text-base font-semibold">admin@phdscanner.com</span>
                                </a>
                                <div className="bg-gray-50/50 rounded-lg p-4 transform hover:scale-105 transition-all duration-300 shadow-sm hover:shadow-md">
                                    <div className="flex items-start gap-3">
                                        <div className="bg-white p-2 rounded-full shadow-sm">
                                            <FaLocationDot className="text-[#DB4F40] text-2xl" />
                                        </div>
                                        <address className="not-italic space-y-2 text-base">
                                            <div className="font-semibold text-[#DB4F40]">PhDScanner</div>
                                            <div className="space-y-1 text-[#1B222A]/80">
                                                <div className="flex items-center">
                                                    <span>Richmond Rd</span>
                                                </div>
                                                <div className="flex items-center">
                                                    <span>Bradford BD7 1DP</span>
                                                </div>
                                                <div className="flex items-center">
                                                    <span>United Kingdom</span>
                                                </div>
                                            </div>
                                        </address>
                                    </div>
                                </div>
                            </div>
                        </FooterSection>
                    </div>

                    {/* Social Links Section */}
                    <div className="md:col-span-2">
                        <FooterSection title="Connect">
                            <div className="space-y-4">
                                {[
                                    {
                                        href: "https://www.linkedin.com/company/phdscanner",
                                        label: "LinkedIn",
                                        icon: () => (
                                            <svg className="w-5 h-5" viewBox="0 0 24 24" fill="currentColor">
                                                <path d="M19 3a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14m-.5 15.5v-5.3a3.26 3.26 0 0 0-3.26-3.26c-.85 0-1.84.52-2.32 1.3v-1.11h-2.79v8.37h2.79v-4.93c0-.77.62-1.4 1.39-1.4a1.4 1.4 0 0 1 1.4 1.4v4.93h2.79M6.88 8.56a1.68 1.68 0 0 0 1.68-1.68c0-.93-.75-1.69-1.68-1.69a1.69 1.69 0 0 0-1.69 1.69c0 .93.76 1.68 1.69 1.68m1.39 9.94v-8.37H5.5v8.37h2.77z" />
                                            </svg>
                                        )
                                    },
                                    {
                                        href: "https://x.com/PhdScanner",
                                        label: "Twitter",
                                        icon: () => (
                                            <svg className="w-5 h-5" viewBox="0 0 24 24" fill="currentColor">
                                                <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z" />
                                            </svg>
                                        )
                                    },
                                    {
                                        href: "https://www.facebook.com/Phdscanner",
                                        label: "Facebook",
                                        icon: () => (
                                            <svg className="w-5 h-5" viewBox="0 0 24 24" fill="currentColor">
                                                <path d="M9.101 23.691v-7.98H6.627v-3.667h2.474v-1.58c0-4.085 1.848-5.978 5.858-5.978.401 0 .955.042 1.468.103a8.68 8.68 0 0 1 1.141.195v3.325a8.623 8.623 0 0 0-.653-.036 26.805 26.805 0 0 0-.733-.009c-.707 0-1.259.096-1.675.309a1.686 1.686 0 0 0-.679.622c-.258.42-.374.995-.374 1.752v1.297h3.919l-.386 2.103-.287 1.564h-3.246v8.245C19.396 23.238 24 18.179 24 12.044c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.628 3.874 10.35 9.101 11.647Z" />
                                            </svg>
                                        )
                                    },
                                    {
                                        href: "https://www.instagram.com/phdscanner",
                                        label: "Instagram",
                                        icon: () => (
                                            <svg className="w-5 h-5" viewBox="0 0 24 24" fill="currentColor">
                                                <path d="M12 2c2.717 0 3.056.01 4.122.06 1.065.05 1.79.217 2.428.465.66.254 1.216.598 1.772 1.153a4.908 4.908 0 0 1 1.153 1.772c.247.637.415 1.363.465 2.428.047 1.066.06 1.405.06 4.122 0 2.717-.01 3.056-.06 4.122-.05 1.065-.218 1.79-.465 2.428a4.883 4.883 0 0 1-1.153 1.772 4.915 4.915 0 0 1-1.772 1.153c-.637.247-1.363.415-2.428.465-1.066.047-1.405.06-4.122.06-2.717 0-3.056-.01-4.122-.06-1.065-.05-1.79-.218-2.428-.465a4.89 4.89 0 0 1-1.772-1.153 4.904 4.904 0 0 1-1.153-1.772c-.248-.637-.415-1.363-.465-2.428C2.013 15.056 2 14.717 2 12c0-2.717.01-3.056.06-4.122.05-1.066.217-1.79.465-2.428a4.88 4.88 0 0 1 1.153-1.772A4.897 4.897 0 0 1 5.45 2.525c.638-.248 1.362-.415 2.428-.465C8.944 2.013 9.283 2 12 2zm0 5a5 5 0 1 0 0 10 5 5 0 0 0 0-10zm6.5-.25a1.25 1.25 0 1 0-2.5 0 1.25 1.25 0 0 0 2.5 0zM12 9a3 3 0 1 1 0 6 3 3 0 0 1 0-6z" />
                                            </svg>
                                        )
                                    }
                                ].map((social) => (
                                    <SocialLink
                                        key={social.label}
                                        href={social.href}
                                        label={social.label}
                                        trackEvent={trackEvent}
                                    >
                                        {social.icon()}
                                    </SocialLink>
                                ))}
                            </div>
                        </FooterSection>
                    </div>
                </div>

                {/* Copyright Section */}
                <div className="pt-8 border-t border-[#1B222A]/10">
                    <div className="flex flex-col md:flex-row justify-between items-center gap-4">
                        <p className="text-base text-[#1B222A] opacity-80 tracking-wide">
                            © 2025 PhDScanner. All rights reserved.
                        </p>
                        <a
                            href="/privacy-policy" // Replace with your actual Privacy Policy URL
                            className="text-base text-[#DB4F40] hover:text-[#c13426] transition-all duration-300"
                        >
                            Privacy Policy
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );
}