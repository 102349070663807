import React, { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import {
  FaHeart,
  FaRegHeart,
  FaCheckCircle,
  FaInfoCircle,
  FaUniversity,
  FaUserTie,
  FaCalendarAlt,
  FaEye
} from "react-icons/fa";
import useAnalytics from "../../hooks/useAnalytics";
import { OpportunityService } from "../../services/OpportunityService";

// Tooltip component for icons
const IconWithTooltip = ({ imgSrc, icon: Icon, label, color, className, position }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div className="relative inline-block">
      <div
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        {imgSrc ? (
          <img
            src={imgSrc}
            alt={label}
            className={`${color} ${className || "h-6 w-6"} object-contain`}
          />
        ) : Icon ? (
          <Icon className={`${color} ${className || "h-6 w-6"}`} />
        ) : null}
      </div>
      {showTooltip && (
        <div
          className={`absolute ${position === "right" ? "right-full" : "left-full"} ml-2 top-1/2 transform -translate-y-1/2 bg-[#1B222A] text-white text-sm rounded py-1 px-2 whitespace-nowrap z-10`}
        >
          {label}
        </div>
      )}
    </div>
  );
};

// Opportunity card component
const OpportunityCard = ({
  opportunity,
  user,
  savedOpportunities,
  handleToggleSave,
}) => {
  const [imgError, setImgError] = useState(false);
  const navigate = useNavigate();
  const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID || "G-XXXXXXXXXX";
  const { trackEvent } = useAnalytics(GA_MEASUREMENT_ID);

  const {
    id,
    university,
    title,
    department,
    supervisor,
    supervisor_url,
    closing_date,
    open_to,
    city,
    country,
    opportunity_url,
    fully_funded,
    ai_summary,
    created_at
  } = opportunity;

  const ageInfo = useMemo(() => {
    if (!created_at) return null;
    const createdAtEpochSeconds = parseInt(created_at, 10);
    if (isNaN(createdAtEpochSeconds)) return null;

    const createdAtEpochMs = createdAtEpochSeconds * 1000;
    const now = new Date();

    // Calculate two days back at 12 AM UTC
    const twoDaysBack = new Date(now);
    twoDaysBack.setUTCDate(now.getUTCDate() - 2);
    twoDaysBack.setUTCHours(0, 0, 0, 0);
    const twoDaysBackCutoff = twoDaysBack.getTime();

    // Calculate 3 and 7 days ago in milliseconds
    const currentTimeMs = now.getTime();
    const threeDaysAgoMs = currentTimeMs - 3 * 24 * 3600 * 1000;
    const sevenDaysAgoMs = currentTimeMs - 7 * 24 * 3600 * 1000;

    if (createdAtEpochMs >= twoDaysBackCutoff) {
      return {
        label: "Latest",
        bgColor: "bg-[#28a745]",
        textColor: "text-white",
      };
    } else if (
      createdAtEpochMs > sevenDaysAgoMs &&
      createdAtEpochMs <= threeDaysAgoMs
    ) {
      return {
        label: "One week old",
        bgColor: "bg-[#ffc107]",
        textColor: "text-[#1B222A]",
      };
    }
    return null;
  }, [created_at]);

  useEffect(() => {
    if (opportunity && opportunity.university) {
      const img = new Image();
      img.src = `./university/${opportunity.university}.png`;
      img.onload = () => setImgError(false);
      img.onerror = () => setImgError(true);
    }
  }, [opportunity]);

  const opportunityLink = OpportunityService.generateOpportunityLink(opportunity);
  const handleClick = () => {
    trackEvent({
      category: "Opportunity",
      action: "View Opportunity Details",
      label: opportunity.title,
    });
    navigate(`/opportunities/${opportunityLink}`);
  };

  const handleSaveToggle = () => {
    if (user) {
      handleToggleSave(opportunity.id);
      trackEvent({
        category: "Opportunity",
        action: savedOpportunities.has(opportunity.id) ? "Unsave Opportunity" : "Save Opportunity",
        label: opportunity.title,
      });
    }
  };

  if (!opportunity) {
    return (
      <div className="bg-gradient-to-br from-[#FFEEED] to-[#FFD1CE] rounded-xl shadow-md p-6 flex items-center justify-center h-full">
        <p className="text-[#1B222A]">Opportunity information not available</p>
      </div>
    );
  }


  return (
    <div className="bg-white/90 backdrop-blur-sm rounded-xl shadow-lg transition-transform duration-300 hover:shadow-xl hover:-translate-y-1 flex flex-col h-full border border-[#DB4F40]/20">
      <div className="p-6 flex-grow">
        <div className="flex items-center justify-between mb-4">
          <div className="flex-1 mr-4">
            <img
              src={`/university/${opportunity.university}.png`}
              alt={university}
              className="h-24 object-contain transition-opacity duration-300 hover:opacity-80"
              onError={() => setImgError(true)}
            />
          </div>
          <div className="flex-shrink-0 relative">
            <button
              onClick={handleSaveToggle}
              className={`text-[#DB4F40] hover:text-[#EEA0ED] transition-colors duration-300 ${!user ? "cursor-not-allowed opacity-50" : ""}`}
              disabled={!user}
            >
              <IconWithTooltip
                icon={savedOpportunities.has(id) ? FaHeart : FaRegHeart}
                label={
                  user
                    ? savedOpportunities.has(id)
                      ? "Unsave Opportunity"
                      : "Save Opportunity"
                    : "Login to save"
                }
                color=""
                className="h-8 w-8"
                position="left"
              />
            </button>
          </div>
        </div>

        <div className="flex flex-wrap gap-3 mb-4">
          {ageInfo && (
            <div className="px-3 py-1 bg-gradient-to-br from-[#DB4F40] to-[#C64537] text-white rounded-full text-sm font-medium flex items-center transition-all duration-300 hover:bg-[#5AC1E4]/30">
              {ageInfo.label}
            </div>
          )}
          {open_to && (
            <div className="px-3 py-1 bg-[#EEA0ED]/20 text-[#1B222A] rounded-full text-sm font-medium flex items-center transition-all duration-300 hover:bg-[#EEA0ED]/30">
              {open_to}
              <IconWithTooltip
                icon={FaInfoCircle}
                label={`Open to ${open_to}`}
                color="text-[#1B222A] ml-1"
                className="h-4 w-4"
              />
            </div>
          )}
          {fully_funded && (
            <div className="px-3 py-1 bg-[#5AC1E4]/20 text-[#1B222A] rounded-full text-sm font-medium flex items-center transition-all duration-300 hover:bg-[#5AC1E4]/30">
              <FaCheckCircle className="mr-2 text-[#DB4F40]" />
              Fully Funded
            </div>
          )}
        </div>

        <h3 className="text-lg font-semibold text-[#1B222A] mb-4 relative">
  <div className="flex items-center justify-between w-full">
    <span className="bg-left-bottom bg-no-repeat bg-gradient-to-r from-[#DB4F40] to-[#DB4F40] bg-[length:0%_2px] hover:bg-[length:100%_2px] transition-all duration-300 inline-block">
      {title}
    </span>
    <div className="ml-4 flex items-center group">
      <div className="bg-[#DB4F40]/80 text-white px-2.5 py-1 rounded-full text-xs flex items-center shadow-sm space-x-1.5 transition-all duration-200 group-hover:bg-[#DB4F40]">
        <FaEye className="w-3 h-3 group-hover:scale-110 transition-transform" />
        <span className="font-medium">{opportunity.views || 0}</span>
      </div>
    </div>
  </div>
</h3>

        <div className="space-y-3 text-sm text-[#1B222A]/70">
          {[
            {
              label: "Department",
              value: department,
              icon: FaUniversity,
              color: "text-[#DB4F40]",
            },
            {
              label: "Supervisor",
              value: supervisor,
              url: supervisor_url,
              icon: FaUserTie,
              color: "text-[#DB4F40]",
            },
            {
              label: "Deadline",
              value: closing_date,
              icon: FaCalendarAlt,
              color: "text-[#DB4F40]",
            },
          ].map((item, index) => {
            if (!item.value || item.value === "0") {
              return null;
            }

            return (
              <div key={index} className="flex items-center mb-2">
                <IconWithTooltip
                  icon={item.icon}
                  label={item.label}
                  color={item.color}
                  position="left"
                />
                <span className="ml-3 text-base">
                  {item.url ? (
                    <a href={item.url} className="hover:underline">
                      {item.value}
                    </a>
                  ) : (
                    item.label === "Deadline"
                      ? new Date(Number(item.value) * 1000).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })
                      : item.value
                  )}
                </span>
              </div>
            );
          })}
        </div>


        {ai_summary && (
          <div className="mb-2 bg-[#FFEEED]/30 rounded-lg p-4 mt-4">
            <h4 className="text-lg font-semibold text-[#1B222A] mb-2">Project Summary</h4>
            <p className="text-base text-[#1B222A]/70 leading-relaxed">{ai_summary}</p>
          </div>
        )}

      </div>

      <div className="px-6 py-4 bg-gradient-to-br from-[#FFEEED] to-[#FFD1CE] flex flex-col sm:flex-row items-start sm:items-center justify-between mt-auto">
        <div className="w-full sm:w-3/4 mb-2 sm:mb-0 sm:pr-4">
          <p className="font-semibold text-[#1B222A] truncate">{university}</p>
          <p className="text-sm text-[#1B222A]/70 truncate">
            {city}, {country}
          </p>
        </div>
        <div className="w-full sm:w-1/4 flex justify-start sm:justify-end">
          {opportunity_url && (
            <a
              href={`/opportunities/${opportunityLink}`}
              target="_blank"
              rel="noopener noreferrer"
              className="w-full sm:w-auto px-4 py-2 bg-gradient-to-br from-[#DB4F40] to-[#C64537] hover:from-[#C64537] hover:to-[#DB4F40] text-white font-bold rounded-md transition duration-300 text-sm text-center transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-[#DB4F40]/50 focus:ring-opacity-50 inline-block"
              role="button"
            >
              Details
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

// Grid component for opportunities
const OpportunityGrid = ({
  opportunities,
  user,
  savedOpportunities,
  handleToggleSave,
}) => (
  <div className="grid grid-cols-1 md:grid-cols-2 gap-8 w-full">
    {opportunities.map((opportunity, idx) => (
      <OpportunityCard
        key={idx}
        opportunity={opportunity}
        user={user}
        savedOpportunities={savedOpportunities}
        handleToggleSave={handleToggleSave}
      />
    ))}
  </div>
);

export default OpportunityGrid;