import { useContext, useState, useEffect, useRef } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useParams, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import SEOMetadata from "../SEOMetadata/SEOMetadata";
import useAnalytics from "../../hooks/useAnalytics";
import SocialShareButtons from "../SocialShareButtons/SocialShareButtons";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import {
  ExclamationCircleIcon,
  ArrowTopRightOnSquareIcon,
  CheckCircleIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";
import {
  FaHeart,
  FaRegHeart,
  FaUserTie,
  FaCalendarAlt,
  FaUniversity,
  FaMapPin,
} from "react-icons/fa";
import { useMutations } from "../../hooks/useMutations";
import { OpportunityService } from "../../services/OpportunityService";
import { useQuery } from "../../hooks/useQuery";

const IconWithTooltip = ({ icon: Icon, label, color, className, position }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div className="relative inline-block">
      <div
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        <Icon className={`${color} ${className || "h-5 w-5"}`} />
      </div>
      {showTooltip && (
        <motion.div
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          className={`absolute ${position === "left" ? "right-full" : "left-full"} ml-2 top-1/2 transform -translate-y-1/2 bg-[#1B222A] text-white text-xs rounded py-1 px-2 whitespace-nowrap z-10`}
        >
          {label}
        </motion.div>
      )}
    </div>
  );
};

function OpportunityDetail() {
  const { title } = useParams();
  const [opportunity, setOpportunity] = useState(null);
  const [loading, setLoading] = useState(true);
  const [imgError, setImgError] = useState(false);
  const [savedOpportunities, setSavedOpportunities] = useState(new Set());
  const imgRef = useRef(null);
  const location = useLocation();
  const { userDetails: user } = useContext(AuthContext);
  const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID || "G-XXXXXXXXXX";
  const { trackEvent } = useAnalytics(GA_MEASUREMENT_ID);

  // Fetch opportunity by ID
  const {
    data: getOpportunityById,
    error: getOpportunityByIdError,
    isLoading: getOpportunityByIdLoading,
    isSuccess: getOpportunityByIdSuccess,
  } = useQuery(
    ["getOpportunityById", title],
    () => OpportunityService.getOpportunityById(title),
    {
      enabled: !!title,
    }
  );

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [location.pathname]);

  useEffect(() => {
    if (getOpportunityByIdLoading) {
      setLoading(true);
    } else if (getOpportunityByIdSuccess && getOpportunityById) {
      setOpportunity(getOpportunityById);
      const opportunityLink = OpportunityService.generateOpportunityLink(getOpportunityById);
      window.history.pushState({}, "", opportunityLink);
      trackEvent({
        category: "Opportunity",
        action: "View Opportunity Details",
        label: `Opportunity ID: ${title}`,
      });
      setLoading(false);
    } else if (getOpportunityByIdError) {
      setLoading(false);
    }
  }, [
    getOpportunityByIdLoading,
    getOpportunityByIdSuccess,
    getOpportunityById,
    getOpportunityByIdError,
    title,
  ]);

  useEffect(() => {
    if (opportunity && opportunity.university) {
      const img = new Image();
      img.src = `/university/${opportunity.university}.png`;
      img.onload = () => setImgError(false);
      img.onerror = () => setImgError(true);
    }
  }, [opportunity]);

  const {
    data: getSavedOpportunities = [],
    error: savedOppError,
    isLoading: loadingSavedOpp,
    isSuccess: savedOppSuccess,
  } = useQuery(
    ["getSavedOpportunities", user?.id],
    () => OpportunityService.getSavedOpportunities({ userId: user.id }),
    {
      enabled: !!user,
    }
  );

  useEffect(() => {
    const opportunitiesArray = Array.isArray(getSavedOpportunities?.savedOpportunities)
      ? getSavedOpportunities.savedOpportunities
      : [];
    if (savedOppSuccess && opportunitiesArray.length > 0) {
      setSavedOpportunities(new Set(opportunitiesArray));
    }
  }, [getSavedOpportunities, savedOppSuccess]);

  const { mutateAsync: createSaveOpportunity } = useMutations(
    OpportunityService.createSavedOpportunity
  );

  const { mutateAsync: deleteSaveOpportunity } = useMutations(
    OpportunityService.deleteSavedOpportunities
  );

  const toggleSave = (opportunityId) => {
    if (!user) return;
    setSavedOpportunities((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(opportunityId)) {
        newSet.delete(opportunityId);
      } else {
        newSet.add(opportunityId);
      }
      return newSet;
    });
  };

  const handleApplyNowClick = () => {
    trackEvent({
      category: "Opportunity",
      action: "Apply Now Clicked",
      label: `Opportunity ID: ${opportunity.id}`,
    });
  };

  const handleSocialShare = (platform) => {
    trackEvent({
      category: "Social Share",
      action: `Share on ${platform}`,
      label: `Opportunity ID: ${opportunity.id} - ${opportunity.title}`,
    });
  };

  const handleToggleSave = async (opportunityId) => {
    if (!user || !opportunity) return;
    try {
      if (savedOpportunities.has(opportunityId)) {
        await deleteSaveOpportunity({
          user_id: user.id,
          opportunity_id: opportunityId,
        });
        toggleSave(opportunityId);
        trackEvent({
          category: "Opportunity",
          action: "Unsave Opportunity",
          label: `Opportunity ID: ${opportunityId}`,
        });
      } else {
        await createSaveOpportunity({
          user_id: user.id,
          opportunity_id: opportunityId,
        });
        toggleSave(opportunityId);
        trackEvent({
          category: "Opportunity",
          action: "Save Opportunity",
          label: `Opportunity ID: ${opportunityId}`,
        });
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const removeEmptyTags = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const removeEmpty = (node) => {
      for (let i = node.childNodes.length - 1; i >= 0; i--) {
        const child = node.childNodes[i];
        if (child.nodeType === 1) {
          removeEmpty(child);
          if (
            child.innerHTML.trim() === "" &&
            !["br", "hr"].includes(child.tagName.toLowerCase())
          ) {
            child.parentNode.removeChild(child);
          }
        } else if (child.nodeType === 3 && child.nodeValue.trim() === "") {
          child.parentNode.removeChild(child);
        }
      }
    };
    removeEmpty(doc.body);
    return doc.body.innerHTML;
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <motion.div
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
          className="text-center"
        >
          <div className="animate-spin mx-auto mb-4 h-16 w-16 border-t-4 border-b-4 border-[#DB4F40] rounded-full"></div>
          <p className="text-xl font-semibold text-[#1B222A]">
            Loading opportunity details...
          </p>
        </motion.div>
      </div>
    );
  }

  if (getOpportunityByIdError) {
    return (
      <div className="flex justify-center items-center h-screen">
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-white border-l-4 border-[#DB4F40] text-[#1B222A] p-4 sm:p-6 rounded-lg shadow-lg max-w-sm w-full"
          role="alert"
        >
          <div className="flex items-center">
            <ExclamationCircleIcon className="w-6 h-6 sm:w-8 sm:h-8 mr-3 sm:mr-4 text-[#DB4F40]" />
            <p className="font-bold text-sm sm:text-lg">
              An error occurred while fetching opportunity details.
            </p>
          </div>
        </motion.div>
      </div>
    );
  }

  if (!opportunity) {
    return (
      <div className="flex justify-center items-center h-screen bg-gradient-to-r from-[#FFEEED] to-[#FFD1CE]">
        <div className="text-center">
          <ExclamationCircleIcon className="mx-auto h-16 w-16 text-[#DB4F40]" />
          <p className="mt-4 text-xl font-semibold text-[#1B222A]">
            No details available for this opportunity
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-gradient-to-b from-[#FFF9F9] to-[#FFEEED] min-h-screen">
      <SEOMetadata
        title={`PhD Vacancies at ${opportunity.university}, ${opportunity.country}: ${opportunity.title}`}
        description={`Explore an exciting PhD opportunity in ${opportunity.department ? opportunity.department + " at " : ""}${opportunity.university}, ${opportunity.country}. Advance your academic career with cutting-edge research and exceptional academic support.`}
        keywords={`phdscanner,phd finder, find phd, phd, phd positions, phd vacancies, phd opportunities, phd openings, research position${opportunity.country ? `, ${opportunity.country}` : ""}${opportunity.university ? `, ${opportunity.university}` : ""}${opportunity.department ? `, ${opportunity.department}` : ""}`}
        opportunity={opportunity}
        url={window.location.href}
        imageUrl={`https://www.phdscanner.com/university/${encodeURIComponent(opportunity.university)}.png`}
      />

      <div className="max-w-6xl mx-auto px-4 sm:px-6 py-8 md:py-12">
        <div className="w-full mb-6 md:mb-8">
          {opportunity && (
            <Breadcrumb
              items={[
                { label: "Home", href: "/" },
                { label: "PhD Openings page", href: "/phd-vacancies/standard" },
              ]}
            />
          )}
        </div>

        <div className="bg-white/95 backdrop-blur-lg rounded-3xl shadow-2xl p-6 md:p-10 lg:p-12">
          {/* Header Section */}
          <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-8 gap-6">
            <div className="flex flex-1 items-start gap-6">
              <div className="flex-shrink-0 bg-white p-3 rounded-2xl shadow-lg border border-[#FFEEED]">
                {!imgError ? (
                  <img
                    src={`/university/${opportunity.university}.png`}
                    alt={opportunity.university}
                    className="h-24 w-24 object-contain"
                    onError={() => setImgError(true)}
                  />
                ) : (
                  <FaUniversity className="h-24 w-24 text-[#DB4F40]/30 p-3" />
                )}
              </div>
              <div className="space-y-1.5">
                <h2 className="text-2xl font-bold text-[#1B222A] leading-tight">
                  {opportunity.university}
                </h2>
                <div className="flex items-center text-[#1B222A]/80 space-x-2">
                  <FaMapPin className="text-[#DB4F40] flex-shrink-0" />
                  <span className="text-lg">{opportunity.city}, {opportunity.country}</span>
                </div>
              </div>
            </div>
            <div className="flex-shrink-0">
              <button
                onClick={() => user && handleToggleSave(`${opportunity.id}`)}
                className={`relative group flex justify-center items-center h-14 w-14 rounded-full border-2 border-[#DB4F40]/20 hover:border-[#DB4F40] transition-colors duration-200 ${
                  !user ? "opacity-50 cursor-not-allowed" : ""
                }`}
                disabled={!user}
              >
                {savedOpportunities.has(`${opportunity.id}`) ? (
                  <FaHeart className="h-7 w-7 text-[#DB4F40]" />
                ) : (
                  <FaRegHeart className="h-7 w-7 text-[#DB4F40]" />
                )}
                <div className="absolute -bottom-8 opacity-0 group-hover:opacity-100 transition-opacity duration-200 text-xs font-medium text-[#1B222A]/70">
                  {user ? "Save opportunity" : "Login to save"}
                </div>
              </button>
            </div>
          </div>

          {/* Main Content */}
          <div className="space-y-8">
            <h1 className="text-3xl md:text-4xl font-bold text-[#1B222A] leading-tight border-b-2 border-[#FFEEED] pb-6">
              {opportunity.title}
            </h1>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
              {opportunity.supervisor && (
                <DetailCard
                  icon={FaUserTie}
                  title="Supervisor"
                  value={opportunity.supervisor}
                  link={opportunity.supervisor_url}
                />
              )}
              {opportunity.closing_date > 0 && (
                <DetailCard
                  icon={FaCalendarAlt}
                  title="Deadline"
                  value={formatDate(opportunity.closing_date)}
                />
              )}
              {opportunity.department && (
                <DetailCard
                  icon={FaUniversity}
                  title="Department"
                  value={opportunity.department}
                />
              )}
            </div>

            {(opportunity.open_to || opportunity.fully_funded) && (
              <div className="flex flex-wrap gap-3">
                {opportunity.open_to && (
                  <Badge
                    text={opportunity.open_to}
                    color="emerald"
                    icon={InformationCircleIcon}
                  />
                )}
                {opportunity.fully_funded && (
                  <Badge
                    text="Fully Funded"
                    color="emerald"
                    icon={CheckCircleIcon}
                  />
                )}
              </div>
            )}

            <div
              className="prose prose-lg max-w-none mb-10 text-[#1B222A]/90"
              dangerouslySetInnerHTML={{
                __html: removeEmptyTags(opportunity.description),
              }}
            />

            <div className="space-y-6">
              <a
                href={opportunity.opportunity_url}
                target="_blank"
                rel="noopener noreferrer"
                onClick={handleApplyNowClick}
                className="inline-flex items-center px-10 py-4 bg-gradient-to-br from-[#DB4F40] to-[#C64537] text-white font-semibold rounded-xl hover:shadow-lg hover:scale-[1.02] transition-all"
              >
                Apply Now
                <ArrowTopRightOnSquareIcon className="ml-3 h-6 w-6 text-white/90" />
              </a>
              
              <SocialShareButtons
                title={`PhD Position: ${opportunity.title}`}
                description={`Check out this PhD position at ${opportunity.university}`}
                url={window.location.href}
                image={`https://phdscanner.com/university/${opportunity.university}.png`}
                onShare={handleSocialShare}
                className="justify-center gap-4"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// Updated DetailCard component
const DetailCard = ({ imgSrc, icon: Icon, title, value, link, className }) => (
  <div className={`bg-white border border-[#FFEEED] rounded-xl p-5 hover:border-[#DB4F40]/20 hover:shadow-md transition-all ${className}`}>
    <div className="flex items-center gap-3 mb-3">
      {imgSrc ? (
        <img src={imgSrc} className="h-9 w-9 text-[#DB4F40]" alt={title} />
      ) : (
        <Icon className="h-9 w-9 text-[#DB4F40] p-2 bg-[#FFEEED]/50 rounded-lg" />
      )}
      <h3 className="text-lg font-semibold text-[#1B222A]">{title}</h3>
    </div>
    {link ? (
      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        className="text-[#DB4F40] hover:text-[#C64537] transition-colors font-medium text-base inline-block"
      >
        {value}
      </a>
    ) : (
      <p className="text-[#1B222A]/80 text-base leading-snug">{value}</p>
    )}
  </div>
);

// Updated Badge component
const Badge = ({ text, color, icon: Icon }) => (
  <div className="flex items-center bg-[#FFEEED] px-4 py-2 rounded-full border border-[#FFD1CE]">
    {Icon && <Icon className="h-5 w-5 mr-2 text-[#DB4F40]" />}
    <span className="text-sm font-medium text-[#1B222A]">{text}</span>
  </div>
);

const DetailItem = ({ icon: Icon, label, color, children }) => (
  <motion.div
    whileHover={{ scale: 1.02 }}
    className="flex items-center space-x-3 p-4 bg-white rounded-lg shadow-sm hover:shadow-md transition-all duration-300"
  >
    <IconWithTooltip
      icon={Icon}
      label={label}
      color={color}
      className="w-6 h-6 flex-shrink-0"
    />
    <div className="text-gray-700 text-sm sm:text-base">{children}</div>
  </motion.div>
);

const isValidClosingDate = (date) => {
  if (!date) return false;
  const numDate = Number(date);
  return !isNaN(numDate) && numDate > 0 && numDate !== Infinity;
};

const formatDate = (timestamp) => {
  const date = new Date(Number(timestamp) * 1000);
  const now = new Date();
  const diffTime = date - now;
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  if (diffDays < 0) {
    return "Deadline passed";
  } else {
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  }
};

export default OpportunityDetail;
