import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useMemo,
  useCallback,
  lazy,
  Suspense
} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useParams } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { OpportunityService } from "../../services/OpportunityService";
import { useQuery } from "../../hooks/useQuery";
import { AuthContext } from "../../context/AuthContext";
import { useGetParams } from "../../hooks/useGetParams";
import { useMutations } from "../../hooks/useMutations";
import useAnalytics from '../../hooks/useAnalytics';
import SEOMetadata from "../SEOMetadata/SEOMetadata";
import SocialShareButtons from "../SocialShareButtons/SocialShareButtons";
import { FaGraduationCap, FaUniversity, FaFilter, FaGlobe, FaBook, FaClock, FaArrowRight, FaChartLine } from "react-icons/fa";
import ReactSelect from 'react-select';

// Lazy load heavy components
const OpportunityGrid = lazy(() => import('../OpportunityGrid/OpportunityGrid'));

function replaceHyphensWithSpaces(str) {
  return str.replace(/-/g, ' ');
}

const encodeSpaces = (str) => {
  return str ? str.replace(/ /g, '%20') : '';
};

function replaceSpacesWithHyphens(str) {
  return str.replace(/\s+/g, '-');
}

function useMemoizedDate(date) {
  return useMemo(() => {
    const day = date.getDate().toString().padStart(2, '0');
    const month = date.toLocaleString('en-US', { month: 'short' });
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }, [date]);
}

export const Opportunities = () => {
  const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID || 'G-XXXXXXXXXX';
  const { trackEvent } = useAnalytics(GA_MEASUREMENT_ID); // Initialize the analytics hook
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedUniversity, setSelectedUniversity] = useState("");
  const [selectedDiscipline, setSelectedDiscipline] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [showSavedOnly, setShowSavedOnly] = useState(false);
  const [showLast24HourOnly, setShowLast24HourOnly] = useState(false);
  const [finalSavedOpportunities, setSavedOpportunities] = useState(new Set());
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [showFullyFundedOnly, setShowFullyFundedOnly] = useState(false);
  const opportunitiesPerPage = 30;
  const sidebarRef = useRef(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);
  const { qsparams, setQueryParams } = useGetParams();
  const { userDetails: user } = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  // Extract parameters based on the URL structure
  const paramsFromUrl = useParams();
  const { category = "standard" } = paramsFromUrl;

  // Determine if the URL follows the new structure
  const isNewUrlStructure = location.pathname.includes("/discipline/");

  // Extract parameters dynamically based on the URL structure
  const { discipline, country, university } = isNewUrlStructure
    ? paramsFromUrl
    : { discipline: undefined, country: paramsFromUrl.country, university: paramsFromUrl.university };

  // Set effective values based on URL parameters
  const effectiveCategory = category || "standard";
  const effectiveCountry = replaceHyphensWithSpaces(country || "");
  const effectiveUniversity = replaceHyphensWithSpaces(university || "");
  const effectiveDiscipline = replaceHyphensWithSpaces(discipline || "");

  const getLast24HoursTimestamp = () => {
    const now = Math.floor(Date.now() / 1000);
    return now - (24 * 60 * 60);
  };

  const params = useMemo(() => {
    const opportunityParams = {
      category: category || 'standard',
      discipline: discipline ? JSON.stringify({ in: [replaceHyphensWithSpaces(discipline)] }) : undefined,
      country: country ? JSON.stringify({ in: [replaceHyphensWithSpaces(country)] }) : undefined,
      university: university ? JSON.stringify({ in: [replaceHyphensWithSpaces(university)] }) : undefined,
      department: discipline ? JSON.stringify({ in: [replaceHyphensWithSpaces(discipline)] }) : undefined,
      limit: opportunitiesPerPage, // Fixed at 30
      offset: (currentPage - 1) * opportunitiesPerPage, // Calculate offset
      'created_at[gt]': startDate ? Math.floor(startDate.getTime() / 1000) : undefined,
      'created_at[lt]': endDate ? Math.floor(endDate.getTime() / 1000) : undefined,
    };

    // Existing parameters for filters..
    if (showFullyFundedOnly) {
      opportunityParams['funded'] = true;
    }

    // Remove undefined values
    Object.keys(opportunityParams).forEach(key => opportunityParams[key] === undefined && delete opportunityParams[key]);

    return opportunityParams;
  }, [qsparams, showLast24HourOnly, showFullyFundedOnly, country, university, discipline, currentPage, opportunitiesPerPage]);

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);
  const { data: responseData = { data: [], pagination: { total: 0 } }, isLoading: loading } = useQuery(
    ["get all opportunities", params],
    OpportunityService.getAllOpportunities,
    params,
  );
  const opportunities = responseData.data;
  const { total } = responseData.pagination || { total: 0 };
  const totalPages = Math.ceil(total / opportunitiesPerPage);

  const { data: countries = [] } = useQuery(
    ["get all countries"],
    OpportunityService.getOpportunityCountries,
    { category: params?.category },
    { enabled: Boolean(params?.category) },
  );

  const { data: disciplines = [] } = useQuery(
    ["get all disciplines"],
    OpportunityService.getOpportunityDisciplineCount,
    { category: params?.category },
    { enabled: Boolean(params?.category) },
  );

  const { data: universities = [] } = useQuery(
    ["get all universities", params?.country],
    OpportunityService.getOpportunityCountryUni,
    { category: params?.category, country: params?.country },
    { enabled: Boolean(params?.category && params?.country) },
  );

  // Set selected values based on URL parameters
  useEffect(() => {
    if (country) setSelectedCountry(replaceHyphensWithSpaces(country));
    if (university) setSelectedUniversity(replaceHyphensWithSpaces(university));
    if (discipline) setSelectedDiscipline(replaceHyphensWithSpaces(discipline));
  }, [country, university, discipline]);

  // Clear all filters
  const handleClearFilters = () => {
    setSelectedCountry("");
    setSelectedUniversity("");
    setSelectedDiscipline("");
    setShowSavedOnly(false);
    setShowLast24HourOnly(false);
    setShowFullyFundedOnly(false);
    setQueryParams({}, { replace: true }, true); // Clear all query parameters
    navigate(`/phd-vacancies/${category}`); // Navigate to the base URL
  };

  const handleCountryChange = (event) => {
    const country = event.target.value;
    setSelectedCountry(country);
    setSelectedUniversity("");
    setSelectedDiscipline("");
    setShowSavedOnly(false); // Reset "Saved Selections" checkbox

    trackEvent({
      category: 'Filter',
      action: 'Select Country',
      label: country || 'None Selected'
    });

    // Construct the URL dynamically based on selected filters
    let newUrl = `/phd-vacancies/${category}`;

    if (isNewUrlStructure) {
      // New URL structure: /discipline/:discipline?/country/:country?/university/:university?
      if (selectedDiscipline) {
        newUrl += `/discipline/${replaceSpacesWithHyphens(selectedDiscipline)}`;
      }
      if (country) {
        newUrl += `/country/${replaceSpacesWithHyphens(country)}`;
      }
    } else {
      // Old URL structure: /:country?/:university?
      if (country) {
        newUrl += `/${replaceSpacesWithHyphens(country)}`;
      }
    }

    navigate(newUrl);
  };

  const handleUniversityChange = (event) => {
    const university = event.target.value;
    setSelectedUniversity(university);
    setShowSavedOnly(false); // Reset "Saved Selections" checkbox

    trackEvent({
      category: 'Filter',
      action: 'Select University',
      label: university || 'None Selected'
    });

    // Construct the URL dynamically based on selected filters
    let newUrl = `/phd-vacancies/${category}`;

    if (isNewUrlStructure) {
      // New URL structure: /discipline/:discipline?/country/:country?/university/:university?
      if (selectedDiscipline) {
        newUrl += `/discipline/${replaceSpacesWithHyphens(selectedDiscipline)}`;
      }
      if (selectedCountry) {
        newUrl += `/country/${replaceSpacesWithHyphens(selectedCountry)}`;
      }
      if (university) {
        newUrl += `/university/${replaceSpacesWithHyphens(university)}`;
      }
    } else {
      // Old URL structure: /:country?/:university?
      if (selectedCountry) {
        newUrl += `/${replaceSpacesWithHyphens(selectedCountry)}`;
      }
      if (university) {
        newUrl += `/${replaceSpacesWithHyphens(university)}`;
      }
    }

    navigate(newUrl);
  };

  const handleDisciplineChange = (event) => {
    const discipline = event.target.value;
    setSelectedDiscipline(discipline);
    setSelectedCountry("");
    setSelectedUniversity("");
    setShowSavedOnly(false); // Reset "Saved Selections" checkbox

    trackEvent({
      category: 'Filter',
      action: 'Select Discipline',
      label: discipline || 'None Selected'
    });

    // Construct the URL dynamically based on selected filters
    let newUrl = `/phd-vacancies/${category}`;

    // New URL structure: /discipline/:discipline?/country/:country?/university/:university?
    if (discipline) {
      newUrl += `/discipline/${replaceSpacesWithHyphens(discipline)}`;
    }

    navigate(newUrl);
  };


  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const gt = searchParams.get('created_at[gt]');
    const lt = searchParams.get('created_at[lt]');

    if (gt && !lt) {
      const gtTimestamp = parseInt(gt, 10);
      const endTimestamp = gtTimestamp + 24 * 60 * 60;
      searchParams.set('created_at[lt]', endTimestamp);
      navigate(`?${searchParams.toString()}`, { replace: true });
    }
  }, [navigate]);

  // Update startDate and endDate when URL params change
  useEffect(() => {
    const gt = qsparams['created_at[gt]'];
    const lt = qsparams['created_at[lt]'];
    setStartDate(gt ? new Date(gt * 1000) : null);
    setEndDate(lt ? new Date(lt * 1000) : null);
  }, [qsparams]);

  // Handle start date change
  const handleStartDateChange = (date) => {
    const newParams = { ...qsparams };

    if (date) {
      newParams['created_at[gt]'] = Math.floor(date.getTime() / 1000);
      if (!newParams['created_at[lt]']) {
        // Set end date to now if not present
        const now = new Date();
        newParams['created_at[lt]'] = Math.floor(now.getTime() / 1000);
        setEndDate(now);
      }
    } else {
      delete newParams['created_at[gt]'];
      // Remove end date if it was auto-added
      if (newParams['created_at[lt]'] && !qsparams['created_at[lt]']) {
        delete newParams['created_at[lt]'];
        setEndDate(null);
      }
    }
    trackEvent({
      category: 'Date Filter',
      action: 'Start Date change',
      label: university || 'None Selected'
    });

    setQueryParams(newParams, { replace: true }, true);
    setStartDate(date);
  };

  // Handle end date change
  const handleEndDateChange = (date) => {
    const newParams = { ...qsparams };

    if (date) {
      newParams['created_at[lt]'] = Math.floor(date.getTime() / 1000);
    } else {
      delete newParams['created_at[lt]'];
    }

    trackEvent({
      category: 'Date Filter',
      action: 'End Date change',
      label: university || 'None Selected'
    });

    setQueryParams(newParams, { replace: true }, true);
    setEndDate(date);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      // Check if the click is outside both the sidebar and any select elements
      const isSelectElement = event.target.tagName.toLowerCase() === 'select';
      const isSelectOption = event.target.tagName.toLowerCase() === 'option';

      // If the click is on a select or option element, don't close the sidebar
      if (isSelectElement || isSelectOption) {
        return;
      }

      // Ignore clicks on elements with data-ignore-outside-click attribute
      if (event.target.closest('[data-ignore-outside-click]')) {
        return;
      }

      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setIsSidebarOpen(false);
      }
    }

    // Only add the event listener if the sidebar is open
    if (isSidebarOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isSidebarOpen]);

  const filteredOpportunities = opportunities.filter(
    (opportunity) =>
      (!showSavedOnly || finalSavedOpportunities?.has(opportunity.id))
  );

  useEffect(() => {
    setCurrentPage(1);
  }, [selectedCountry, selectedUniversity, selectedDiscipline, showLast24HourOnly, showFullyFundedOnly]);

  const currentOpportunities = filteredOpportunities;

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    trackEvent({
      category: 'Pagination',
      action: 'Change Page',
      label: `Page ${newPage}`
    });
    window.scrollTo({ top: 10, behavior: "smooth" });
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleCheckboxChange = (setter, label) => {
    setter((prev) => !prev);
    trackEvent({
      category: 'Filter',
      action: 'Toggle Checkbox',
      label: label
    });

    // Update URL query parameters for the Fully Funded checkbox
    if (label === 'Fully Funded') {
      const newValue = !showFullyFundedOnly;
      if (newValue) {
        setQueryParams({ ...qsparams, funded: true }, { replace: true }, true);
      } else {
        const { funded: _, ...newParams } = qsparams; // Remove the funded parameter
        setQueryParams(newParams, { replace: true }, true);
      }
    }
  };

  const toggleSave = (opportunityId) => {
    if (!user) {
      return; // Redirect to login or handle user not logged in
    }

    setSavedOpportunities((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(opportunityId)) {
        newSet.delete(opportunityId);
      } else {
        newSet.add(opportunityId);
      }
      return newSet;
    });
  };

  const {
    data: getSavedOpportunities = [],
    error: savedOppError,
    isLoading: loadingSavedOpp,
    isSuccess: savedOppSuccess,
  } = useQuery(
    ["getSavedOpportunities", user?.id],
    () => OpportunityService.getSavedOpportunities({ userId: user.id }),
    {
      enabled: !!user,
    }
  );

  useEffect(() => {
    const opportunitiesArray = Array.isArray(getSavedOpportunities?.savedOpportunities)
      ? getSavedOpportunities.savedOpportunities
      : [];
    if (savedOppSuccess && opportunitiesArray.length > 0) {
      setSavedOpportunities(new Set(opportunitiesArray));
    }
  }, [getSavedOpportunities, savedOppSuccess]);

  const { mutateAsync: createSaveOpportunity } = useMutations(
    OpportunityService.createSavedOpportunity
  );

  const { mutateAsync: deleteSaveOpportunity } = useMutations(
    OpportunityService.deleteSavedOpportunities
  );

  const handleToggleSave = async (opportunityId) => {
    if (!user) {
      return; // Redirect to login or handle user not logged in
    }

    try {
      if (finalSavedOpportunities.has(opportunityId)) {
        await deleteSaveOpportunity({
          user_id: user.id,
          opportunity_id: opportunityId,
        });
        toggleSave(opportunityId);
        trackEvent({
          category: 'Opportunity',
          action: 'UnSave',
          label: opportunityId
        });
      } else {
        await createSaveOpportunity({
          user_id: user.id,
          opportunity_id: opportunityId,
        });
        toggleSave(opportunityId);
        trackEvent({
          category: 'Opportunity',
          action: 'Save',
          label: opportunityId
        });
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  // Effect to synchronize checkbox state with URL parameter
  useEffect(() => {
    const createdAtParam = qsparams['created_at[gt]'];
    if (createdAtParam) {
      setShowLast24HourOnly(true);
    } else {
      setShowLast24HourOnly(false);
    }

    const fundedParam = qsparams['funded'];
    if (fundedParam) {
      setShowFullyFundedOnly(true);
    } else {
      setShowFullyFundedOnly(false);
    }
  }, [qsparams]);

  const handleLast24HourChange = () => {
    const newValue = !showLast24HourOnly; // Determine the new value
    setShowLast24HourOnly(newValue); // Update the state

    // Update the URL parameters based on the checkbox state
    if (newValue) {
      const timestamp = getLast24HoursTimestamp();
      setQueryParams({ ...qsparams, 'created_at[gt]': timestamp }, { replace: true }, true);
    } else {
      // If the checkbox is unchecked, remove the created_at[gt] parameter from the URL
      const { 'created_at[gt]': _, ...newParams } = qsparams; // Remove the created_at[gt] parameter
      setQueryParams(newParams, { replace: true }, true);
    }

    // Track the checkbox change event
    trackEvent({
      category: 'Filter',
      action: 'Toggle Checkbox',
      label: 'Published in Last 24 Hours',
      value: newValue ? 'Enabled' : 'Disabled'
    });
  };

  const handleSocialShare = (platform) => {
    trackEvent({
      category: 'Social',
      action: 'Share',
      label: platform
    });
  };

  useEffect(() => {
    // Scroll to the top of the page when component mounts
    window.scrollTo(0, 10);
  }, []);

  return (
    <div className="min-h-screen bg-gray-100">
      <SEOMetadata
        title={`PhDScanner - ${total > 0 ? total : ''} PhD Vacancies${effectiveCountry ? ` in ${effectiveCountry}` : ''}${effectiveUniversity ? ` at ${effectiveUniversity}` : ''}`}
        description={`${useMemoizedDate(new Date())} -- Explore PhD positions${effectiveUniversity ? ` at ${effectiveUniversity}` : ''}${effectiveCountry ? ` in ${effectiveCountry}` : ''}. 
        ${total > 0 ? `We have ${total} PhD positions available${effectiveUniversity ? ` at ${effectiveUniversity}` : ''}${effectiveCountry ? ` in ${effectiveCountry}` : ''}. All listings are current, with no expired or closed opportunities.` : ''}`}
        keywords={`phdscanner, phd finder, find phd, phd vacancies${effectiveCountry ? `, ${effectiveCountry}` : ''}${effectiveUniversity ? `, ${effectiveUniversity}` : ''}`}
        imageUrl="https://www.phdscanner.com/phdscanner_logo.webp"
        url={`https://www.phdscanner.com/phd-vacancies/${effectiveCategory}${country ? `/${replaceSpacesWithHyphens(country)}` : ''}${university ? `/${replaceSpacesWithHyphens(university)}` : ''}`}
      />

      {/* Page Header Section */}
      <div className="bg-gradient-to-r from-[#FFBEB9] to-[#FFD1CE] border-b border-[#DB4F40]/20 shadow-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
          <div className="flex flex-col md:flex-row items-center justify-between space-y-4 md:space-y-0">
            <h1 className="text-2xl md:text-3xl font-extrabold text-gray-900">
              {category === "featured" ? "🌟 Featured Opportunities" :
                `${total > 0 ? total : ''} PhD Positions${discipline ? ` in ${replaceHyphensWithSpaces(discipline)}` : ''}${country ? ` in ${replaceHyphensWithSpaces(country)}` : ''}${university ? ` at ${replaceHyphensWithSpaces(university)}` : ''}`}
            </h1>

            {/* Social Share & Mobile Filter */}
            <div className="flex items-center gap-4">
              <button
                onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                className="md:hidden bg-[#DB4F40] text-white flex items-center gap-2 px-4 py-2 rounded-lg 
                         hover:bg-[#C64537] transition-colors shadow-sm hover:shadow-md"
              >
                <FaFilter className="w-4 h-4" />
                <span className="text-sm font-medium">Filters</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Two-Column Layout */}
      <div className="max-w-7xl mx-auto grid grid-cols-1 lg:grid-cols-[300px_1fr] gap-8 p-4 sm:p-6">
        {/* Sidebar Filters */}
        <aside
          ref={sidebarRef}
          className={`fixed lg:static inset-y-0 left-0 w-80 lg:w-full bg-white/90 backdrop-blur-sm lg:bg-transparent 
                    shadow-xl lg:shadow-none z-40 transform transition-transform duration-300 ease-out
                    ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'} lg:translate-x-0 overflow-y-auto`}
        >
          <div className="p-6 lg:p-0 space-y-8">
            {/* Mobile Close Button */}
            <div className="lg:hidden flex justify-end pb-4">
              <button
                onClick={() => setIsSidebarOpen(false)}
                className="text-gray-500 hover:text-[#DB4F40] transition-colors"
              >
                <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>

            {/* Filters Content */}
            <div className="space-y-8">

              {/* Discipline Selector */}
              <div className="space-y-4">
                <h3 className="text-lg font-bold text-gray-900 flex items-center">
                  <FaBook className="text-[#DB4F40] mr-2" />
                  Select Discipline
                </h3>

                <ReactSelect
                  options={[
                    { value: '', label: 'All Disciplines' },
                    ...disciplines.map(discipline => ({
                      value: discipline.ai_department,
                      label: `${discipline.ai_department} (${discipline.Count})`
                    }))
                  ]}
                  value={{
                    value: selectedDiscipline,
                    label: selectedDiscipline
                      ? `${selectedDiscipline} (${disciplines.find(d => d.ai_department === selectedDiscipline)?.Count || 0})`
                      : 'All Disciplines'
                  }}
                  onChange={(selectedOption) => handleDisciplineChange({
                    target: { value: selectedOption.value }
                  })}
                  isSearchable
                  placeholder="Search disciplines..."
                  className="react-select-container"
                  classNamePrefix="react-select"
                  styles={{
                    control: (base) => ({
                      ...base,
                      border: '2px solid #FFBEB9',
                      borderRadius: '12px',
                      padding: '8px 4px',
                      '&:hover': { borderColor: '#FFBEB9' },
                      '&:focus-within': {
                        borderColor: '#DB4F40',
                        boxShadow: '0 0 0 2px #DB4F40'
                      }
                    }),
                    option: (base, { isFocused }) => ({
                      ...base,
                      backgroundColor: isFocused ? '#FFBEB9' : 'white',
                      color: '#333'
                    })
                  }}
                />
              </div>

              {/* Country Selector */}
              <div className="space-y-4">
                <h3 className="text-lg font-bold text-gray-900 flex items-center">
                  <FaGlobe className="text-[#DB4F40] mr-2" />
                  Select Country
                </h3>

                <ReactSelect
                  options={[
                    { value: '', label: 'All Countries' },
                    ...countries.map(country => ({
                      value: country.country,
                      label: country.country
                    }))
                  ]}
                  value={{
                    value: selectedCountry,
                    label: selectedCountry || 'All Countries'
                  }}
                  onChange={(selectedOption) => handleCountryChange({
                    target: { value: selectedOption.value }
                  })}
                  isSearchable
                  placeholder="Search countries..."
                  className="react-select-container"
                  classNamePrefix="react-select"
                  styles={{
                    control: (base) => ({
                      ...base,
                      border: '2px solid #FFBEB9',
                      borderRadius: '12px',
                      padding: '8px 4px',
                      '&:hover': { borderColor: '#FFBEB9' },
                      '&:focus-within': {
                        borderColor: '#DB4F40',
                        boxShadow: '0 0 0 2px #DB4F40'
                      }
                    }),
                    option: (base, { isFocused }) => ({
                      ...base,
                      backgroundColor: isFocused ? '#FFBEB9' : 'white',
                      color: '#333'
                    })
                  }}
                />
              </div>

              {/* University Selector */}
              <div className="space-y-4">
                <h3 className="text-lg font-bold text-gray-900 flex items-center">
                  <FaUniversity className="text-[#DB4F40] mr-2" />
                  Select University
                </h3>

                <ReactSelect
                  options={[
                    { value: '', label: 'All Universities' },
                    ...universities.map(university => ({
                      value: university.university,
                      label: university.university
                    }))
                  ]}
                  value={{
                    value: selectedUniversity,
                    label: selectedUniversity || 'All Universities'
                  }}
                  onChange={(selectedOption) => handleUniversityChange({
                    target: { value: selectedOption.value }
                  })}
                  isSearchable
                  isDisabled={!selectedCountry}
                  placeholder={selectedCountry ? "Search universities..." : "Select a country first"}
                  className="react-select-container"
                  classNamePrefix="react-select"
                  styles={{
                    control: (base) => ({
                      ...base,
                      border: '2px solid #FFBEB9',
                      borderRadius: '12px',
                      padding: '8px 4px',
                      '&:hover': { borderColor: '#FFBEB9' },
                      '&:focus-within': {
                        borderColor: '#DB4F40',
                        boxShadow: '0 0 0 2px #DB4F40'
                      },
                      opacity: !selectedCountry ? 0.5 : 1
                    }),
                    option: (base, { isFocused }) => ({
                      ...base,
                      backgroundColor: isFocused ? '#FFBEB9' : 'white',
                      color: '#333'
                    })
                  }}
                />
              </div>

              {/* Calendar Selector */}
              {/* Calendar Selector */}
              <div className="space-y-4">
                <h3 className="text-lg font-bold text-gray-900 flex items-center">
                  <FaClock className="text-[#DB4F40] mr-2" />
                  Select Published Date
                </h3>
                <div className="flex flex-col gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">Start Date</label>
                    <DatePicker
                      selected={startDate}
                      onChange={handleStartDateChange}
                      selectsStart
                      startDate={startDate}
                      endDate={endDate}
                      placeholderText="Select start date"
                      className="w-full p-2 border-2 border-[#FFBEB9] rounded-lg focus:border-[#DB4F40] focus:ring-2 focus:ring-[#DB4F40] transition-colors"
                      isClearable
                      wrapperClassName="w-full"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">End Date</label>
                    <DatePicker
                      selected={endDate}
                      onChange={handleEndDateChange}
                      selectsEnd
                      startDate={startDate}
                      endDate={endDate}
                      minDate={startDate}
                      placeholderText="Select end date"
                      className="w-full p-2 border-2 border-[#FFBEB9] rounded-lg focus:border-[#DB4F40] focus:ring-2 focus:ring-[#DB4F40] transition-colors"
                      isClearable
                      wrapperClassName="w-full"
                    />
                  </div>
                </div>
              </div>

              {/* Quick Filters Section */}
              <div className="mb-6">
                <h3 className="text-lg font-semibold text-gray-800 mb-4 
                  border-b-2 border-indigo-100 pb-2 
                  font-['Poppins', 'sans-serif']">
                  Quick Filters
                </h3>
                <div className="space-y-4">
                  {[
                    {
                      label: "Funded",
                      state: showFullyFundedOnly,
                      onChange: () => handleCheckboxChange(setShowFullyFundedOnly, 'Fully Funded'),
                      description: "Minimal financial barriers"
                    }
                  ].map((checkbox, index) => (
                    <div
                      key={index}
                      className="flex items-center p-4 bg-white rounded-xl border border-[#FFD1CE]
                               hover:border-[#DB4F40] transition-colors shadow-sm"
                    >
                      <input
                        type="checkbox"
                        checked={checkbox.state}
                        onChange={checkbox.onChange}
                        className="h-5 w-5 text-[#DB4F40] border-2 border-[#FFBEB9] rounded-md 
                                 focus:ring-[#DB4F40] mr-4"
                      />
                      <div>
                        <p className="font-medium text-gray-900">{checkbox.label}</p>
                        <p className="text-sm text-gray-500 mt-1">{checkbox.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {/* Clear Filters Button */}
              <button
                onClick={handleClearFilters}
                className="w-full px-4 py-3 bg-[#DB4F40] text-white rounded-xl hover:bg-[#C64537] transition-colors shadow-sm hover:shadow-md"
              >
                Clear Filters
              </button>
            </div>
          </div>
        </aside>

        {/* Main Content */}
        <main className="flex-1">
          {loading ? (
            <div className="flex justify-center items-center h-64">
              <div className="animate-spin rounded-full h-12 w-12 border-4 border-[#DB4F40] border-t-transparent" />
            </div>
          ) : currentOpportunities.length > 0 ? (
            <div className="space-y-8">
              <Suspense fallback={
                <div className="flex justify-center items-center h-64">
                  <div className="animate-spin rounded-full h-12 w-12 border-4 border-[#DB4F40] border-t-transparent" />
                </div>
              }>
                <OpportunityGrid
                  opportunities={currentOpportunities}
                  user={user}
                  savedOpportunities={finalSavedOpportunities}
                  handleToggleSave={handleToggleSave}
                />
              </Suspense>

              {/* Pagination */}
              {totalPages > 1 && (
                <div className="flex flex-col sm:flex-row justify-center items-center gap-4 py-8">
                  <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    className="px-6 py-2 rounded-lg bg-white border border-[#FFBEB9] hover:border-[#DB4F40] text-[#DB4F40] hover:bg-[#FFEEED] disabled:opacity-50 transition-all"
                  >
                    Previous
                  </button>
                  <span className="px-6 py-2 bg-[#DB4F40] text-white rounded-lg shadow-sm">
                    Page {currentPage} of {totalPages}
                  </span>
                  <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    className="px-6 py-2 rounded-lg bg-white border border-[#FFBEB9] hover:border-[#DB4F40] text-[#DB4F40] hover:bg-[#FFEEED] disabled:opacity-50 transition-all"
                  >
                    Next
                  </button>
                </div>
              )}
            </div>
          ) : (
            <div className="text-center py-20">
              <p className="text-xl text-gray-600 mb-4">🎓 No opportunities found</p>
              <p className="text-gray-500">Try adjusting your filters or check back later</p>
            </div>
          )}
        </main>
      </div>
    </div>
  );
};