import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { FaFilter } from "react-icons/fa";
import { OpportunityService } from "../../services/OpportunityService";
import { useQuery } from "../../hooks/useQuery";
import { AuthContext } from "../../context/AuthContext";
import useAnalytics from '../../hooks/useAnalytics';
import SEOMetadata from "../SEOMetadata/SEOMetadata";
import { FaGraduationCap, FaUniversity, FaGlobe, FaBook, FaArrowRight, FaChartLine } from "react-icons/fa";
import OpportunityGrid from "../OpportunityGrid/OpportunityGrid";
import { useMutations } from "../../hooks/useMutations";

const SavedJobs = () => {
  const mainContentRef = useRef(null);
  const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID || 'G-XXXXXXXXXX';
  const { trackEvent } = useAnalytics(GA_MEASUREMENT_ID);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const opportunitiesPerPage = 30;
  const sidebarRef = useRef(null);
  const { userDetails: user } = useContext(AuthContext);
  const navigate = useNavigate();
  const [savedOpportunities, setSavedOpportunities] = useState([]);

  // Fetch saved opportunities
  const {
    data: getSavedOpportunities = [],
    error: savedOppError,
    isLoading: loadingSavedOpp,
    isSuccess: savedOppSuccess,
  } = useQuery(
    ["getSavedOpportunities", user?.id],
    () => OpportunityService.getSavedOpportunitiesDetails({ userId: user.id }),
    {
      enabled: !!user,
    }
  );

  useEffect(() => {
    if (savedOppSuccess && Array.isArray(getSavedOpportunities)) {
      setSavedOpportunities(getSavedOpportunities);
    }
  }, [getSavedOpportunities, savedOppSuccess]);

  const { mutateAsync: createSaveOpportunity } = useMutations(
    OpportunityService.createSavedOpportunity
  );

  const { mutateAsync: deleteSaveOpportunity } = useMutations(
    OpportunityService.deleteSavedOpportunities
  );

  const handleToggleSave = async (opportunityId) => {
    if (!user) {
      return;
    }

    try {
      const savedOpportunitiestmp = new Set(savedOpportunities.map(opp => opp.id));
      if (savedOpportunitiestmp.has(opportunityId)) {
        await deleteSaveOpportunity({
          user_id: user.id,
          opportunity_id: opportunityId,
        });
        trackEvent({
          category: 'Opportunity',
          action: 'UnSave',
          label: opportunityId
        });
      } else {
        await createSaveOpportunity({
          user_id: user.id,
          opportunity_id: opportunityId,
        });
        trackEvent({
          category: 'Opportunity',
          action: 'Save',
          label: opportunityId
        });
      }

      // Reload saved opportunities
      const updatedOpportunities = await OpportunityService.getSavedOpportunitiesDetails({ userId: user.id });
      setSavedOpportunities(updatedOpportunities);

    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  // Pagination logic
  const totalPages = Math.ceil(savedOpportunities.length / opportunitiesPerPage);
  const currentOpportunities = savedOpportunities.slice(
    (currentPage - 1) * opportunitiesPerPage,
    currentPage * opportunitiesPerPage
  );

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    trackEvent({
      category: 'Pagination',
      action: 'Change Page',
      label: `Page ${newPage}`
    });

    // Scroll to the top of the main content
    setTimeout(() => {
      if (mainContentRef.current) {
        mainContentRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }, 100);
  };

  // Handle window resize for sidebar
  useEffect(() => {
    const handleResize = () => {
      if (sidebarRef.current) {
        const windowHeight = window.innerHeight;
        const contentTop = sidebarRef.current.getBoundingClientRect().top;
        const contentHeight = windowHeight - contentTop;
        sidebarRef.current.style.height = `${contentHeight}px`;
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="min-h-screen bg-gray-100">
      <SEOMetadata
        title="PhDScanner - Saved Jobs"
        description="View your saved PhD opportunities on PhDScanner."
        keywords="phdscanner, saved jobs, phd vacancies"
        imageUrl="https://www.phdscanner.com/phdscanner_logo.webp"
        url="https://www.phdscanner.com/smart-picks"
      />

      <div className="max-w-7xl mx-auto grid grid-cols-1 gap-8 p-4 sm:p-6">
        <main ref={mainContentRef} className="flex-1">
          {/* If user is not logged in */}
          {!user ? (
            <div className="text-center py-20">
              <p className="text-xl text-gray-600 mb-4">🔒 Please log in to view your saved opportunities</p>
              <button
                onClick={() => navigate("/login")}
                className="mt-4 px-6 py-2 bg-[#DB4F40] text-white rounded-lg shadow-sm hover:bg-[#C64537] transition"
              >
                Login
              </button>
            </div>
          ) : loadingSavedOpp ? (
            <div className="flex justify-center items-center h-64">
              <div className="animate-spin rounded-full h-12 w-12 border-4 border-[#DB4F40] border-t-transparent" />
            </div>
         
          ) : currentOpportunities.length > 0 ? (
            <div className="space-y-8">
              <OpportunityGrid
                opportunities={currentOpportunities}
                user={user}
                savedOpportunities={new Set(savedOpportunities.map(opp => opp.id))}
                handleToggleSave={handleToggleSave}
              />

              {/* Pagination */}
              {totalPages > 1 && (
                <div className="flex flex-col sm:flex-row justify-center items-center gap-4 py-8">
                  <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    className="px-6 py-2 rounded-lg bg-white border border-[#FFBEB9] 
                             hover:border-[#DB4F40] text-[#DB4F40] hover:bg-[#FFEEED] 
                             disabled:opacity-50 transition-all"
                  >
                    Previous
                  </button>
                  <span className="px-6 py-2 bg-[#DB4F40] text-white rounded-lg shadow-sm">
                    Page {currentPage} of {totalPages}
                  </span>
                  <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    className="px-6 py-2 rounded-lg bg-white border border-[#FFBEB9] 
                             hover:border-[#DB4F40] text-[#DB4F40] hover:bg-[#FFEEED] 
                             disabled:opacity-50 transition-all"
                  >
                    Next
                  </button>
                </div>
              )}
            </div>
          ) : (
            <div className="text-center py-20">
              <p className="text-xl text-gray-600 mb-4">🎓 No PhD opportunities saved by you</p>
              <p className="text-gray-500">Save opportunities to view them here</p>
            </div>
          )}
        </main>
      </div>
    </div>
  );
};

export default SavedJobs;
