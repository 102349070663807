import React, { useState, useEffect, useRef } from "react";
import {
  MessageSquare,
  Plus,
  Edit,
  Menu,
  X,
  Eye,
  Trash2,
} from "lucide-react";
import { OpportunityForm } from "../OpportunityForm/OpportunityForm";
import { UpdateOpportunity } from "../UpdateOpportunity/UpdateOpportunity";
import { ContactMessage } from "../../services/ContactMessage";

const AdminDashboard = () => {
  const [messages, setMessages] = useState([]);
  const [selectedSection, setSelectedSection] = useState("add-opportunity");
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const sidebarRef = useRef(null);
  const contentRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (sidebarRef.current && contentRef.current) {
        const windowHeight = window.innerHeight;
        const contentTop = contentRef.current.getBoundingClientRect().top;
        const contentHeight = windowHeight - contentTop;
        sidebarRef.current.style.height = `${contentHeight}px`;
        contentRef.current.style.minHeight = `${contentHeight}px`;
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const fetchMessage = async () => {
      if (selectedSection === "messages") {
        const message = await ContactMessage.getMessage();
        setMessages(message); // Assign the output to selectedMessage
      }
    };
  
    fetchMessage();
  }, [selectedSection]);

  const handleDelete = async (id) => {
    try {
      await ContactMessage.deleteMessageById(id); // Call the new function
      // Update the local state by filtering out the deleted message
      setMessages(messages.filter((message) => message.id !== id));
    } catch (error) {
      console.error("Error deleting message:", error);
    }
  };

  const MessageCard = ({ message, onView, onDelete }) => {
    const formatDate = (dateString) => {
      return new Date(dateString).toLocaleString(undefined, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      });
    };
    
    return (
      <div className="bg-white rounded-xl shadow-sm hover:shadow-md transition-all duration-300 border border-gray-100 p-6">
        {/* Name */}
        <div className="mb-4">
          <p className="text-xs font-semibold text-gray-600 uppercase tracking-wider mb-1">Name</p>
          <h4 className="font-semibold text-lg text-gray-800">{message.name}</h4>
        </div>
  
        {/* Email */}
        <div className="mb-4">
          <p className="text-xs font-semibold text-gray-600 uppercase tracking-wider mb-1">Email</p>
          <p className="text-sm text-gray-600">{message.email}</p>
        </div>
  
        {/* Message */}
        <div className="mb-4">
          <p className="text-xs font-semibold text-gray-600 uppercase tracking-wider mb-1">Message</p>
          <p className="text-gray-700 leading-relaxed">
            {message.message.length > 100
              ? `${message.message.substring(0, 100)}...`
              : message.message}
          </p>
        </div>
  
        {/* Timestamp */}
        <div className="mb-4">
          <p className="text-xs font-semibold text-gray-600 uppercase tracking-wider mb-1">Received</p>
          <p className="text-sm text-gray-500">{formatDate(message.created_at)}</p>
        </div>
  
        {/* Action Buttons */}
        <div className="flex justify-end items-center space-x-3 pt-2">
          <button
            onClick={() => onView(message)}
            className="inline-flex items-center px-4 py-2 bg-[#DB4F40] text-white rounded-lg hover:bg-[#c54538] transition-colors duration-200 text-sm font-medium"
          >
            <Eye className="w-4 h-4 mr-2" />
            View Details
          </button>
          
          <button
            onClick={() => onDelete(message.id)}
            className="inline-flex items-center px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors duration-200 text-sm font-medium"
          >
            <Trash2 className="w-4 h-4 mr-2" />
            Delete
          </button>
        </div>
      </div>
    );
  };
  

  const Sidebar = () => (
    <aside
      ref={sidebarRef}
      className={`
        fixed md:static inset-y-0 left-0 w-80 
        bg-white shadow-lg z-40 transform transition-transform duration-300
        ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'}
        md:translate-x-0 overflow-y-auto
      `}
    >
      {/* Mobile Close Button */}
      <div className="md:hidden flex justify-end p-4">
        <button
          onClick={() => setIsSidebarOpen(false)}
          className="text-gray-500 hover:text-gray-700 transition-colors"
        >
          <X className="h-6 w-6" />
        </button>
      </div>

      <div className="p-4 md:p-6 space-y-6">
        <div className="mb-6">
          <div className="flex items-center justify-between mb-4 border-b-2 border-[#EEA0ED]/30 pb-2">
            <h3 className="text-lg font-semibold text-gray-800 font-['Poppins', 'sans-serif']">
              Admin Dashboard
            </h3>
          </div>
          <div className="space-y-4">
            <button
              onClick={() => {
                setSelectedSection("add-opportunity");
                setIsSidebarOpen(false);
              }}
              className={`w-full flex items-center justify-between 
                bg-[#EEA0ED]/10 p-3 rounded-lg hover:bg-[#EEA0ED]/20 
                transition duration-300 group
                ${selectedSection === "add-opportunity" ? "bg-[#EEA0ED]/20" : ""}`}
            >
              <div className="flex items-center space-x-3">
                <Plus className="h-5 w-5 text-[#DB4F40]" />
                <div>
                  <p className="text-sm font-semibold text-gray-800 group-hover:text-[#DB4F40] transition">
                    Add Opportunity
                  </p>
                  <p className="text-xs text-gray-500">Create new opportunity</p>
                </div>
              </div>
            </button>

 {/* New Update Opportunity Button */}
          <button
              onClick={() => {
                setSelectedSection("update-opportunity");
                setIsSidebarOpen(false);
              }}
              className={`w-full flex items-center justify-between 
                bg-[#EEA0ED]/10 p-3 rounded-lg hover:bg-[#EEA0ED]/20 
                transition duration-300 group
                ${selectedSection === "update-opportunity" ? "bg-[#EEA0ED]/20" : ""}`}
            >
              <div className="flex items-center space-x-3">
                <Edit className="h-5 w-5 text-[#DB4F40]" />
                <div>
                  <p className="text-sm font-semibold text-gray-800 group-hover:text-[#DB4F40] transition">
                    Update Opportunity
                  </p>
                  <p className="text-xs text-gray-500">Modify existing opportunities</p>
                </div>
              </div>
            </button>

            <button
              onClick={() => {
                setSelectedSection("messages");
                setIsSidebarOpen(false);
              }}
              className={`w-full flex items-center justify-between 
                bg-[#EEA0ED]/10 p-3 rounded-lg hover:bg-[#EEA0ED]/20 
                transition duration-300 group
                ${selectedSection === "messages" ? "bg-[#EEA0ED]/20" : ""}`}
            >
              <div className="flex items-center space-x-3">
                <MessageSquare className="h-5 w-5 text-[#DB4F40]" />
                <div>
                  <p className="text-sm font-semibold text-gray-800 group-hover:text-[#DB4F40] transition">
                    Messages
                  </p>
                  <p className="text-xs text-gray-500">View incoming messages</p>
                </div>
              </div>
            </button>

            
          </div>
        </div>
      </div>
    </aside>
  );

  return (
    <div className="min-h-screen bg-gray-100">
      {/* Header */}
      <div className="bg-[#FFEEED] shadow-md p-4">
        <div className="max-w-screen-xl mx-auto">
          <div className="flex items-center justify-between">
            <h1 className="text-xl md:text-3xl font-bold text-gray-900">
            {selectedSection === "messages" 
                ? "Messages" 
                : selectedSection === "add-opportunity"
                ? "Add New Opportunity"
                : "Update Opportunity"}
            </h1>

            {/* Mobile Filter Button */}
            <button
              onClick={() => setIsSidebarOpen(!isSidebarOpen)}
              className="md:hidden bg-[#DB4F40] hover:bg-[#c54538] text-white focus:ring-2 focus:ring-[#DB4F40]/50 flex items-center gap-2 px-3 py-2 rounded-lg"
            >
              <Menu className="w-4 h-4" />
              <span className="text-sm font-medium">Menu</span>
            </button>
          </div>
        </div>
      </div>

      {/* Two-Column Layout */}
      <div className="grid grid-cols-1 md:grid-cols-[320px_1fr]">
        <Sidebar />

        {/* Main Content */}
        <main ref={contentRef} className="bg-gray-100 p-4 md:p-6 w-full">
          {selectedSection === "messages" && (
            <div className="grid gap-6">
              {messages.map((message) => (
                <MessageCard
                  key={message.id}
                  message={message}
                  onView={setSelectedMessage}
                  onDelete={handleDelete}
                />
              ))}
            </div>
          )}

          {selectedSection === "add-opportunity" && <OpportunityForm />}
          {selectedSection === "update-opportunity" && <UpdateOpportunity />}
        </main>
      </div>

      {/* Message Modal */}
      {selectedMessage && (
       <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center p-4 z-50">
       <div className="bg-white w-full max-w-md p-6 rounded-lg shadow-lg border border-gray-200">
         {/* Header with Close Button */}
         <div className="flex justify-between items-center mb-4 border-b pb-2">
           <h4 className="text-xl font-semibold text-indigo-700">Message Details</h4>
           <button
             onClick={() => setSelectedMessage(null)}
             className="text-gray-600 hover:text-gray-800 transition"
           >
             <X className="h-5 w-5" />
           </button>
         </div>
     
         {/* Name */}
         <div className="mb-2">
           <p className="text-sm font-semibold text-gray-700">Name:</p>
           <p className="text-gray-800">{selectedMessage.name}</p>
         </div>
     
         {/* Email */}
         <div className="mb-2">
           <p className="text-sm font-semibold text-gray-700">Email:</p>
           <p className="text-gray-800">{selectedMessage.email}</p>
         </div>
     
         {/* Message Content */}
         <div className="mb-2">
           <p className="text-sm font-semibold text-gray-700">Message:</p>
           <div className="mt-1 p-3 bg-gray-100 rounded-lg text-gray-700 whitespace-pre-wrap">
             {selectedMessage.message}
           </div>
         </div>
     
         {/* Timestamp */}
         <div className="mt-3">
           <p className="text-sm font-semibold text-gray-700">Received At:</p>
           <p className="text-xs text-gray-500">{new Date(selectedMessage.created_at).toLocaleString()}</p>
         </div>
       </div>
     </div>
     
     
      )}
    </div>
  );
};

export default AdminDashboard;