import React, { useState, useEffect, useRef, useContext } from "react";
import useAnalytics from '../../hooks/useAnalytics';
import { useMutations } from "../../hooks/useMutations";
import { useQuery } from "../../hooks/useQuery";
import { AuthContext } from "../../context/AuthContext";
import { OpportunityService } from "../../services/OpportunityService";
import { useNavigate } from "react-router-dom";
import SEOMetadata from "../SEOMetadata/SEOMetadata";
import { X } from "lucide-react"; // Import the X icon for the close button

const CustomMultiSelect = ({ options, selected, onSelect, maxSelections, fieldName, placeholder }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const dropdownRef = useRef(null);

  const filteredOptions = options.filter(option =>
    option.toLowerCase().includes(searchQuery.toLowerCase())
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleCheckboxChange = (option) => {
    const newSelected = selected.includes(option)
      ? selected.filter(item => item !== option)
      : [...selected, option];

    if (newSelected.length > maxSelections) {
      alert(`Maximum ${maxSelections} selections allowed for ${fieldName}`);
      return;
    }

    onSelect(newSelected);
  };

  return (
    <div className="relative w-full" ref={dropdownRef}>
      <div
        className="p-2 border-2 border-[#DB4F40]/20 rounded-lg bg-white cursor-pointer hover:border-[#DB4F40]/40"
        onClick={() => setIsOpen(!isOpen)}
      >
        {selected.length > 0 ? (
          <div className="flex flex-wrap gap-1 overflow-x-auto max-h-20">
            {selected.map(item => (
              <span
                key={item}
                className="px-2 py-1 bg-[#FFEEED]/50 rounded-full text-sm text-[#DB4F40] truncate"
              >
                {item}
              </span>
            ))}
          </div>
        ) : (
          <span className="text-gray-400 text-sm md:text-base">{placeholder}</span>
        )}
      </div>

      {isOpen && (
        <div className="absolute z-10 w-full mt-1 bg-white border-2 border-[#DB4F40]/20 rounded-lg shadow-lg">
          <input
            type="text"
            placeholder="Search..."
            className="p-2 w-full border-b-2 border-[#DB4F40]/20 focus:outline-none focus:border-[#DB4F40]/40 text-sm md:text-base"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <div className="max-h-48 md:max-h-64 overflow-y-auto">
            {filteredOptions.map(option => (
              <label
                key={option}
                className="flex items-center p-2 md:p-3 hover:bg-[#FFEEED]/30 cursor-pointer"
              >
                <input
                  type="checkbox"
                  checked={selected.includes(option)}
                  onChange={() => handleCheckboxChange(option)}
                  className="h-4 w-4 text-[#DB4F40] border-2 border-[#DB4F40]/40 rounded focus:ring-[#DB4F40]/40"
                />
                <span className="ml-2 text-[#1B222A] text-sm md:text-base truncate">{option}</span>
              </label>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const SelectedList = ({ items, onRemove }) => {
  return (
    <div className="h-48 md:h-64 overflow-y-auto border-2 border-[#DB4F40]/20 rounded-lg p-2">
      {items.map(item => (
        <div
          key={item}
          className="flex justify-between items-center p-1.5 md:p-2 hover:bg-[#FFEEED]/30 rounded"
        >
          <span className="text-sm text-[#1B222A] truncate">{item}</span>
          <button
            onClick={() => onRemove(item)}
            className="text-[#DB4F40] hover:text-[#C64537] font-bold text-lg md:text-base px-2"
          >
            ×
          </button>
        </div>
      ))}
    </div>
  );
};

const UserPreferences = () => {
  const navigate = useNavigate();
  const { userDetails: user } = useContext(AuthContext);
  const [countries, setCountries] = useState([]);
  const [universities, setUniversities] = useState([]);
  const [disciplines, setDisciplines] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedUniversities, setSelectedUniversities] = useState([]);
  const [selectedDisciplines, setSelectedDisciplines] = useState([]);
  const [comments, setComments] = useState(""); // State for comments
  const [isPopupVisible, setIsPopupVisible] = useState(false); // State for popup visibility
  const [popupMessage, setPopupMessage] = useState(""); // State for popup message
  const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID || 'G-XXXXXXXXXX';
  const { trackEvent } = useAnalytics(GA_MEASUREMENT_ID);

  // Fetch existing user preferences
  const { data: userPreferences } = useQuery(
    ["userPreferences", user?.id],
    () => OpportunityService.getUserPreference(user.id),
    { enabled: !!user }
  );

  // Fetch countries, universities, and disciplines
  const { data: countriesData } = useQuery(
    ["countries"],
    () => OpportunityService.getOpportunityCountries(),
    { enabled: !!user }
  );

  const { data: universitiesData } = useQuery(
    ["universities"],
    () => OpportunityService.getOpportunityUniversities(),
    { enabled: !!user }
  );

  const { data: disciplinesData } = useQuery(
    ["disciplines"],
    () => OpportunityService.getOpportunityDiscipline(),
    { enabled: !!user }
  );

  useEffect(() => {
    if (countriesData) setCountries(countriesData.map(c => c.country));
    if (universitiesData) setUniversities(universitiesData.map(u => u.university));
    if (disciplinesData) setDisciplines(disciplinesData.map(d => d.ai_department));
  }, [countriesData, universitiesData, disciplinesData]);

  // Set existing preferences when userPreferences is fetched
  useEffect(() => {
    if (userPreferences) {
      setSelectedCountries(userPreferences.countries || []);
      setSelectedUniversities(userPreferences.universities || []);
      setSelectedDisciplines(userPreferences.departments || []);
      setComments(userPreferences.comments || ""); // Set existing comments
    }
  }, [userPreferences]);

  const { mutateAsync: savePreferences } = useMutations(
    OpportunityService.savedUserPreference
  );

  // Handle saving preferences
  const handleSavePreferences = async () => {
    try {
      await savePreferences({
        user_id: user.id,
        countries: selectedCountries,
        universities: selectedUniversities,
        departments: selectedDisciplines,
        comments: comments, // Include comments in the payload
      });
      trackEvent({
        category: 'UserPreference',
        action: 'Save',
        label: 'UserPreference'
      });
      setPopupMessage("Preferences saved successfully!"); // Set success message
      setIsPopupVisible(true); // Show popup
    } catch (error) {
      console.error("Failed to save preferences:", error);
      setPopupMessage("Failed to save preferences. Please try again."); // Set error message
      setIsPopupVisible(true); // Show popup
    }
  };

  // Handle closing the popup
  const handleClosePopup = () => {
    setIsPopupVisible(false);
  };

  return (
    <div className="space-y-4 p-4 w-full max-w-6xl mx-auto">
      <SEOMetadata
        title="PhDScanner - User preferences page"
        description="Settings to view PhD opportunities based on your preferences"
        keywords="phd recommendations, academic opportunities"
      />
      {!user ? (
        <div className="text-center py-20">
          <p className="text-xl text-gray-600 mb-4">🔒 Please log in to view your saved opportunities</p>
          <button
            onClick={() => navigate("/login")}
            className="mt-4 px-6 py-2 bg-[#DB4F40] text-white rounded-lg shadow-sm hover:bg-[#C64537] transition"
          >
            Login
          </button>
        </div>
      ) : (
        <>
          {/* User Info Section */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-4 mb-6 bg-[#FFEEED]/30 p-4 md:p-6 rounded-xl">
            <div className="mb-2 md:mb-0">
              <label className="block text-sm md:text-base font-medium text-[#1B222A]">Username</label>
              <input
                type="text"
                readOnly
                value={user?.name || ""}
                className="mt-1 block w-full p-2 text-sm md:text-base border border-[#DB4F40]/20 rounded-md bg-white"
              />
            </div>
            <div>
              <label className="block text-sm md:text-base font-medium text-[#1B222A]">Email</label>
              <input
                type="email"
                readOnly
                value={user?.email || ""}
                className="mt-1 block w-full p-2 text-sm md:text-base border border-[#DB4F40]/20 rounded-md bg-white"
              />
            </div>
          </div>

          <h3 className="text-xl md:text-2xl font-bold text-[#1B222A] mb-4 md:mb-6 text-center">
            Research Preferences
          </h3>

          {/* Countries Section */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
            <div className="space-y-2">
              <label className="block text-sm md:text-base font-medium text-[#1B222A]">
                Countries
              </label>
              <CustomMultiSelect
                options={countries}
                selected={selectedCountries}
                onSelect={setSelectedCountries}
                maxSelections={10}
                fieldName="countries"
                placeholder="Select countries..."
              />
            </div>
            <div className="space-y-2">
              <p className="text-sm md:text-base font-medium text-[#1B222A]">Selected Countries (max 10)</p>
              <SelectedList
                items={selectedCountries}
                onRemove={(item) => setSelectedCountries(selectedCountries.filter(c => c !== item))}
              />
            </div>
          </div>

          {/* Universities Section */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
            <div className="space-y-2">
              <label className="block text-sm md:text-base font-medium text-[#1B222A]">
                Universities
              </label>
              <CustomMultiSelect
                options={universities}
                selected={selectedUniversities}
                onSelect={setSelectedUniversities}
                maxSelections={10}
                fieldName="universities"
                placeholder="Select universities..."
              />
            </div>
            <div className="space-y-2">
              <p className="text-sm md:text-base font-medium text-[#1B222A]">Selected Universities (max 10)</p>
              <SelectedList
                items={selectedUniversities}
                onRemove={(item) => setSelectedUniversities(selectedUniversities.filter(u => u !== item))}
              />
            </div>
          </div>

          {/* Disciplines Section */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
            <div className="space-y-2">
              <label className="block text-sm md:text-base font-medium text-[#1B222A]">
                Disciplines
              </label>
              <CustomMultiSelect
                options={disciplines}
                selected={selectedDisciplines}
                onSelect={setSelectedDisciplines}
                maxSelections={10}
                fieldName="disciplines"
                placeholder="Select disciplines..."
              />
            </div>
            <div className="space-y-2">
              <p className="text-sm md:text-base font-medium text-[#1B222A]">Selected Disciplines (max 10)</p>
              <SelectedList
                items={selectedDisciplines}
                onRemove={(item) => setSelectedDisciplines(selectedDisciplines.filter(d => d !== item))}
              />
            </div>
          </div>

          {/* Comments Section */}
          <div className="space-y-2">
            <label className="block text-sm md:text-base font-medium text-[#1B222A]">
              Comments
            </label>
            <textarea
              value={comments}
              onChange={(e) => setComments(e.target.value)}
              placeholder="Enter your comments..."
              className="w-full p-2 border-2 border-[#DB4F40]/20 rounded-lg focus:outline-none focus:border-[#DB4F40]/40 text-sm md:text-base"
              rows={4}
            />
          </div>

          <div className="text-center">
            <button
              onClick={handleSavePreferences}
              className="w-full md:w-auto px-6 py-2 md:px-8 md:py-3 bg-gradient-to-br from-[#DB4F40] to-[#C64537] text-white rounded-lg shadow-lg hover:from-[#C64537] hover:to-[#DB4F40] transition-all transform hover:scale-105 text-sm md:text-base"
            >
              Save Preferences
            </button>
          </div>

          {/* Popup */}
          {isPopupVisible && (
            <div className="fixed inset-0 flex items-center justify-center z-50 bg-black/30 px-4 backdrop-blur-sm">
              <div className="bg-white p-8 rounded-2xl shadow-xl w-full max-w-md border border-[#F3F4F6]">
                <div className="flex justify-between items-start mb-6">
                  <h3 className="text-2xl font-bold text-[#1B222A]">Notification</h3>
                  <button
                    onClick={handleClosePopup}
                    className="text-gray-500 hover:text-[#DB4F40] transition-colors"
                  >
                    <X className="h-7 w-7" strokeWidth={1.5} />
                  </button>
                </div>
                <p className="text-[#1B222A] mb-6 text-lg leading-relaxed">
                  {popupMessage}
                </p>
                <button
                  onClick={handleClosePopup}
                  className="w-full bg-[#DB4F40] hover:bg-[#C23327] text-white font-semibold py-3 px-6 rounded-lg transition-all duration-300"
                >
                  Close Notification
                </button>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default UserPreferences;