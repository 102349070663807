import React, { useContext, useEffect, useMemo, useState } from "react";
import { FaGraduationCap, FaUniversity, FaGlobe, FaBook, FaArrowRight, FaChartLine } from "react-icons/fa";
import { Link } from "react-router-dom";
import { countryImages } from "../../utils/countryImages";
import OpportunityGrid from "../OpportunityGrid/OpportunityGrid";
import SEOMetadata from "../SEOMetadata/SEOMetadata";
import SubscribeEmail from "../SubscribeEmail/SubscribeEmail";
import { OpportunityService } from "../../services/OpportunityService";
import { useQuery } from "../../hooks/useQuery";
import { AuthContext } from "../../context/AuthContext";
import { useMutations } from "../../hooks/useMutations";
import useAnalytics from '../../hooks/useAnalytics';
import phdscannerBrand from "../../assets/phdscanner_brand.webp";

function replaceSpacesWithHyphens(str) {
  return str.replace(/\s+/g, '-');
}

export default function Home() {
  const [finalSavedOpportunities, setSavedOpportunities] = useState(new Set());
  const { userDetails: user } = useContext(AuthContext);
  const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID || 'G-XXXXXXXXXX';
  const { trackEvent } = useAnalytics(GA_MEASUREMENT_ID);

  const {
    data: featuredOpportunities = [],
    error: featuredOpperror,
    isLoading: featuredOpportunitiesLoading,
  } = useQuery(
    ["opportunity countries count", "featured opportunities"],
    () => OpportunityService.getAllOpportunities({
      category: "featured",
      limit: 9,
    }),
  );


  const {
    data: data = [],
    error: oppbycntryError,
    isLoading: loadingSavedOppByCntry,
  } = useQuery(
    ["getOpportunityCountriesCount"],
    OpportunityService.getOpportunityCountriesCount,
  );

  // Conditionally call getSavedOpportunities only if user is logged in
  const {
    data: savedOpportunities = [],
    error: savedOppError,
    isLoading: loadingSavedOpp,
    isSuccess: savedOppSuccess,
  } = useQuery(
    ["getSavedOpportunities", user?.id], // Include user ID in the query key
    () => OpportunityService.getSavedOpportunities({ userId: user.id }), // Pass user ID to the service
    {
      enabled: !!user, // Only run this query if user is logged in
    }
  );


  // Convert the fetched data to a Set
  useEffect(() => {
    // Safely extract the array from savedOpportunities object
    const opportunitiesArray = Array.isArray(savedOpportunities?.savedOpportunities)
      ? savedOpportunities.savedOpportunities
      : [];

    if (savedOppSuccess && opportunitiesArray.length > 0) {
      setSavedOpportunities(new Set(opportunitiesArray));
    }
  }, [savedOpportunities, savedOppSuccess]);


  const { mutateAsync: createSaveOpportunity } = useMutations(
    OpportunityService.createSavedOpportunity
  );

  const { mutateAsync: deleteSaveOpportunity } = useMutations(
    OpportunityService.deleteSavedOpportunities
  );

  const opportunitiesByCountry = useMemo(
    () =>
      data?.map?.((item) => ({
        ...item,
        image_url: countryImages[item.Country] || "", // Map country to image URL
      })) || [],
    [data],
  );

  // Toggle save/unsave opportunities
  const toggleSave = (opportunityId) => {
    if (!user) {
      return; // Redirect to login or handle user not logged in
    }

    setSavedOpportunities((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(opportunityId)) {
        newSet.delete(opportunityId);
      } else {
        newSet.add(opportunityId);
      }
      return newSet;
    });
  };

  const {
    data: universitiesData = {},
    error: universitiesError,
    isLoading: loadingUniversities,
  } = useQuery(
    ["getOpportunityUniversitiesCounts"],
    OpportunityService.getOpportunityUniversitiesCounts,
  );

  const {
    data: disciplineData = [],
    error: disciplineError,
    isLoading: loadingDiscipline,
  } = useQuery(
    ["getOpportunityDisciplineCount"],
    OpportunityService.getOpportunityDisciplineCount
  );

  // Create a memoized version of universities data
  const universitiesByCountry = useMemo(() => {
    return Object.entries(universitiesData).map(([country, universities]) => ({
      country,
      universities: universities.map(uni => ({
        ...uni,
        image_url: countryImages[country] || "", // Map country to image URL
      }))
    }));
  }, [universitiesData]);

  const handleToggleSave = async (opportunityId) => {
    if (!user) {
      return; // Redirect to login or handle user not logged in
    }

    try {
      if (finalSavedOpportunities.has(opportunityId)) {
        await deleteSaveOpportunity({
          user_id: user.id,
          opportunity_id: opportunityId,
        });
        toggleSave(opportunityId);
      } else {
        trackEvent({
          category: 'Button',
          action: 'Click',
          label: 'User Opportunity Save'
        });

        await createSaveOpportunity({
          user_id: user.id,
          opportunity_id: opportunityId,
        });
        toggleSave(opportunityId);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  return (
    <div >
      <SEOMetadata
        title="Explore Global PhD Opportunities | PhDScanner - Positions, Programs & Research Projects"
        description="Find available phd opportunities and positions here. Explore PhD Opportunities Worldwide"
        keywords="phdscanner, phd finder, find phd, phd, phd postions, phd opportunities, phd openings, academic researchers, higher education, phd vacancies, phd jobs"
        imageUrl="https://www.phdscanner.com/phdscanner_logo.webp"
        url="https://www.phdscanner.com/about"
      />

      <section className="w-full px-2 md:px-8 border-t border-[#DB4F40]">
        <picture>
          <source
            media="(max-width: 768px)"
            srcSet={phdscannerBrand}
            type="image/webp"
          />
          <source
            media="(min-width: 769px)"
            srcSet={phdscannerBrand}
            type="image/webp"
          />
          <img
            src={phdscannerBrand}
            alt="PhD Opportunities Worldwide"
            className="w-full h-auto object-cover"
            loading="lazy"
          />
        </picture>
      </section>

      {/* Hero Section */}
      <section className="relative bg-gradient-to-br from-[#FFBEB9] to-[#FFD1CE] border-t border-[#DB4F40]/20">
        <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
          <div className="flex flex-col items-center space-y-8 text-center">
            <h1 className="text-4xl sm:text-5xl lg:text-6xl font-extrabold text-[#1B222A] leading-tight">
              <span className="block mb-2 bg-gradient-to-r from-[#DB4F40] to-[#C64537] bg-clip-text text-transparent">
                Discover Your Next
              </span>
              <span className="block bg-gradient-to-r from-[#C64537] to-[#DB4F40] bg-clip-text text-transparent">
                Academic Adventure
              </span>
            </h1>

            <p className="text-lg sm:text-xl lg:text-2xl text-gray-700 max-w-3xl leading-relaxed">
              Your gateway to top-tier academic roles and groundbreaking PhD opportunities worldwide.
            </p>

            <div className="flex flex-col sm:flex-row gap-4 mt-8">
              <Link
                to="/phd-vacancies/standard"
                className="relative inline-flex items-center justify-center px-8 py-4 font-semibold text-white 
                         bg-gradient-to-br from-[#DB4F40] to-[#C64537] rounded-full shadow-lg
                         transform transition-all duration-300 hover:scale-105 hover:shadow-xl"
                onClick={() => {
                  trackEvent({
                    category: 'Button',
                    action: 'Click',
                    label: 'View All Opportunities from Home'
                  });
                }}
              >
                View All Opportunities
              </Link>
            </div>

            <div className="grid grid-cols-2 md:grid-cols-4 gap-6 mt-16 w-full max-w-6xl">
              {[
                {
                  icon: FaUniversity,
                  number: "60+",
                  label: "Universities",
                  color: "text-blue-500"
                },
                {
                  icon: FaGraduationCap,
                  number: "2K+",
                  label: "PhD Positions",
                  color: "text-green-500"
                },
                {
                  icon: FaGlobe,
                  number: "9+",
                  label: "Countries",
                  color: "text-purple-500"
                },
                {
                  icon: FaChartLine,
                  number: "10K+",
                  label: "Active Researchers",
                  color: "text-indigo-500"
                }
              ].map((stat, index) => (
                <div
                  key={index}
                  className="bg-white/80 backdrop-blur-sm rounded-2xl p-6 text-center 
                           shadow-md hover:shadow-xl transition-all duration-300"
                >
                  <stat.icon className={`mx-auto text-4xl mb-3 ${stat.color}`} />
                  <div className="text-3xl font-bold text-[#DB4F40] mb-2">{stat.number}</div>
                  <div className="text-gray-700 text-base">{stat.label}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>


      {/* Countries Section */}
      <section className="py-20 bg-white border-t border-[#DB4F40]/20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <h2 className="text-4xl font-extrabold text-[#1B222A] mb-4">
              Explore PhD Opportunities Worldwide
            </h2>
            <p className="text-xl text-gray-700 max-w-2xl mx-auto">
              Discover prestigious academic roles and groundbreaking research opportunities across the globe.
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {opportunitiesByCountry.slice(0, 9).map((opportunity, index) => (
              <div
                key={index}
                className="bg-gradient-to-br from-[#FFEEED] to-[#FFD1CE] rounded-2xl overflow-hidden shadow-lg 
                   hover:shadow-xl transition-all duration-300 group relative"
              >
                <Link
                  to={`/phd-vacancies/standard/${replaceSpacesWithHyphens(opportunity.Country)}`}
                  onClick={(e) => {
                    e.preventDefault();
                    trackEvent({
                      category: 'Opportunity',
                      action: 'View',
                      label: 'Opportunity by country'
                    });
                    window.location.href = `/phd-vacancies/standard/${replaceSpacesWithHyphens(opportunity.Country)}`;
                  }}
                >
                  <div className="relative aspect-video">
                    <img
                      className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-105"
                      src={opportunity.image_url}
                      alt={opportunity.Country}
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
                  </div>
                  <div className="p-6 -mt-16 relative z-10">
                    <h3 className="text-2xl font-bold text-white text-center mb-4">
                      {opportunity.Country}
                    </h3>
                    <div className="flex items-center justify-center">
                      <div className="bg-white/90 backdrop-blur-sm rounded-full px-6 py-3 flex items-center">
                        <FaGraduationCap className="text-[#DB4F40] text-xl mr-2" />
                        <span className="text-gray-800 font-medium">
                          {opportunity.Count} Opportunities
                        </span>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Disciplines Section */}
      <section className="py-20 bg-gradient-to-b from-white to-[#FFEEED]/30 border-t border-[#DB4F40]/20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-20">
            <h2 className="text-4xl font-extrabold text-[#1B222A] mb-4">
              Opportunities by Discipline
            </h2>
            <p className="text-xl text-gray-700 max-w-3xl mx-auto">
              Explore PhD opportunities across various academic disciplines
            </p>
          </div>

          {loadingDiscipline ? (
            <div className="flex justify-center py-12">
              <div className="animate-spin rounded-full h-12 w-12 border-4 border-[#DB4F40] border-t-transparent" />
            </div>
          ) : (
            <>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {disciplineData.slice(0, 50).map((discipline, index) => (
                  <Link
                    key={index}
                    to={`/phd-vacancies/standard/discipline/${replaceSpacesWithHyphens(discipline.ai_department)}`}
                    className="bg-white rounded-xl p-6 border border-gray-100 hover:border-[#DB4F40]/20 transition-all duration-300 hover:scale-[1.02]"
                    onClick={() => {
                      trackEvent({
                        category: 'Link',
                        action: 'Click',
                        label: `View Opportunities by Discipline - ${discipline.ai_department}`
                      });
                    }}
                  >
                    <div className="flex items-start space-x-4">
                      <div className="p-3 bg-[#DB4F40]/10 rounded-lg">
                        <FaBook className="w-6 h-6 text-[#DB4F40]" />
                      </div>
                      <div className="flex-1">
                        <h3 className="text-lg font-semibold text-gray-900 mb-2">
                          {discipline.ai_department}
                        </h3>
                        <div className="flex items-center space-x-2">
                          <FaGraduationCap className="w-4 h-4 text-[#DB4F40]" />
                          <span className="text-gray-600">
                            {discipline.Count} Opportunities
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>

              {disciplineData.length > 50 && (
                <div className="text-center mt-8">
                  <Link
                    to="/phd-vacancies/standard"
                    className="text-[#DB4F40] hover:text-[#C64537] font-medium inline-flex items-center"
                    onClick={() => {
                      trackEvent({
                        category: 'Link',
                        action: 'Click',
                        label: 'View All Disciplines'
                      });
                    }}
                  >
                    View All Disciplines
                    <FaArrowRight className="ml-2 w-4 h-4" />
                  </Link>
                </div>
              )}
            </>
          )}
        </div>
      </section>

      {/* Universities Section */}
      <section className="py-20 bg-gradient-to-b from-white to-[#FFEEED]/30 border-t border-[#DB4F40]/20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-20">
            <h2 className="text-4xl font-extrabold text-[#1B222A] mb-4">
              Universities by Country
            </h2>
            <p className="text-xl text-gray-700 max-w-3xl mx-auto">
              Explore PhD opportunities across top universities worldwide
            </p>
          </div>

          {loadingUniversities ? (
            <div className="flex justify-center py-12">
              <div className="animate-spin rounded-full h-12 w-12 border-4 border-[#DB4F40] border-t-transparent" />
            </div>
          ) : (
            <div className="space-y-12">
              {universitiesByCountry.map(({ country, universities }, index) => (
                <div
                  key={index}
                  className="bg-gradient-to-br from-[#FFEEED] to-[#FFD1CE] rounded-2xl shadow-lg p-8 
                     hover:shadow-xl transition-all duration-300"
                >
                  <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-8">
                    <h3 className="text-2xl font-bold text-gray-900 flex items-center mb-4 sm:mb-0">
                      <FaGlobe className="text-[#DB4F40] mr-3" />
                      {country}
                    </h3>
                    <span className="px-4 py-2 bg-[#DB4F40]/10 text-[#DB4F40] rounded-full text-sm font-semibold">
                      {universities.length} Universities
                    </span>
                  </div>

                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {universities.map((uni, uniIndex) => (
                      <div
                        key={uniIndex}
                        className="bg-white rounded-xl p-6 border border-gray-100 hover:border-[#DB4F40]/20 
                           transition-all duration-300 hover:scale-105"
                      >
                        <div className="flex items-start space-x-4">
                          <div className="p-3 bg-[#DB4F40]/10 rounded-lg">
                            <FaUniversity className="w-6 h-6 text-[#DB4F40]" />
                          </div>
                          <div className="flex-1">
                            <h4 className="text-lg font-semibold text-gray-900 mb-2">
                              {uni.universityName}
                            </h4>
                            <div className="flex items-center space-x-2">
                              <FaBook className="w-4 h-4 text-[#DB4F40]" />
                              <span className="text-gray-600">
                                {uni.count} PhD Positions
                              </span>
                            </div>
                          </div>
                        </div>
                        <Link
                          to={`/phd-vacancies/standard/${replaceSpacesWithHyphens(country)}/${replaceSpacesWithHyphens(uni.universityName)}`}
                          onClick={() => {
                            trackEvent({
                              category: 'Link',
                              action: 'Click',
                              label: `View Opportunities by University university=${uni.universityName} country=${country}`
                            });
                          }}
                          className="mt-4 inline-flex items-center text-[#DB4F40] hover:text-[#C64537] font-medium"
                        >
                          View Opportunities
                          <FaArrowRight className="ml-2 w-4 h-4" />
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </section>

      <SubscribeEmail />
    </div>
  );
}
