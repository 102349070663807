// ErrorPopup.jsx
import React from 'react';

const ErrorPopup = ({ message, visible, onClose }) => {
  if (!visible) return null;

  return (
    <div className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-2xl p-6 max-w-md w-full shadow-xl">
        <div className="text-red-600 font-semibold mb-4">Error Occurred</div>
        <p className="text-gray-800 mb-6">{message}</p>
        <button
          onClick={onClose}
          className="w-full py-2 bg-gradient-to-br from-[#DB4F40] to-[#C64537] text-white rounded-xl hover:from-[#C64537] hover:to-[#DB4F40] transition-all"
        >
          OK
        </button>
      </div>
    </div>
  );
};

export default ErrorPopup;