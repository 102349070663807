import React, { useState, useEffect } from "react";
import {
  AlertCircle,
  CheckCircle,
  Send,
  Search,
  Calendar,
  Globe,
  Users,
  Link,
  User,
  Trash2
} from "lucide-react";
import { useMutations } from "../../hooks/useMutations";
import { OpportunityService } from "../../services/OpportunityService";
import { useSnackBar } from "../../hooks/useSnackBar";
import { Select } from "antd";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const { Option } = Select;

const Popup = ({ message, isSuccess, onClose }) => {
  return (
    <div
      className={`fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 ${!message ? "hidden" : ""}`}
    >
      <div
        className={`bg-white p-6 rounded-lg shadow-lg ${isSuccess ? "border-teal-500" : "border-red-500"} border-2 max-w-md w-full mx-4`}
      >
        <div className="flex items-center mb-4">
          {isSuccess ? (
            <CheckCircle className="h-6 w-6 text-red-500 mr-2" />
          ) : (
            <AlertCircle className="h-6 w-6 text-red-500 mr-2" />
          )}
          <h3
            className={`text-lg font-semibold ${isSuccess ? "text-red-700" : "text-red-700"}`}
          >
            {isSuccess ? "Success" : "Error"}
          </h3>
        </div>
        <p className="text-gray-600 mb-4">{message}</p>
        <button
          className="w-full px-4 py-2 bg-teal-600 text-white rounded-lg hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-opacity-50 transition duration-300"
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export const UpdateOpportunity = () => {
  const initialFormData = {
    category: "standard",
    title: "",
    department: "",
    university: "",
    university_url: "",
    city: "",
    country: "",
    closing_date: "",
    open_to: "",
    supervisor: "",
    supervisor_url: "",
    type: "",
    fully_funded: "false",
    opportunity_url: "",
    description: "",
  };

  const [opportunityId, setOpportunityId] = useState("");
  const [formData, setFormData] = useState(initialFormData);
  const [universities, setUniversities] = useState([]);
  const [isCustomUniversity, setIsCustomUniversity] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const enqMsg = useSnackBar();

  useEffect(() => {
    const fetchUniversities = async () => {
      try {
       const response = await OpportunityService.getOpportunityUniversitiesCountries();
                // Remove duplicates based on university name and create unique IDs
        const uniqueUniversities = response.reduce((acc, current) => {
          const isDuplicate = acc.find(item => item.university === current.university);
          if (!isDuplicate) {
            acc.push({
              ...current,
              id: `${current.university}-${current.city}-${current.country}`.toLowerCase().replace(/\s+/g, '-')
            });
          }
          return acc;
        }, []);
        setUniversities(uniqueUniversities);; // Ensure we always have an array
      } catch (error) {
        enqMsg("Failed to fetch universities data", "error");
      }
    };
    fetchUniversities();
  }, []);

  const handleSearch = async () => {
    try {
      if (!opportunityId.trim()) {
        enqMsg("Please enter an opportunity ID", "error");
        return;
      }

      const response = await OpportunityService.getOpportunityById(opportunityId);
      if (response) {
        const closingDate = response.closing_date ? 
          new Date(response.closing_date * 1000).toISOString().split('T')[0] : '';

        setFormData({
          ...response,
          closing_date: closingDate,
          fully_funded: response.fully_funded ? "true" : "false"
        });
        setShowForm(true);
        enqMsg("Opportunity found", "success");
      }
    } catch (error) {
      enqMsg("Error finding opportunity", "error");
      setShowForm(false);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleUniversityChange = (value) => {
    if (value === "custom") {
      setIsCustomUniversity(true);
      setFormData({
        ...formData,
        university: "",
        city: "",
        country: "",
      });
    } else {
      setIsCustomUniversity(false);
      const selectedUniversity = universities.find((uni) => uni.university === value);
      setFormData({
        ...formData,
        university: value,
        city: selectedUniversity ? selectedUniversity.city : "",
        country: selectedUniversity ? selectedUniversity.country : "",
      });
    }
  };

  const handleDescriptionChange = (content) => {
    setFormData({
      ...formData,
      description: content,
    });
  };


  const { mutateAsync: deleteOpportunity } = useMutations({
    mutationFn: (data) => OpportunityService.deleteOpportunityById(opportunityId)
  });


  const handleDelete = async () => {
    try {
      await deleteOpportunity(opportunityId);
      enqMsg("Opportunity deleted successfully", "success");
      setShowForm(false);
      setOpportunityId("");
      setFormData(initialFormData);
      setShowDeleteConfirm(false);
    } catch (error) {
      enqMsg("Error deleting opportunity", "error");
    }
  };

  const { mutateAsync: updateOpportunity, isLoading: updating } = useMutations(
    OpportunityService.updateOpportunityById
  );
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const closingDateEpoch = formData?.closing_date
        ? Math.floor(Date.UTC(...formData.closing_date.split('-').map((v, i) => i === 1 ? Number(v) - 1 : Number(v))) / 1000)
        : null;
  
      const closingDateString = closingDateEpoch ? closingDateEpoch.toString() : null;
      const formDataCopy = { ...formData };
      delete formDataCopy.createdAt;
      delete formDataCopy.updatedAt;

      const data = {
        ...formDataCopy,
        closing_date: closingDateString,
        fully_funded: formData?.fully_funded === "true",
        
      }
      console.log(`Data before update: ${JSON.stringify(data)}`);
      console.log(`Opportunity ID: ${opportunityId}`);
  
  
      const res = await updateOpportunity({
        id: opportunityId,
        data: data
      });
  
      if (res?.error) {
        throw new Error(res?.error);
      } else {
        enqMsg(res?.message, "success");
      }
    } catch (error) {
      enqMsg(error);
    }
  };

  const inputStyle =
    "w-full rounded-md border border-[#DB4F40] py-2 px-3 focus:border-[#DB4F40] focus:ring focus:ring-[#DB4F40]/50 transition duration-300";
  const labelStyle = "block text-sm font-medium mb-1";

  const formFields = [
    {
      name: "department",
      label: "Department",
      type: "text",
      required: true,
      placeholder: "Enter the department name",
      icon: <Users className="h-5 w-5 text-red-500" />,
    },
    {
      name: "city",
      label: "City",
      type: "text",
      required: true,
      icon: <Globe className="h-5 w-5 text-red-500" />,
    },
    {
      name: "country",
      label: "Country",
      type: "text",
      required: true,
      icon: <Globe className="h-5 w-5 text-red-500" />,
    },
    {
      name: "closing_date",
      label: "Closing Date",
      type: "date",
      required: true,
      icon: <Calendar className="h-5 w-5 text-red-500" />,
    },
    {
      name: "open_to",
      label: "Open To",
      type: "text",
      required: false,
      placeholder: "e.g., All nationalities, EU citizens only",
      icon: <Users className="h-5 w-5 text-red-500" />,
    },
    {
      name: "opportunity_url",
      label: "Opportunity URL",
      type: "url",
      required: false,
      placeholder: "https://www.university.edu/position/12345 (optional)",
      icon: <Link className="h-5 w-5 text-red-500" />,
    },
    {
      name: "supervisor",
      label: "Supervisor",
      type: "text",
      required: false,
      placeholder: "Enter supervisor name (optional)",
      icon: <User className="h-5 w-5 text-red-500" />,
    },
    {
      name: "supervisor_url",
      label: "Supervisor URL",
      type: "url",
      required: false,
      placeholder: "https://www.university.edu/supervisor or mailto:test@university.uk (optional)",
      icon: <Link className="h-5 w-5 text-red-500" />,
    },
  ];

  return (
    <div className="bg-[#EEA0ED]/20 min-h-screen py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        {/* Search Section */}
        <div className="bg-white p-6 rounded-lg shadow-xl mb-6">
          <div className="flex gap-4">
            <input
              type="text"
              value={opportunityId}
              onChange={(e) => setOpportunityId(e.target.value)}
              placeholder="Enter Opportunity ID"
              className="flex-1 p-2 border border-[#DB4F40] rounded focus:ring focus:ring-[#DB4F40]/50"
            />
            <button
              onClick={handleSearch}
              className="px-6 py-2 bg-[#DB4F40] text-white rounded hover:bg-[#c54538] transition-colors duration-200 flex items-center"
            >
              <Search className="mr-2" size={20} /> Search
            </button>
          </div>
        </div>

        {/* Form Section */}
        {showForm && (
          <div className="bg-white rounded-lg shadow-xl overflow-hidden">
            <div className="bg-[#DB4F40] py-6 px-4 sm:px-6 lg:px-8 flex justify-between items-center">
              <h2 className="text-2xl font-bold text-white">
                Update PhD Position
              </h2>
              <button
                onClick={() => setShowDeleteConfirm(true)}
                className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 transition-colors duration-200 flex items-center"
              >
                <Trash2 className="mr-2" size={20} /> Delete
              </button>
            </div>
            <div className="p-6 sm:p-8">
              <form onSubmit={handleSubmit} className="space-y-6">
                <div className="mb-4">
                  <label className="block text-sm font-medium text-[#1B222A] mb-2">
                    Title
                    <span className="text-red-500 ml-1">*</span>
                  </label>
                  <input
                    type="text"
                    name="title"
                    value={formData.title}
                    onChange={handleChange}
                    className={inputStyle}
                    required
                    placeholder="Enter the opportunity title"
                  />
                </div>

                <div className="mb-4">
                  <label className="block text-sm font-medium text-[#1B222A] mb-2">
                    University Name
                    <span className="text-red-500 ml-1">*</span>
                  </label>
                  <Select
                    showSearch
                    style={{ width: '100%' }}
                    value={formData.university}
                    onChange={handleUniversityChange}
                    placeholder="Select or enter the university name"
                    dropdownRender={(menu) => (
                      <>
                        {menu}
                        <div className="p-2">
                          <input
                            type="text"
                            placeholder="Enter custom university"
                            className={inputStyle}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleUniversityChange("custom");
                            }}
                            onChange={(e) => {
                              e.stopPropagation();
                              setFormData({
                                ...formData,
                                university: e.target.value,
                              });
                            }}
                            value={isCustomUniversity ? formData.university : ''}
                          />
                        </div>
                      </>
                    )}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().includes(input.toLowerCase())
                    }
                  >
                    {universities.map((uni) => (
                      <Option key={uni.university} value={uni.university}>
                        {uni.university}
                      </Option>
                    ))}
                  </Select>
                </div>

                {formFields.map((field, index) => (
                  <div
                    key={field.name}
                    className={
                      index === 1 ? "grid grid-cols-1 sm:grid-cols-2 gap-6" : ""
                    }
                  >
                    <div className={index === 1 ? "" : "mb-4"}>
                      <label htmlFor={field.name} className={labelStyle}>
                        {field.label}
                        {field.required && (
                          <span className="text-red-500 ml-1">*</span>
                        )}
                      </label>
                      <div className="relative">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                          {field.icon}
                        </div>
                        <input
                          type={field.type}
                          id={field.name}
                          name={field.name}
                          value={formData[field.name]}
                          onChange={handleChange}
                          className={`${inputStyle} pl-10`}
                          required={field.required}
                          placeholder={field.placeholder || field.label}
                        />
                      </div>
                    </div>
                    {index === 1 && (
                      <div>
                        <label htmlFor="fully_funded" className={labelStyle}>
                          Fully Funded
                        </label>
                        <select
                          id="fully_funded"
                          name="fully_funded"
                          value={formData.fully_funded}
                          onChange={handleChange}
                          className={inputStyle}
                        >
                          <option value="false">No</option>
                          <option value="true">Yes</option>
                        </select>
                      </div>
                    )}
                  </div>
                ))}

<div className="mb-4">
                  <label className="block text-sm font-medium text-[#1B222A] mb-2">
                    Description
                  </label>
                  <div className="border border-[#DB4F40] h-96">
                    <ReactQuill
                      value={formData.description}
                      onChange={handleDescriptionChange}
                      modules={{
                        toolbar: [
                          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                          ['bold', 'italic', 'underline', 'strike'],
                          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                          ['link', 'image'],
                          ['clean']
                        ]
                      }}
                      className="h-[calc(100%-40px)]"
                      placeholder="Describe the opportunity details"
                    />
                  </div>
                </div>

                <div className="mb-4">
                  <button
                    type="submit"
                    className="w-full bg-[#DB4F40] text-white px-6 py-3 rounded-md font-semibold hover:bg-[#DB4F40]/90 focus:outline-none focus:ring-2 focus:ring-[#DB4F40] focus:ring-opacity-50 transition duration-300 flex items-center justify-center"
                  >
                    <Send className="h-5 w-5 mr-2" />
                    Update Opportunity
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}

        {/* Delete Confirmation Modal */}
        {showDeleteConfirm && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full mx-4">
              <h3 className="text-xl font-semibold text-gray-900 mb-4">
                Confirm Deletion
              </h3>
              <p className="text-gray-600 mb-6">
                Are you sure you want to delete this opportunity? This action cannot be undone.
              </p>
              <div className="flex justify-end space-x-4">
                <button
                  onClick={() => setShowDeleteConfirm(false)}
                  className="px-4 py-2 text-gray-600 hover:text-gray-800 transition-colors duration-200"
                >
                  Cancel
                </button>
                <button
                  onClick={handleDelete}
                  className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 transition-colors duration-200 flex items-center"
                >
                  <Trash2 className="h-5 w-5 mr-2" />
                  Delete
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

