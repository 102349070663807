

import React from 'react';
import { Link } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { FaArrowRight, FaEdit, FaEye } from 'react-icons/fa';
import { AuthContext } from "../../context/AuthContext";
import useAnalytics from '../../hooks/useAnalytics';
import { useContext} from "react";

const BlogCard = ({ blog }) => {
  const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID || 'G-XXXXXXXXXX';
  const { trackEvent } = useAnalytics(GA_MEASUREMENT_ID);
  // Parse the ISO date string format
  const { userDetails: user } = useContext(AuthContext);
  const formatDate = (dateString) => {
    try {
      const date = parseISO(dateString);
      return format(date, 'MMMM d, yyyy');
    } catch (error) {
      return 'Date unavailable';
    }
  };

  // Track event handler
  const handleReadMoreClick = () => {
    trackEvent('Blog Click', {
      blogId: blog.id,
      blogTitle: blog.title,
      author: user?.username || 'anonymous'
    });
  };
  
  return (
    <div className="bg-gradient-to-br from-[#FFEEED] to-[#FFD1CE] rounded-2xl overflow-hidden shadow-lg hover:shadow-xl transition-all duration-300 group relative">
      <div className="relative aspect-video">
        {blog.thumbnail_image_data ? (
          <img
            src={`data:image/jpeg;base64,${blog.thumbnail_image_data}`}
            alt={blog.title}
            className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-105"
          />
        ) : (
          <div className="w-full h-full bg-gradient-to-br from-[#DB4F40] to-[#C64537] animate-pulse" />
        )}
        
        {/* View count badge */}
        <div className="absolute bottom-3 right-3 bg-black/60 text-white px-2 py-1 rounded-full text-sm flex items-center">
          <FaEye className="mr-1 w-3 h-3" />
          {blog.views || 0}
        </div>
      </div>
      
      <div className="p-6">
        <h2 className="text-2xl font-bold text-[#1B222A] mb-3">
          {blog.title}
        </h2>
        
        <div className="flex items-center justify-between mt-4">
          <Link
            to={`/blog/${blog.id}`}
            className="inline-flex items-center font-medium text-[#DB4F40] hover:text-[#C64537] transition-colors"
            onClick={handleReadMoreClick}
          >
            Read More <FaArrowRight className="ml-2 w-4 h-4" />
          </Link>
          <div className="text-[#1B222A]/70">
            {formatDate(blog.created_at)}
          </div>
        </div>
      </div>
      
      {user && user.role === "admin" && (
        <div className="px-6 py-3 bg-[#FFEEED] border-t border-[#DB4F40]/20">
          <Link
            to={`/edit-blog/${blog.id}`}
            className="text-[#DB4F40] hover:text-[#C64537] transition-colors"
          >
            <FaEdit className="w-5 h-5" />
          </Link>
        </div>
      )}
    </div>
  );
};

export default BlogCard;