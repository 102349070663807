import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useQuery } from "react-query";
import BlogCard from "../BlogCard/BlogCard";
import { AuthContext } from "../../context/AuthContext";
import { Blog } from "../../services/Blog";
import SEOMetadata from "../SEOMetadata/SEOMetadata";

// Helper function to format date like "March 3, 2025"
const formatDate = (date) => {
  return date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
};

const BlogPage = () => {
  const { userDetails: user } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [blogs, setBlogs] = useState([]);
  const [totalBlogs, setTotalBlogs] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const blogsPerPage = 12;

  // Get search params from URL
  const searchParams = new URLSearchParams(location.search);
  const pageParam = searchParams.get("page");
  const searchParam = searchParams.get("search");

  // Initialize state from URL
  const [searchTerm, setSearchTerm] = useState(searchParam || "");
  const [currentPage, setCurrentPage] = useState(pageParam ? parseInt(pageParam) : 1);

  // Define params for pagination
  const apiParams = { 
    page: currentPage, 
    search: searchTerm !== "" ? searchTerm : undefined
  };

  const { data: response, isLoading } = useQuery(
    ["getAllBlogs", apiParams],
    () => Blog.getBlogs(apiParams),
    {
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    if (response) {
      setBlogs(response.data || []);
      if (response.pagination) {
        const total = response.pagination.total || 0;
        setTotalBlogs(total);
        setTotalPages(Math.ceil(total / blogsPerPage));
      }
    }
  }, [response]);

  // Update URL when search or page changes
  useEffect(() => {
    const newSearchParams = new URLSearchParams();
    if (currentPage > 1) {
      newSearchParams.set("page", currentPage.toString());
    }
    if (searchTerm) {
      newSearchParams.set("search", searchTerm);
    }
    
    const newUrl = `${location.pathname}${newSearchParams.toString() ? '?' + newSearchParams.toString() : ''}`;
    navigate(newUrl, { replace: true });
  }, [currentPage, searchTerm, navigate, location.pathname]);

  // Scroll to top when currentPage changes
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [currentPage]);

  const handleSearch = () => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    } else {
      // Force refetch if we're already on page 1
      const newSearchParams = new URLSearchParams(location.search);
      if (searchTerm) {
        newSearchParams.set("search", searchTerm);
      } else {
        newSearchParams.delete("search");
      }
      navigate(`${location.pathname}?${newSearchParams.toString()}`, { replace: true });
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") handleSearch();
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  // Generate SEO title and description
  const seoTitle = searchTerm 
    ? `PhD Tips - ${totalBlogs > 0 ? totalBlogs : ''} Articles about "${searchTerm}"` 
    : "PhD Tips - Advice and Guidance for PhD Students and Researchers";

  const currentDate = formatDate(new Date());
  const seoDescription = searchTerm
    ? `${currentDate} -- Explore PhD tips and advice about "${searchTerm}". ${totalBlogs > 0 ? `We have ${totalBlogs} articles related to "${searchTerm}". All content is up-to-date and written by experienced researchers.` : ''}`
    : `${currentDate} -- Explore tips and advice for PhD students and researchers. ${totalBlogs > 0 ? `Browse our collection of ${totalBlogs} articles covering various aspects of PhD life, from research methods to work-life balance.` : 'Practical guidance written by experienced academics to help you navigate your doctoral journey.'}`; 

  const keywords = `phd tips, phd advice, doctoral research${searchTerm ? `, ${searchTerm}` : ''}, research methods, academic writing, phd life`;

  return (
    <>
      <SEOMetadata
        title={seoTitle}
        description={seoDescription}
        keywords={keywords}
        imageUrl="https://www.phdscanner.com/phdscanner_logo.webp"
        url={`https://www.phdscanner.com/phd-tips${searchTerm ? `?search=${encodeURIComponent(searchTerm)}` : ''}`}
      />

      <div className="container mx-auto px-4 py-12">
        <div className="flex justify-between items-center mb-12 flex-wrap gap-4">
          <h1 className="text-4xl font-extrabold text-[#1B222A]">PhD Tips</h1>
          <div className="flex gap-4 flex-grow justify-end">
            <div className="flex flex-grow max-w-md gap-2">
              <input
                type="text"
                placeholder="Search posts..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyDown={handleKeyPress}
                className="px-4 py-2 border border-[#DB4F40]/30 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#DB4F40]/50 w-full"
              />
              <button
                onClick={handleSearch}
                className="px-6 py-2 bg-gradient-to-br from-[#DB4F40] to-[#C64537] hover:from-[#C64537] hover:to-[#DB4F40] text-white font-medium rounded-lg transition duration-300 whitespace-nowrap transform hover:scale-105"
              >
                Search
              </button>
            </div>
            {user && user.role === "admin" && (
              <Link
                to="/create-blog"
                className="px-6 py-3 bg-gradient-to-br from-[#DB4F40] to-[#C64537] hover:from-[#C64537] hover:to-[#DB4F40] text-white font-medium rounded-xl transition-all duration-300 hover:scale-105"
              >
                Create New Post
              </Link>
            )}
          </div>
        </div>

        {isLoading ? (
          <div className="flex justify-center my-12">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-[#DB4F40]" />
          </div>
        ) : blogs.length === 0 ? (
          <div className="text-center my-12 p-8 bg-gradient-to-br from-[#FFEEED] to-[#FFD1CE] rounded-2xl">
            <h2 className="text-2xl font-semibold text-[#1B222A] mb-4">No blog posts found</h2>
            <p className="text-[#1B222A]/80">Try adjusting your search or create a new post!</p>
          </div>
        ) : (
          <>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {blogs.map((blog) => (
                <BlogCard key={blog.id} blog={blog} />
              ))}
            </div>

            {totalPages > 1 && (
              <div className="flex flex-col sm:flex-row justify-center items-center gap-4 py-12">
                <button
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                  className="px-6 py-3 rounded-xl bg-white border-2 border-[#DB4F40]/20 hover:border-[#DB4F40]/50 text-[#DB4F40] hover:bg-[#FFEEED]/30 transition-all"
                >
                  Previous
                </button>
                <span className="px-6 py-3 bg-gradient-to-br from-[#DB4F40] to-[#C64537] text-white rounded-xl">
                  Page {currentPage} of {totalPages}
                </span>
                <button
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                  className="px-6 py-3 rounded-xl bg-white border-2 border-[#DB4F40]/20 hover:border-[#DB4F40]/50 text-[#DB4F40] hover:bg-[#FFEEED]/30 transition-all"
                >
                  Next
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default BlogPage;