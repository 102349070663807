import React, { useState, useEffect, useRef } from "react";
import { Menu, Bookmark } from "lucide-react";
import SavedJobs from "../../components/SavedJobs/SavedJobs";
import UserPreferences from "../../components/UserPreferences/UserPreferences";
import RecommendedJobs from "../../components/RecommendedJobs/RecommendedJobs";
import { FaBook } from "react-icons/fa";

// Configuration for section titles and sidebar items
const sectionTitles = {
  "recommended-jobs": "Recommended PhD Opportunities",
  "saved-jobs": "Saved PhD Opportunities",
  "user-preferences": "User Preferences"
};

const sectionsConfig = [
  {
    id: 1,
    title: "Recommended Opportunities",
    subtitle: "Opportunities based on your preference",
    sectionKey: "recommended-jobs"
  },
  {
    id: 2,
    title: "Saved Opportunities",
    subtitle: "View your saved opportunities",
    sectionKey: "saved-jobs"
  },
  {
    id: 3,
    title: "User Preferences",
    subtitle: "Set your viewing preferences",
    sectionKey: "user-preferences"
  }
];

const SmartPicks = () => {
  const [selectedSection, setSelectedSection] = useState("recommended-jobs");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const sidebarRef = useRef(null);
  const contentRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (sidebarRef.current && contentRef.current) {
        const windowHeight = window.innerHeight;
        const contentTop = contentRef.current.getBoundingClientRect().top;
        const contentHeight = windowHeight - contentTop;
        sidebarRef.current.style.height = `${contentHeight}px`;
        contentRef.current.style.minHeight = `${contentHeight}px`;
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Close sidebar on outside click (only in mobile view)
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isSidebarOpen &&
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target) &&
        !event.target.closest(".menu-button")
      ) {
        setIsSidebarOpen(false);
      }
    };

    if (isSidebarOpen) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    return () => document.removeEventListener("click", handleClickOutside);
  }, [isSidebarOpen]);

  return (
    <div className="min-h-screen bg-gray-100">
      {/* Header */}
      <div className="bg-gradient-to-r from-[#FFBEB9] to-[#FFD1CE] shadow-md p-4">
        <div className="max-w-7xl mx-auto flex items-center justify-between">
          <h1 className="text-2xl md:text-3xl font-bold text-gray-900 truncate">
            {sectionTitles[selectedSection] || 'Smart Picks'}
          </h1>
          <button
            onClick={() => setIsSidebarOpen(!isSidebarOpen)}
            className="menu-button md:hidden bg-[#DB4F40] text-white flex items-center gap-2 px-3 py-2 rounded-lg shadow-sm hover:bg-[#C64537] transition"
          >
            <Menu className="w-5 h-5" />
            <span className="text-sm font-medium">Menu</span>
          </button>
        </div>
      </div>

      {/* Two-Column Layout */}
      <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-[300px_1fr] gap-8 p-4 sm:p-6">
        {/* Sidebar */}
        <aside
          ref={sidebarRef}
          className={`fixed lg:static inset-y-0 left-0 w-80 lg:w-full bg-white/90 backdrop-blur-sm lg:bg-transparent 
            shadow-xl lg:shadow-none z-40 transform transition-transform duration-300 ease-out 
            ${isSidebarOpen ? "translate-x-0" : "-translate-x-full"} lg:translate-x-0 overflow-y-auto p-6 lg:p-0`}
        >
          {/* Mobile Close Button */}
          <div className="lg:hidden flex justify-end pb-4">
            <button
              onClick={() => setIsSidebarOpen(false)}
              className="text-gray-500 hover:text-[#DB4F40] transition"
            >
              ✖
            </button>
          </div>

          {/* Sidebar Content */}
          <div className="space-y-8">
            <div className="space-y-4">
              <h3 className="text-lg font-bold text-gray-900 flex items-center border-b-2  pb-2 whitespace-nowrap">
                <FaBook className="text-[#DB4F40] mr-2" /> Smart Picks
              </h3>
              {sectionsConfig.map(({ id, title, subtitle, sectionKey }) => (
                <button
                  key={id}
                  onClick={() => {
                    setSelectedSection(sectionKey);
                    setIsSidebarOpen(false);
                  }}
                  className={`w-full flex items-center justify-between bg-white p-4 rounded-xl border hover:border-[#DB4F40] 
                    transition shadow-sm ${
                      selectedSection === sectionKey 
                        ? "border-[#DB4F40] bg-[#FFEEED]" 
                        : "border-gray-200"
                    }`}
                >
                  <div className="flex items-center space-x-3">
                    <Bookmark className="h-5 w-5 text-[#DB4F40]" />
                    <div>
                      <p className="text-sm font-semibold text-gray-800">{title}</p>
                      <p className="text-xs text-gray-500">{subtitle}</p>
                    </div>
                  </div>
                </button>
              ))}
            </div>
          </div>
        </aside>

        {/* Main Content */}
        <main ref={contentRef} className="bg-white p-6 rounded-xl shadow-md w-full">
          {selectedSection === "recommended-jobs" && (
            <RecommendedJobs setSelectedSection={setSelectedSection} />
          )}
          {selectedSection === "saved-jobs" && <SavedJobs />}
          {selectedSection === "user-preferences" && <UserPreferences />}
        </main>
      </div>
    </div>
  );
};

export default SmartPicks;
