import React from 'react';
import CookieConsent from "react-cookie-consent";

const CookieBanner = () => {
  return (
    <CookieConsent
      location="bottom"
      buttonText="I Understand"
      declineButtonText="Decline"
      enableDeclineButton={false}
      cookieName="session_cookie_consent"
      style={{ 
        background: "#FFEEED",
        color: "#1B222A",
        boxShadow: "0 -4px 20px rgba(219, 79, 64, 0.1)",
        borderTop: "1px solid #DB4F40"
      }}
      buttonStyle={{
        background: "#DB4F40",
        color: "white",
        fontSize: "14px",
        fontWeight: "600",
        borderRadius: "999px",
        padding: "12px 24px",
        textTransform: "uppercase",
        letterSpacing: "0.5px",
        transition: "all 0.3s ease",
        border: "none"
      }}
      buttonWrapperClasses="hover:scale-105 transition-transform"
      contentClasses="flex items-center"
      expires={365}
    >
      <div className="max-w-7xl mx-auto px-4 py-2">
        <h4 className="text-xl font-bold mb-1" style={{ color: "#C64537" }}>
          Cookie Notice
        </h4>
        <p className="text-base">
          We use only strictly necessary cookies to maintain your secure session. 
          Learn more in our{' '}
          <a 
            href="/privacy-policy" 
            className="font-semibold hover:underline"
            style={{ color: "#DB4F40" }}
          >
            Privacy Policy
          </a>.
        </p>
      </div>
    </CookieConsent>
  );
};

export default CookieBanner;