import React from 'react';
import { FaLinkedin, FaTwitter, FaWhatsapp, FaFacebook, FaShareAlt } from 'react-icons/fa';

const SocialShareButtons = ({
  title,
  description,
  url,
  image,
  onShare,
  className = ''
}) => {
  const handleShare = (platform) => {
    if (onShare) {
      onShare(platform);
    }
  };

  const shareButtons = [
    {
      icon: FaLinkedin,
      color: 'bg-[#0077b5] hover:bg-[#006396]',
      label: 'Share on LinkedIn',
      platform: 'LinkedIn',
      href: `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}&title=${encodeURIComponent(title)}&summary=${encodeURIComponent(description)}`
    },
    {
      icon: FaTwitter,
      color: 'bg-[#1DA1F2] hover:bg-[#1a91da]',
      label: 'Share on Twitter',
      platform: 'Twitter',
      href: `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${encodeURIComponent(title)}&via=phdscanner&hashtags=phd,phdopportunities,phdpositions,phdvacancy,scholarship,research,jobs,phdopenings&image=${encodeURIComponent(image)}`
    },
    {
      icon: FaWhatsapp,
      color: 'bg-[#25D366] hover:bg-[#20bd5a]',
      label: 'Share on WhatsApp',
      platform: 'WhatsApp',
      href: `https://api.whatsapp.com/send?text=${encodeURIComponent(`${title}\n\n${description}\n\n${url}`)}`
    }
  ];

  return (
    <div className={`flex items-center justify-center gap-4 ${className}`}>
      {/* Share text with icon for larger screens */}
      <div className="hidden sm:flex items-center gap-2 bg-gradient-to-r from-[#DB4F40] to-[#e86d61] 
        px-4 py-2 rounded-full text-white shadow-md">
        <FaShareAlt className="w-5 h-5 animate-pulse" />
        <h2 className="text-lg font-semibold">Share</h2>
      </div>

      {/* Social buttons container */}
      <div className="flex items-center gap-3">
        {shareButtons.map((button, index) => (
          <a
            key={index}
            href={button.href}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => handleShare(button.platform)}
            className={`group relative ${button.color} p-3 rounded-full text-white 
              transform transition-all duration-300 
              hover:scale-110 hover:shadow-lg 
              active:scale-95
              focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-opacity-50`}
            aria-label={button.label}
          >
            <button.icon className="w-5 h-5 transition-transform duration-300 group-hover:scale-110" />
            
            {/* Tooltip */}
            <span className="absolute -top-10 left-1/2 transform -translate-x-1/2 
              bg-gray-900 text-white text-xs py-1 px-2 rounded-md whitespace-nowrap
              opacity-0 group-hover:opacity-100 transition-opacity duration-300
              pointer-events-none shadow-lg">
              {button.label}
            </span>
          </a>
        ))}
      </div>
    </div>
  );
};

export default SocialShareButtons;